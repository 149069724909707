import { CompanyCreate } from "api/endpoints/company";
import CompanyFormData from "./form/company-from-data";

const create = (formData: CompanyFormData): CompanyCreate => {
  return {
    address_id: formData.addressId,
    company_name: formData.companyName,
    krs_no: formData.krs,
    regon: formData.regon,
    tax_id: formData.taxId,
    insurance_amount: Number(formData.insurance),
    licence_number: formData.licenceNumber,
  };
};

const companyAddRequestFactory = { create };

export default companyAddRequestFactory;
