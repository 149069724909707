import { LatLngBounds, LatLngTuple, Point, Map, LatLngExpression } from "leaflet";
import L from "leaflet";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMapEvent } from "react-leaflet";
import { nominatimURL } from "api/backend";
import MapNewAddrMessageBox from "./MapNewAddrMessageBox";

const pendingIcon = L.icon({
  iconUrl: require("assets/pendingMarker.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

export interface AddressForMap {
  name: string;
  x: number;
  y: number;
}

function MapComponent(props: {
  markers: AddressForMap[];
  padding: string;
  title: boolean;
  height?: string;
  autoFit?: boolean;
  autoFitOnUpdate?: boolean;
  refreshFitFlag?: boolean;
  empMarker?: AddressForMap;
  empAddrTo?: boolean;
  empAddrFrom?: boolean;
  setNewAddr?: (lat: number, lng: number) => void;
  newAddr?: boolean;
  newEmpAddr?: boolean;
}) {
  const { t } = useTranslation("common", { keyPrefix: "map" });
  const mapRef = useRef<Map>(null);
  const [isMapReady, setIsMapReady] = useState(false);

  const lines = () => {
    var array: { ad1: AddressForMap; ad2: AddressForMap }[] = [];
    for (var i = 0; i + 1 < props.markers.length; i += 2) {
      array.push({ ad1: props.markers[i], ad2: props.markers[i + 1] });
    }
    return array;
  };

  function SetNewAddrMarker() {
    useMapEvent("click", (e) => {
      if (props.setNewAddr) props.setNewAddr(e.latlng.lat, e.latlng.lng);
    });
    return null;
  }

  // eslint-disable-next-line
  const linesMemo = useMemo(() => lines(), [props.markers]);

  const refreshZoom = () => {
    const points: LatLngTuple[] = props.markers.map((marker) => [marker.x, marker.y]);
    if (props.empMarker && props.empMarker.x !== 0 && props.empMarker.y !== 0) {
      points.push([props.empMarker.x, props.empMarker.y]);
    }
    if (points.length === 1) {
      mapRef.current?.setView(points[0], 10);
    } else if (points.length > 1) {
      const bounds = new LatLngBounds(points);
      mapRef.current?.fitBounds(bounds, { padding: new Point(25, 25) });
    }
  };

  const center: LatLngExpression =
    props.empMarker && props.empMarker.x !== 0.0 && props.empMarker.y !== 0.0
      ? { lat: props.empMarker.x, lng: props.empMarker.y }
      : props.markers.length > 0
        ? { lat: props.markers[0].x, lng: props.markers[0].y }
        : { lat: 52.06, lng: 19.25 };

  useEffect(() => {
    if (
      !mapRef.current ||
      !isMapReady ||
      !props.autoFit ||
      (!props.markers?.length && !(props.empMarker && props.empMarker.x !== 0 && props.empMarker.y !== 0))
    ) {
      return;
    }
    refreshZoom();
    // eslint-disable-next-line
  }, [mapRef.current, isMapReady, props.autoFit, JSON.stringify(props.markers), JSON.stringify(props.empMarker)]);

  useEffect(() => {
    if (!isMapReady) return;
    if (props.markers?.length) {
      refreshZoom();
    } else {
      const defaultLatLongTuple: LatLngTuple = [52.06, 19.25];
      const bounds = new LatLngBounds([defaultLatLongTuple]);
      mapRef.current?.fitBounds(bounds, { padding: new Point(25, 25) }).setZoom(5);
    }
    // eslint-disable-next-line
  }, [props.refreshFitFlag]);

  const [pendingEmpMarker, setPendingEmpMarker] = useState<{ lat: number; lng: number } | null>(null);
  const [empNominatimName, setEmpNominatimName] = useState<string>("");
  const [isEmpReverseFetched, setIsEmpReverseFetched] = useState(false);

  function SetNewEmpAddrMarker() {
    useMapEvent("click", (e) => {
      setPendingEmpMarker({ lat: e.latlng.lat, lng: e.latlng.lng });
      setIsEmpReverseFetched(false);
    });
    return null;
  }

  useEffect(() => {
    if (!pendingEmpMarker || isEmpReverseFetched) return;
    const delayDebounceFn = setTimeout(() => {
      const query_params = new URLSearchParams();
      query_params.append("accept-language", "pl");
      query_params.append("lat", String(pendingEmpMarker.lat));
      query_params.append("lon", String(pendingEmpMarker.lng));
      query_params.append("format", "json");
      query_params.append("layer", "address");
      fetch(nominatimURL + "/reverse?" + query_params, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setEmpNominatimName(data.display_name);
          if (data.lat && data.lon) {
            setPendingEmpMarker({
              lat: parseFloat(data.lat),
              lng: parseFloat(data.lon),
            });
          }
          setIsEmpReverseFetched(true);
        });
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [pendingEmpMarker, isEmpReverseFetched]);

  const handleEmpConfirm = () => {
    if (pendingEmpMarker && props.setNewAddr) {
      props.setNewAddr(pendingEmpMarker.lat, pendingEmpMarker.lng);
      setPendingEmpMarker(null);
    }
  };

  return (
    <div
      style={{
        position: "relative",
        flex: "1",
        border: "1px solid var(--gray-base)",
        borderRadius: "4px",
        height: "100%",
      }}
    >
      <MapContainer
        ref={mapRef}
        whenReady={() => {
          if (mapRef.current) {
            refreshZoom();
          }
          setIsMapReady(true);
        }}
        style={{ width: "100%", height: props.height ?? "600px" }}
        center={center}
        zoom={5}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {props.markers.map((e) => (
          <Marker key={`${e.x} ${e.y} ${Math.random()}`} position={[e.x, e.y]}>
            <Popup>
              <span style={{ fontSize: "1.2rem" }}>{e.name}</span>
            </Popup>
          </Marker>
        ))}

        {props.newAddr === true && <SetNewAddrMarker />}
        {props.newEmpAddr === true && <SetNewEmpAddrMarker />}
        {pendingEmpMarker && <Marker position={[pendingEmpMarker.lat, pendingEmpMarker.lng]} icon={pendingIcon} />}

        {props.empMarker && (
          <>
            <Marker position={[props.empMarker.x, props.empMarker.y]}>
              <Popup>
                <span style={{ fontSize: "1.2rem" }}>
                  {t("empAddr")} <br /> {props.empMarker.name}
                </span>
              </Popup>
            </Marker>

            {props.empMarker.x !== 0.0 && props.empMarker.y !== 0.0 && (
              <>
                {props.empAddrTo === true && (
                  <Polyline
                    positions={[
                      [props.empMarker.x, props.empMarker.y],
                      [props.markers[0].x, props.markers[0].y],
                    ]}
                    pathOptions={{ color: "#ffcc00" }}
                  />
                )}

                {props.empAddrFrom === true && (
                  <Polyline
                    positions={[
                      [props.empMarker.x, props.empMarker.y],
                      [props.markers[props.markers.length - 1].x, props.markers[props.markers.length - 1].y],
                    ]}
                    pathOptions={{ color: "#ffcc00" }}
                  />
                )}
              </>
            )}
          </>
        )}

        {linesMemo.map((e) => (
          <Polyline
            key={`${e.ad1.x} ${e.ad1.y} ${e.ad2.x}`}
            positions={[
              [e.ad1.x, e.ad1.y],
              [e.ad2.x, e.ad2.y],
            ]}
            pathOptions={{ color: "#3699ff" }}
          />
        ))}
      </MapContainer>

      {pendingEmpMarker && (
        <div
          style={{
            position: "absolute",
            bottom: 15,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
          }}
        >
          <MapNewAddrMessageBox text={empNominatimName} onConfirm={handleEmpConfirm} />
        </div>
      )}
    </div>
  );
}

export default React.memo(MapComponent);
