import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";
import { AddressCreate } from "api/entities/bases/address";
import api from "api";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import RegisterNewNominatimAddr from "commons/Registration/RegisterNewNominatimAddr";
import Modal from "commons/Modal";
/* import NotificationPopUp from 'commons_new/NotificationPopUp/NotificationPopUp'; */

export default function Address() {
  const { t } = useTranslation("adminView", { keyPrefix: "register" });
  const { handleErrors } = useErrorHandling();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  /* const [notifText, setNotifText] = useState(''); */

  const [inputs, setInputs] = useState<{ name: string; longitude: number | undefined; latitude: number | undefined }>({
    name: "",
    longitude: undefined,
    latitude: undefined,
  });

  const clearInputs = () => {
    setInputs({
      name: "",
      longitude: undefined,
      latitude: undefined,
    });
  };

  const [err, setErr] = useState("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (inputs.name.length === 0) {
      setErr(t("address.nameErr") ?? "");
      return;
    }
    if (inputs.latitude === 0) {
      setErr(t("address.mapErr") ?? "");
      return;
    }

    setLoading(true);
    api.registerAddress(inputs as AddressCreate).then((res) => {
      if (handleErrors(res.error).ok) {
        clearInputs();
        setLoading(false);
        setErr("");
        setAlert(true);
      } else {
        setLoading(false);
        setAlert(false);
      }
    });
  };

  return (
    <>
      {alert && (
        <Modal
          action1={() => {
            setAlert(false);
          }}
          title={t("modal.registerTitle")}
        />
      )}
      {/* <NotificationPopUp text='Użytkownik został stworzony poprawnie'/> */}
      <form onSubmit={handleSubmit} autoComplete={"off"}>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <RegisterNewNominatimAddr
            value={{ name: inputs.name, lat: inputs.latitude, lng: inputs.longitude }}
            htmlId="address"
            setValue={(name: string, lat: number | undefined, lng: number | undefined) => {
              setInputs({ name: name, latitude: lat!, longitude: lng! });
              setErr("");
            }}
            placeholder={t("address.name") ?? "Nazwa"}
            label={t("address.name") ?? "Nazwa"}
          />

          {err !== "" && <div style={{ textAlign: "center", color: "#df4f5d" }}>{err}</div>}
          <SubmitButton loading={loading} success={false} />
        </div>
      </form>
    </>
  );
}
