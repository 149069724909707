import { Mode } from "index";
import { useRef, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import styles from "../Listings.module.css";

import api from "api";
import { AuditorCoWithAddress } from "api/entities/auditorCo";
import { DispatcherCoWithAddress } from "api/entities/dispatcherCo";
import { DataList, DataProvider, TitleProvider } from "commons/DataManagement";
import { AuditorCoAddressFilter } from "api/filters/auditor_co";
import { DispatcherCoAddressFilter } from "api/filters/dispatcher_co";
import Button from "commons/Button/Button";

function SecondaryRow(props: { data: AuditorCoWithAddress | DispatcherCoWithAddress; mode: Mode }) {
  const navigate = useNavigate();
  const { t } = useTranslation("adminView", { keyPrefix: "companies.handles" });

  // Po kliknieciu wyświetlenie pracowników firmy
  const handleShowEmp = (companyName: string, mode: Mode) => {
    navigate("/admin/users", {
      state: {
        companyName: companyName,
        mode: mode,
      },
    });
  };

  return (
    <>
      <div className={styles.td_exp_heading_content}>
        <div>
          <button
            onClick={() => {
              handleShowEmp(props.data.company_name, Mode.Auditor);
            }}
            className={styles.td_exp_button}
            type="button"
          >
            {t("auditorCo.auditors")}
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              handleShowEmp(props.data.company_name, Mode.AuditorMngr);
            }}
            className={styles.td_exp_button}
            type="button"
          >
            {t("auditorCo.auditorManagers")}
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              handleShowEmp(props.data.company_name, Mode.Dispatcher);
            }}
            className={styles.td_exp_button}
            type="button"
          >
            {t("dispatcherCo.dispatchers")}
          </button>
        </div>
      </div>

      <div className={styles.td_exp_heading_content}>
        <Button
          label={t("edit")}
          id={"success"}
          click={() => {
            navigate("/admin/register", {
              state: {
                id: "4",
                companyEditData: props.data,
              },
            });
          }}
        />
      </div>
    </>
  );
}

const AuditorDataProvider = (props: { ht: any; ft: any; loc: Location }) => {
  const filterRef = useRef({
    name: props.loc.state?.filter ?? "",
    address_name: "",
  } as AuditorCoAddressFilter);
  const nodeRef = useRef(null);

  return (
    <DataProvider
      fetchFun={api.fetchAuditorCompaniesWithAddresses}
      fetchArgs={[]}
      query={{
        order_by: "company_name" as keyof AuditorCoWithAddress,
      }}
    >
      {(gto) => {
        return (
          <>
            <div className={styles.table_wrapper}>
              <DataList
                gto={gto}
                keyGenerator={(e) => e.id}
                tableWrapperElement={<table className={styles.table} />}
                dataRowElement={<tr className={styles.tr} />}
                dataLabelElement={<td className={styles.td} />}
                headerElement={
                  <DataList.Header
                    gto={gto}
                    translation={props.ht}
                    headerElement={<thead className={styles.thead} />}
                    labelElement={<th scope="col" className={styles.th} />}
                    filterElement={<input className={styles.input} />}
                    filterRef={filterRef}
                  />
                }
                labelGenerator={{
                  name: {
                    label: (e) => e.company_name,
                    column: "company_name",
                    filter: { key: "name", type: "string" },
                  },
                  address: {
                    label: (e) => e.address.name,
                    column: "address_name",
                    filter: {
                      key: "address_name",
                      type: "string",
                    },
                  },
                  taxNo: {
                    label: (e) => e.tax_id,
                    filter: {
                      key: "nip",
                      type: "string",
                    },
                  },
                  regon: {
                    label: (e) => e.regon,
                    filter: {
                      key: "regon",
                      type: "string",
                    },
                  },
                  krs: {
                    label: (e) => e.krs_no,
                  },
                  insurance: {
                    label: (e) => `${e.insurance_amount ?? "---"}`,
                    column: "insurance_amount",
                  },
                  license: {
                    label: (e) => `${e.licence_number ?? "---"}`,
                  },
                }}
                defaultOrder="company_name"
              >
                {(d, e, k) => (
                  <tr className={styles.tr_exp} key={k}>
                    <CSSTransition
                      in={e}
                      nodeRef={nodeRef}
                      timeout={300}
                      classNames={{
                        enter: styles.csstrEnter,
                        enterActive: styles.csstrEnterActive,
                        exit: styles.csstrExit,
                        exitActive: styles.csstrExitActive,
                      }}
                      unmountOnExit
                      appear
                    >
                      <td ref={nodeRef} className={styles.td_exp} colSpan={7}>
                        <div className={styles.td_exp_content}>
                          <div className={styles.td_exp_heading}>
                            <SecondaryRow data={d} mode={Mode.AuditorComp} />
                          </div>
                        </div>
                      </td>
                    </CSSTransition>
                  </tr>
                )}
              </DataList>
            </div>
            <div className={styles.row_items}>
              <DataProvider.Pagination gto={gto} />
            </div>
          </>
        );
      }}
    </DataProvider>
  );
};

const DispatcherDataProvider = (props: { ht: any; ft: any; loc: Location }) => {
  const filterRef = useRef({
    name: props.loc.state?.filter ?? "",
    address_name: "",
  } as DispatcherCoAddressFilter);
  const nodeRef = useRef(null);

  return (
    <DataProvider
      fetchFun={api.fetchDispatcherCompaniesWithAddress}
      fetchArgs={[]}
      query={{
        order_by: "company_name" as keyof DispatcherCoWithAddress,
      }}
    >
      {(gto) => {
        return (
          <>
            <div className={styles.table_wrapper}>
              <DataList
                gto={gto}
                keyGenerator={(e) => e.id}
                tableWrapperElement={<table className={styles.table} />}
                dataRowElement={<tr className={styles.tr} />}
                dataLabelElement={<td className={styles.td} />}
                headerElement={
                  <DataList.Header
                    gto={gto}
                    translation={props.ht}
                    headerElement={<thead className={styles.thead} />}
                    labelElement={<th scope="col" className={styles.th} />}
                    filterElement={<input className={styles.input} />}
                    filterRef={filterRef}
                  />
                }
                labelGenerator={{
                  name: {
                    label: (e) => e.company_name,
                    column: "company_name",
                    filter: { key: "name", type: "string" },
                  },
                  address: {
                    label: (e) => e.address.name,
                    column: "address_name",
                    filter: {
                      key: "address_name",
                      type: "string",
                    },
                  },
                  taxNo: {
                    label: (e) => e.tax_id,
                    column: "tax_id",
                  },
                  regon: {
                    label: (e) => e.regon,
                    column: "regon",
                  },
                  krs: {
                    label: (e) => e.krs_no,
                    column: "krs_no",
                  },
                }}
                defaultOrder="company_name"
              >
                {(d, e, k) => (
                  <tr className={styles.tr_exp} key={k}>
                    <CSSTransition
                      in={e}
                      nodeRef={nodeRef}
                      timeout={300}
                      classNames={{
                        enter: styles.csstrEnter,
                        enterActive: styles.csstrEnterActive,
                        exit: styles.csstrExit,
                        exitActive: styles.csstrExitActive,
                      }}
                      unmountOnExit
                      appear
                    >
                      <td ref={nodeRef} className={styles.td_exp} colSpan={7}>
                        <div className={styles.td_exp_content}>
                          <div className={styles.td_exp_heading}>
                            <SecondaryRow data={d} mode={Mode.DispatcherComp} />
                          </div>
                        </div>
                      </td>
                    </CSSTransition>
                  </tr>
                )}
              </DataList>
            </div>
            <div className={styles.row_items}>
              <DataProvider.Pagination gto={gto} />
            </div>
          </>
        );
      }}
    </DataProvider>
  );
};

export const Companies = () => {
  const location = useLocation();

  const { t } = useTranslation("adminView", { keyPrefix: "companies" });
  const headerTranslation = useTranslation("adminView", {
    keyPrefix: "companies.data",
  });
  const filterTranslation = useTranslation("adminView", {
    keyPrefix: "companies.filters",
  });

  // Wyświetlanie userów z konkretną rolą
  // eslint-disable-next-line
  const [tableMode, setTableMode] = useState<Mode>(Mode.AuditorComp);

  const resolveDataProvider = (mode: Mode) => {
    if (mode === Mode.DispatcherComp)
      return <DispatcherDataProvider ht={headerTranslation.t} ft={filterTranslation.t} loc={location} />;
    else return <AuditorDataProvider ht={headerTranslation.t} ft={filterTranslation.t} loc={location} />;
  };

  return (
    <>
      <TitleProvider title={`${t("title")} | Raily Marketplace`} />
      <div className={styles.content}>
        <div className={styles.row}>
          <h1>{t("title")}</h1>
        </div>

        {resolveDataProvider(tableMode)}
      </div>
    </>
  );
};

export default Companies;
