import { FormValidationDefinition } from "commons/form/useForm";
import CompanyFormData from "./company-from-data";
import { AuditorCoWithAddress } from "api/entities/auditorCo";
import formValidationService from "commons/form/form-validation.service";
import registerValidationService from "../../common/register-validation-service";

const getDefaultFormData = (): CompanyFormData => {
  return {
    addressId: "",
    addressName: "",
    companyName: "",
    insurance: "",
    krs: "",
    licenceNumber: "",
    regon: "",
    taxId: "",
  };
};

const createFormData = (companyData: AuditorCoWithAddress): CompanyFormData => {
  return {
    addressId: companyData.address_id,
    addressName: companyData.address.name,
    companyName: companyData.company_name,
    insurance: `${companyData.insurance_amount ?? ""}`,
    krs: companyData.krs_no ?? "",
    licenceNumber: companyData.licence_number ?? "",
    regon: companyData.regon ?? "",
    taxId: companyData.tax_id,
  };
};

const getFormValidationDefinition = (): FormValidationDefinition<CompanyFormData> => {
  return {
    addressId: formValidationService.ok,
    addressName: formValidationService.ok,
    companyName: (values) => registerValidationService.validateCompanyName(values.companyName),
    insurance: (values) => registerValidationService.validateCompanyInsurance(Number(values.insurance)),
    krs: (values) => registerValidationService.validateCompanyKrs(values.krs),
    licenceNumber: (values) => registerValidationService.validateCompanyLicenseNumber(values.licenceNumber),
    regon: (values) => registerValidationService.validateCompanyRegon(values.regon),
    taxId: (values) => registerValidationService.validateCompanyTaxId(values.taxId),
  };
};

const companyFormFactory = {
  getDefaultFormData,
  createFormData,
  getFormValidationDefinition,
};

export default companyFormFactory;
