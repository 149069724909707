import { useState, useEffect } from "react";
import DoubleInput from "commons/Registration/DoubleInput";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import styles from "./EmployeeForm.module.css";
import { Employee, employeeFormService } from "../../../commons/Services/employeeFormService";
import { useTranslation } from "react-i18next";
import { ListType } from "commons/Registration/ListInput/ListInput";
import { WorkerType } from "api/entities/enums";
import { AuditorWithUserAndAddress } from "api/entities/auditor";
import { useLocation, useNavigate } from "react-router-dom";
import SingleInput from "commons/Registration/SingleInput";
import Button from "commons/Button/Button";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import api from "api";
import CheckboxListInput from "commons/Registration/CheckboxListInput/CheckboxListInput";
import ListInputAddrNomi from "commons/Registration/ListInput/ListInputAddrNomi";
import Modal from "commons/Modal";

export const EmployeeForm = () => {
  const { t } = useTranslation("auditorManagerView", { keyPrefix: "employee-form" });
  const { handleErrors } = useErrorHandling();
  const location = useLocation();
  const navigate = useNavigate();
  const [registerAlert, setAlert] = useState(false);
  const [editAlert, setEditAlert] = useState(false);

  const employee = location.state !== null ? (location.state.employee as AuditorWithUserAndAddress) : undefined;
  const isEdit = location.state !== null ? (location.state.edit as boolean) : false;
  const inactive = location.state !== null ? (location.state.inactive as boolean) : false;

  useEffect(() => {
    document.title = `${t("title")} | Raily Marketplace`;
  }, [t]);

  const [inputs, setInputs] = useState(() => {
    if (isEdit) {
      return employeeFormService.getFormData(employee);
    }
    return employeeFormService.getDefaultFormData(sessionStorageHelper.getAuditorCoId());
  });

  const clearInputs = () => {
    setInputs(employeeFormService.getDefaultFormData(sessionStorageHelper.getAuditorCoId()));
  };

  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (errMsg !== "") setErrMsg("");
    const value: string = event.currentTarget.value;
    setInputs({ ...inputs, [event.currentTarget.id]: value });
  };

  const [errMsg, setErrMsg] = useState("");

  const handleEdit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (employee === undefined) return;
    if (inputs.addr_id === "" && inputs.addr_name === "") {
      setErrMsg(t("addrErr") ?? "error");
      return;
    }
    if (inputs.addr_lati === 0.0 || inputs.addr_long === 0.0) {
      setErrMsg(t("coordErr") ?? "error");
      return;
    }
    setLoading(true);
    employeeFormService
      .handleEdit(employee, inputs)
      .then((s) => {
        const x = handleErrors(s);
        if (x.ok) {
          navigate(-1);
          setEditAlert(true);
        } else {
          setLoading(false);
          setErrMsg(x.err_str);
          setEditAlert(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setEditAlert(false);
      });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (inputs.addr_id === "" && inputs.addr_name === "") {
      setErrMsg(t("addrErr") ?? "error");
      return;
    }
    if (inputs.addr_lati === 0.0 || inputs.addr_long === 0.0) {
      setErrMsg(t("coordErr") ?? "error");
      return;
    }
    setLoading(true);

    const empData: Employee = inputs;

    empData.phone = empData.phone.replace(/\s/g, "");

    if (empData.phone.indexOf("+") === -1) {
      empData.phone_prefix = "+48";
    } else {
      empData.phone_prefix = empData.phone.substring(0, 3);
      empData.phone = empData.phone.substring(3);
    }

    employeeFormService
      .handleSubmit(empData)
      .then((s) => {
        const x = handleErrors(s);
        if (x.ok) {
          clearInputs();
          setLoading(false);
          setAlert(true);
          setErrMsg("");
          setListBool(!listBool);
        } else {
          setLoading(false);
          setErrMsg(x.err_str);
          setAlert(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setAlert(false);
      });
  };

  //for refreshing listinputaddr
  const [listBool, setListBool] = useState(false);

  return (
    <>
      {registerAlert && (
        <Modal
          action1={() => {
            setAlert(false);
          }}
          title={t("registerTitle")}
        />
      )}
      {editAlert && (
        <Modal
          action1={() => {
            setEditAlert(false);
          }}
          title={t("editTitle")}
        />
      )}
      <form onSubmit={isEdit ? handleEdit : handleSubmit}>
        <div className={styles.content}>
          <div className={styles.row}>
            <h1 className={styles.h1}>{t("title")}</h1>
          </div>
          <div className={styles.inputs_wrapper}>
            {isEdit && employee && (
              <div className={styles.row}>
                {!inactive && (
                  <Button
                    label={t("deactivate")}
                    id={"delete"}
                    click={() => {
                      if (sessionStorageHelper.getDispatcherId() !== "") {
                        api.removeAuditor(employee.id);
                        navigate("/dispatcher/auditors");
                      } else if (sessionStorageHelper.getAuditorManagerId() !== "") {
                        api.removeAuditor(employee.id);
                        navigate("/operator/auditors");
                      }
                    }}
                  />
                )}
                {inactive && (
                  <Button
                    label={t("activate")}
                    id={"success"}
                    click={() => {
                      if (sessionStorageHelper.getDispatcherId() !== "") {
                        api.restoreAuditor(employee.id);
                        navigate("/dispatcher/auditors");
                      } else if (sessionStorageHelper.getAuditorManagerId() !== "") {
                        api.restoreAuditor(employee.id);
                        navigate("/operator/auditors");
                      }
                    }}
                  />
                )}
              </div>
            )}

            <DoubleInput
              change={handleChange}
              text_i1={t("name")}
              id_i1={"name"}
              placeholder_i1={t("name")}
              value_i1={inputs.name}
              pattern_i1=".{2,20}"
              text_i2={t("surname")}
              id_i2={"surname"}
              placeholder_i2={t("surname")}
              value_i2={inputs.surname}
              pattern_i2=".{2,20}"
            />
            <DoubleInput
              change={handleChange}
              text_i1={t("email")}
              id_i1={"email"}
              placeholder_i1={t("email")}
              value_i1={inputs.email}
              pattern_i1="[^@]+@[^@]+\.[^@]+"
              errorMsg_i1={t("emailError") ?? "email error"}
              text_i2={t("phone")}
              id_i2={"phone"}
              placeholder_i2={t("phone")}
              value_i2={inputs.phone}
              pattern_i2="([+][0-9]*)?[1-9][0-9\s]*"
              errorMsg_i2={`${t("phoneError")} +48 123 123 123`}
            />

            <div style={{ width: "490px" }}>
              <SingleInput
                change={handleChange}
                text={t("login")}
                id={"login"}
                placeholder={t("login")}
                value={inputs.login}
                login
                pattern=".{3,40}"
                errorMsg={t("loginPatter") ?? "error"}
              />
            </div>

            <DoubleInput
              change={handleChange}
              text_i1={t("newPasswd")}
              id_i1={"password"}
              placeholder_i1={t("newPasswd")}
              value_i1={inputs.password}
              password_i1={true}
              pattern_i1=".{8,50}"
              errorMsg_i1={t("passwdPatter") ?? "error"}
              optional_i1={!isEdit || inputs.password_confirm.length === 0}
              text_i2={t("newPasswdConfirm")}
              id_i2={"password_confirm"}
              placeholder_i2={t("newPasswdConfirm")}
              value_i2={inputs.password_confirm}
              password_i2={true}
              pattern_i2=".{8,50}"
              errorMsg_i2={t("passwdPatter") ?? "error"}
              optional_i2={inputs.password.length === 0}
            />

            <CheckboxListInput
              setInputs={(id, name, values) => {
                setInputs({ ...inputs, types: values as WorkerType[] });
              }}
              labelText={t("workerTypes")}
              id="types"
              mode={ListType.WorkerType}
              resetElement={listBool}
              values={inputs.types}
            />

            <ListInputAddrNomi
              setInput={(id: string, name: string, lati: number | undefined, long: number | undefined) => {
                setInputs({ ...inputs, addr_id: id, addr_name: name, addr_lati: lati, addr_long: long });
              }}
              labelText={t("address")}
              id={"address"}
              newAddressEnabled={true}
              resetElement={listBool}
            />

            {errMsg !== "" && <div style={{ color: "var(--red-base)", textAlign: "center" }}>{errMsg}</div>}

            <SubmitButton success={false} loading={loading} label={isEdit ? t("editSubmit") ?? undefined : undefined} />
            {isEdit && (
              <Button
                label={t("editCancel")}
                id={"delete"}
                click={() => {
                  navigate(-1);
                }}
              />
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default EmployeeForm;
