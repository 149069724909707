import { FocusEventHandler, RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import localStyles from "./DataList.module.css";
import commonStyles from "commons/Registration/Registration.module.css";
import api from "api";
import { StringMatchingMode } from "api/filters/filterEnums";
import { AuditorCoBase } from "api/entities/bases/auditorCo";
import { ServiceBase } from "api/entities/bases/service";

export default function DataList(props: {
  type: string;
  setInputs: (id: string, name: string) => void;
  id: string;
  labelText: string;
  mode?: string; // 's' - services
  comp_id?: string;
  value?: string;
  inputRef?: RefObject<HTMLInputElement>;
  onFocus?: () => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}) {
  const { t } = useTranslation("common", { keyPrefix: "listInput" });
  const [lockedState, setLockedState] = useState(true);
  const [selected, setSelected] = useState(false);
  const [input, setInput] = useState("");
  const [data, setData] = useState<AuditorCoBase[]>([]);
  const [dataS, setDataS] = useState<ServiceBase[]>([]);

  const listRef = useRef(null);

  const handleItemClick = (name: string, id: string) => {
    props.setInputs(id, name);
    setInput(name);
    setLockedState(true);
    setSelected(true);
  };

  useEffect(() => {
    setInput(props.value ?? "");
    setLockedState(true);
    setSelected(!!props.value);
    // eslint-disable-next-line
  }, [props.value]);

  const updateData = (filter: string) => {
    if (props.mode === "s") {
      api
        .fetchServicesAvailableForContract(props.comp_id ?? "", {
          filter: {
            name: filter,
            name_match: StringMatchingMode.INCLUDE,
          },
        })
        .then((res) => {
          setDataS(res.data);
          setLockedState(false);
        });
      return;
    }

    api
      .fetchAuditorCompanies({
        filter: {
          name: filter,
          name_match: StringMatchingMode.INCLUDE,
        },
      })
      .then((res) => {
        setData(res.data);
        setLockedState(false);
      });
  };

  return (
    <div>
      <div className={localStyles.single_input}>
        <label htmlFor={props.id} className={localStyles.input_label} title={props.labelText}>
          {props.labelText}
        </label>
        <div className={localStyles.input_with_button}>
          {/* Main input */}
          <input
            ref={props.inputRef}
            id={props.id}
            className={commonStyles.input}
            value={input}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              setInput(e.currentTarget.value);
              if (e.currentTarget.value.length > 0) updateData(e.currentTarget.value);
              else {
                setLockedState(true);
              }
            }}
            type="text"
            placeholder={(props.mode === "s" ? t("startTypingService") : t("startTyping")) ?? ""}
            autoComplete="off"
            readOnly={selected}
            title=""
            spellCheck={false}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            // onInvalid={(e) => e.currentTarget.setCustomValidity(t("onInvalid"))}
            // onInput={(e) => {
            //   e.currentTarget.setCustomValidity("");
            // }}
          ></input>

          {/* X Button */}
          <div
            onClick={() => {
              setLockedState(true);
              props.setInputs("", "");
              setInput("");
              setSelected(false);
            }}
            className={localStyles.button}
            id={""}
          >
            <FontAwesomeIcon className={localStyles.icon} id={""} icon={faXmark} />
          </div>
        </div>
      </div>
      <CSSTransition
        in={lockedState === false}
        nodeRef={listRef}
        timeout={{
          enter: 400,
          exit: 400,
        }}
        classNames={{
          enter: localStyles.enter,
          enterActive: localStyles.enterActive,
          exit: localStyles.exit,
          exitActive: localStyles.exitActive,
        }}
        mountOnEnter
        unmountOnExit
        appear
      >
        <div style={{ padding: "0 10px 0 10px" }} ref={listRef} className={localStyles.list_wrapper}>
          <div className={localStyles.list}>
            <MapData data={data} dataS={dataS} mode={props.mode ?? ""} handleClick={handleItemClick} />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

interface MapDataProps {
  data: AuditorCoBase[];
  dataS: ServiceBase[];
  mode?: string;
  handleClick: (name: string, id: string) => void;
}

function MapData(props: MapDataProps) {
  const { t } = useTranslation("common", { keyPrefix: "listInputAddr" });
  if (props.mode === "s") {
    return (
      <>
        {props.dataS.map((x) => {
          return (
            <div onClick={() => props.handleClick(x.name, x.id)} className={localStyles.list_item} key={x.id}>
              {x.name}
            </div>
          );
        })}
        {props.dataS.length === 0 && (
          <div className={localStyles.list_item} key={Math.random()}>
            {t("noEntries")}
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {props.data.map((x) => {
          return (
            <div onClick={() => props.handleClick(x.company_name, x.id)} className={localStyles.list_item} key={x.id}>
              {x.company_name}
            </div>
          );
        })}
        {props.data.length === 0 && (
          <div className={localStyles.list_item} key={Math.random()}>
            {t("noEntries")}
          </div>
        )}
      </>
    );
  }
}
