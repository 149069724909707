import backend, { QueryParams } from "api/backend";
import { AuditorEdit, AuditorBase } from "api/entities/bases/auditor";
import {
  AuditorWithAuditorCompanyAndAddress,
  AuditorWithAuditorCompanyUserAddress,
  AuditorWithUserAndAddress,
} from "api/entities/auditor";
import {
  AuditorUserCompanyAddressFilter,
  AuditorUserCompanyFilter,
  AuditorUserAddressFilter,
  AuditorActiveForOrderFilter,
} from "api/filters/auditor";
import { UserCreateRequest } from "api/entities/bases/user";

/**
 *  ## Object containing functions that fetch API endpoints related to auditors
 *  ### **_auditorApiService_** functions:
        fetchAuditors: 
            (q?: QueryParams<AuditorBase[], AuditorUserCompanyFilter>) 
                => Promise<backendResponse<AuditorBase[], AuditorUserCompanyFilter>>
    <br/>
        fetchAuditor: 
            (id: string [UUID]) 
                => Promise<backendResponse<AuditorBase, undefined>>
    <br/>
        fetchAuditorsWithUser: 
            (q?: QueryParams<AuditorWithUserAndAddress[], AuditorUserCompanyAddressFilter>) 
                => Promise<backendResponse<AuditorWithUserAndAddress[], AuditorUserCompanyAddressFilter>>
    <br/>
        fetchAuditorWithUser: 
            (id: string [UUID]) 
                => Promise<backendResponse<AuditorWithUserAndAddress, undefined>>
    <br/>
        fetchAuditorsWithAuditorCompany: 
            (q?: QueryParams<AuditorWithAuditorCompanyAndAddress[], AuditorUserCompanyAddressFilter>) 
                => Promise<backendResponse<AuditorWithAuditorCompanyAddress[], AuditorUserCompanyAddressFilter>>
    <br/>
        fetchAuditorWithAuditorCompany: 
            (id: string [UUID]) 
                => Promise<backendResponse<AuditorWithAuditorCompanyAndAddress, undefined>>
    <br/>
        fetchAuditorsWithAuditorCompanyAndUser: 
            (q?: QueryParams<AuditorWithAuditorCompanyUserAddress[], AuditorUserCompanyAddressFilter>) 
                => Promise<backendResponse<AuditorWithAuditorCompanyUserAddress[], AuditorUserCompanyAddressFilter>>
    <br/>
        fetchAuditorWithAuditorCompanyAndUser: 
            (id: string [UUID]) 
                => Promise<backendResponse<AuditorWithAuditorCompanyUserAddress, undefined>>
    <br/>
        fetchAuditorsByAuditorCompany: 
            (auditor_co_id: string [UUID], q?: QueryParams<AuditorWithUserAndAddress[], AuditorUserAddressFilter>) 
                => Promise<backendResponse<AuditorWithUserAndAddress[], AuditorUserAddressFilter>>
    <br/>
        registerAuditor: 
            (auditor: AuditorCreate) 
                => Promise<backendResponse<AuditorBase, undefined>>
    <br/>
        registerAuditorByDispatcher:    
        (user_id: string [UUID], auditor_co_id: string) 
            => Promise<backendResponse<AuditorBase, undefined>>
    <br/>
        registerAuditorByAuditorManager: 
            (user_id: string [UUID], auditor_co_id: string) 
                => Promise<backendResponse<AuditorBase, undefined>>
    <br/>
        removeAuditor: 
            (id: string [UUID]) 
                => Promise<backendResponse<null, undefined>>
    <br/>
        editAuditor: 
            (id: string [UUID], edit: AuditorEdit) 
                    => Promise<backendResponse<AuditorBase, undefined>>
 */
export const auditorApiService = {
  fetchAuditors: (q?: QueryParams<AuditorBase[], AuditorUserCompanyFilter>) => {
    return backend.get(`/auditors`, q);
  },
  fetchAuditor: (id: string) => {
    return backend.get<AuditorBase>(`/auditor/${id}`);
  },
  fetchAuditorsWithUser: (q?: QueryParams<AuditorWithUserAndAddress[], AuditorUserCompanyAddressFilter>) => {
    return backend.get(`/auditors/user`, q);
  },
  fetchAuditorWithUser: (id: string) => {
    return backend.get<AuditorWithUserAndAddress>(`/auditor/user/${id}`);
  },
  fetchAuditorsWithAuditorCompany: (
    q?: QueryParams<AuditorWithAuditorCompanyAndAddress[], AuditorUserCompanyAddressFilter>,
  ) => {
    return backend.get(`/auditors/auditor-company`, q);
  },
  fetchAuditorWithAuditorCompany: (id: string) => {
    return backend.get<AuditorWithAuditorCompanyAndAddress>(`/auditor/auditor-company/${id}`);
  },
  fetchAuditorsWithAuditorCompanyAndUser: (
    q?: QueryParams<AuditorWithAuditorCompanyUserAddress[], AuditorUserCompanyAddressFilter>,
  ) => {
    return backend.get(`/auditors/user-and-auditor-company`, q);
  },
  fetchAuditorWithAuditorCompanyAndUser: (id: string) => {
    return backend.get<AuditorWithAuditorCompanyUserAddress>(`/auditor/user-and-auditor-company/${id}`);
  },
  fetchAuditorsByAuditorCompany: (
    auditor_co_id: string,
    q?: QueryParams<AuditorWithUserAndAddress[], AuditorActiveForOrderFilter>,
  ) => {
    return backend.get(`/auditor/active/user/auditor-company/${auditor_co_id}`, q);
  },
  fetchAuditorsInactiveByAuditorCompany: (
    auditor_co_id: string,
    q?: QueryParams<AuditorWithUserAndAddress[], AuditorUserAddressFilter>,
  ) => {
    return backend.get(`/auditor/inactive/user/auditor-company/${auditor_co_id}`, q);
  },
  registerAuditor: (auditor: UserCreateRequest) => {
    return backend.post<AuditorBase>(`/auditor`, auditor);
  },
  restoreAuditor: (auditor_id: string) => {
    return backend.post<{ auditor_id: string }>(`/auditor/${auditor_id}/restore`, null);
  },
  removeAuditor: (id: string) => {
    return backend.delete(`/auditor/${id}`);
  },
  editAuditor: (id: string, edit: AuditorEdit) => {
    return backend.put<AuditorBase>(`/auditor/${id}`, edit);
  },
};
