import { AddressCreate } from "api/entities/bases/address";
import { LoginCheckResponse } from "api/entities/user";
import FormValidationResult from "commons/form/form-validation-result";
import formValidationService from "commons/form/form-validation.service";
import Joi from "joi";
import registerValidationService from "../../common/register-validation-service";

const validateLogin = (login: string, originalLogin: string, loginCheck: LoginCheckResponse): FormValidationResult => {
  if (login === originalLogin) return formValidationService.ok();

  const schema = Joi.string().trim().min(3).max(40).pattern(formValidationService.LOGIN_PATTERN).required();

  const result = formValidationService.validate(login, schema);

  if (result.isValid && !loginCheck.available) {
    const customMessages = formValidationService.getValidationCustomMessages();
    return formValidationService.bad(customMessages.loginNotAvailable);
  }

  return result;
};

const validateAddress = (address: string | AddressCreate): FormValidationResult => {
  if (typeof address === "string") {
    return formValidationService.ok();
  } else {
    const coordinateSchema = Joi.number().required();

    const latResult = formValidationService.validate(address.latitude, coordinateSchema);
    const lonResult = formValidationService.validate(address.longitude, coordinateSchema);

    if (!latResult.isValid || !lonResult.isValid) {
      const customMessages = formValidationService.getValidationCustomMessages();

      return formValidationService.bad(customMessages.improperAddress);
    }

    return registerValidationService.validateAddressName(address.name);
  }
};

const auditorEditFormValidationService = {
  validateLogin,
  validateAddress,
};

export default auditorEditFormValidationService;
