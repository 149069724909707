import { GenericTypeObject, QueryParams, backendResponse } from "api/backend";
import { once } from "lodash";
import DatePicker from "react-datepicker";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactElement,
  cloneElement,
  useRef,
  MutableRefObject,
  SyntheticEvent,
} from "react";
import { ContextError, GeneralContextProps } from "./ContextError";
import Pagination from "commons/Pagination/Pagination";
import Button from "commons/Button/Button";
import { NumberComparisonMode, numberComparisonModeToString, parseNumberComparisonMode } from "api/filters/filterEnums";
import { getLocale } from "i18n";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import useAppContext from "context/useContext";

interface DataContextProps<T, G, U> extends GeneralContextProps {
  data?: T;
  page?: number;
  pages?: number;
  loading: boolean;
  refetch: () => void;
  changeQuery: (params: QueryParams<U, G>) => void;
}

export const createDataContext = once(<T, G = undefined, U = T extends (infer U)[] ? U : never>() =>
  createContext({ isUndefined: true } as DataContextProps<T, G, U>),
);
export const useDataContext = <T, G = undefined, U = T extends (infer U)[] ? U : never>() =>
  useContext(createDataContext<T, G, U>());

interface DataProviderProps<T, G, U> {
  fetchFun: (args?: any) => Promise<backendResponse<T, G>>; // funkcja pobierająca dane. Z niej przekazywane są typy generyczne
  fetchArgs: any[]; // argumenty funkcji w postaci tablicy danych
  query?: QueryParams<U, G>; // początkowa wartość parametrów wyszukiwania w postaci QueryParams
  children?: (
    gto: GenericTypeObject<T, G, U>,
    data: T | undefined,
    fetched: boolean,
    refetch: () => void,
    query: QueryParams<U, G>,
  ) => any; // wszystkie elementy wewnatrz 'DataProvider' będą miały dostęp do kontekstu 'dataContext'
  //      aby pobrać kontekst należy użyć useDataContext<T, ...>() z jednakowymi typami generycznymi
}

export const DataProvider = <T, G, U = T extends (infer U)[] ? U : never>({
  fetchFun,
  fetchArgs,
  query = {},
  children,
}: DataProviderProps<T, G, U>) => {
  const { handleErrors } = useErrorHandling();
  const [dataPage, setDataPage] = useState<{ data: T; page?: number; pages?: number }>();
  const [loading, setLoading] = useState(false);
  const [args, setArgs] = useState<any[]>([]);

  const dataContext = createDataContext<T, G, U>();

  const [savedQuery, setSavedQuery] = useState<QueryParams<U, G>>(query);

  const GTO = useRef({} as GenericTypeObject<T, G, U>);
  const firstFetch = useRef(true);

  useEffect(() => {
    if (dataPage?.page !== null)
      // no idea why it works but it works
      setLoading(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setArgs([...fetchArgs, savedQuery]);
  }, [fetchArgs, savedQuery]);

  useEffect(() => {
    if (firstFetch.current) {
      firstFetch.current = false;
      return;
    }
    if (loading) {
      fetchFun(...args)
        .then((res) => {
          if (handleErrors(res.error).ok) {
            setDataPage({
              data: res.data,
              page: res.page,
              pages: res.pages,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [loading, fetchFun]);

  return (
    <dataContext.Provider
      value={{
        data: dataPage?.data,
        page: dataPage?.page,
        pages: dataPage?.pages,
        loading: false, // to remove -> changes to this param resets the component and all below it
        refetch: () => setLoading(true),
        changeQuery: (params) => {
          setSavedQuery({ ...savedQuery, page: 1, ...params });
          setTimeout(() => setLoading(true), 10);
        },
      }}
    >
      {children && children(GTO.current, dataPage?.data, !loading, () => setLoading(true), savedQuery)}
    </dataContext.Provider>
  );
};

interface DataFilterProps<T, G, U> {
  gto: GenericTypeObject<T, G, U>; // GenericTypeObject do poprawnego przekazywania typów generycznych
  filterBy: { [label in keyof G]: string }; // lista filtrów które mają być aktywne wraz z kluczami translacyjnymi
  dateFilters?: (keyof G)[];
  dateRangeFilters?: (keyof G)[]; //
  filterRef: MutableRefObject<G>; // referancja do początkowych wartości filtrów      !!! Ważne !!!   Należy podać wartości pól o kluczach zgodnych z
  //                                                  wartościami 'filterBy' żeby poprawnie odczytać typy tych wartości
  filterRowElement?: ReactElement; // element wiersza                  default = div
  filterWrapperElement?: ReactElement; // element wrappera filtrów         default = div
  filterInputWrapperElement?: ReactElement; //
  filterElement?: ReactElement; // element filtra                   default = input
  filterApplyElement?: ReactElement; // element zatwierdzający zmiany    default = button
  translation: any; // funkcja translacji
  children?: any; // element 'children' będzie ustawiony w wewnątrz elementu 'filterRowElement' w którym znajduje się 'filterApplyElement'
}

export const DataFilter = <T, G, U = T extends (infer U)[] ? U : never>({
  filterBy,
  dateFilters,
  dateRangeFilters,
  filterRef,
  filterRowElement = <div />,
  filterWrapperElement = <div />,
  filterInputWrapperElement = <div />,
  filterElement = <input />,
  filterApplyElement = <button />,
  translation,
  children,
}: DataFilterProps<T, G, U>) => {
  const [dateFiltersState, setDateFilters] = useState<{ [k in keyof G]?: Date }>({});
  const [textFilters, setTextFilters] = useState<{ [k in keyof G]?: string }>({});
  const [numFilters, setNumFilters] = useState<{ [k in keyof G]?: number }>({});
  const [boolFilters, setBoolFilters] = useState<{ [k in keyof G]?: boolean }>({});

  const [rawNumStr, setRawNumStr] = useState<{ [k in keyof G]?: string }>({});

  const dataContext = useDataContext<T, G, U>();

  useEffect(() => {
    let tempS: { [k in keyof G]?: string } = {};
    let tempN: { [k in keyof G]?: number } = {};
    let tempB: { [k in keyof G]?: boolean } = {};
    let tempRNS: { [k in keyof G]?: string } = {};
    let tempD: { [k in keyof G]?: Date } = {};

    for (let k of Object.keys(filterBy)) {
      const f = k as keyof G;
      if (typeof filterRef.current[f] === "string") tempS = { ...tempS, [f]: filterRef.current[f] };
      else if (typeof filterRef.current[f] === "number") {
        tempN = { ...tempN, [f]: filterRef.current[f] };
        if (filterRef.current[`${String(f)}_compare` as keyof G] !== undefined) {
          const mode = filterRef.current[`${String(f)}_compare` as keyof G] as NumberComparisonMode;
          let prefix = numberComparisonModeToString(mode);
          tempRNS = { ...tempRNS, [f]: `${prefix} ${filterRef.current[f]}` };
        } else if (!Number.isNaN(filterRef.current[f])) tempRNS = { ...tempRNS, [f]: filterRef.current[f] };
        else tempRNS = { ...tempRNS, [f]: "" };
      } else if (typeof filterRef.current[f] === "boolean") tempB = { ...tempB, [f]: filterRef.current[f] };
      else if (filterRef.current[f] instanceof Date) {
        tempD = { ...tempD, [f]: filterRef.current[f] };
        if (filterRef.current[`${String(f)}_end` as keyof G] !== undefined) {
          const v = filterRef.current[`${String(f)}_end` as keyof G] as Date;
          tempD = { ...tempD, [`${String(f)}_end`]: v };
        }
      }
    }

    setTextFilters(tempS);
    setNumFilters(tempN);
    setBoolFilters(tempB);
    setDateFilters(tempD);

    setRawNumStr(tempRNS);
  }, [filterBy, filterRef]);

  const setRef = (key: keyof G, value: any) => {
    filterRef.current = { ...filterRef.current, [key]: value } as G;
  };

  const handleTextChange = (key: keyof G, event: React.FormEvent<HTMLInputElement>) => {
    const value: string = event.currentTarget.value;
    setTextFilters({ ...textFilters, [key]: value });
    setRef(key, value);
  };

  const handleNumberChange = (key: keyof G, event: React.FormEvent<HTMLInputElement>) => {
    const v = event.currentTarget.value;
    const formatted = `${numberComparisonModeToString(parseNumberComparisonMode(v))} ${v.replaceAll(/[^0-9]/gi, "")}`;
    const value: number = Number.parseInt(formatted.replaceAll(/[^0-9]/gi, ""));
    setRawNumStr({ ...rawNumStr, [key]: formatted });
    setNumFilters({ ...numFilters, [key]: value });
    setRef(key, value);
  };

  const handleBoolChange = (key: keyof G, event: React.FormEvent<HTMLInputElement>) => {
    const value: boolean = event.currentTarget.checked;
    setBoolFilters({ ...boolFilters, [key]: value });
    setRef(key, value);
  };

  const handleDatesChange = (
    key: keyof G,
    date: Date | [Date | null, Date | null] | null,
    e: SyntheticEvent<any, Event> | undefined,
  ) => {
    if (date instanceof Date || (date === null && dateFilters?.includes(key))) {
      setDateFilters({ ...dateFiltersState, [key]: date });
      setRef(key, date);
    } else if (date !== null && dateRangeFilters?.includes(key) && e !== undefined) {
      const [start, end] = date;
      setDateFilters({ ...dateFiltersState, [key]: start, [`${String(key)}_end` as keyof G]: end });
      setRef(key, start);
      setRef(`${String(key)}_end` as keyof G, end);
    }
  };

  if (dataContext.isUndefined) return <ContextError consumerName="DataProvider.Filters" providerName="DataProvider" />;
  return (
    <>
      {cloneElement(filterRowElement, {
        children: (
          <>
            {cloneElement(filterWrapperElement, {
              children: (
                <>
                  {Object.keys(filterBy).length > 0 &&
                    cloneElement(filterApplyElement, {
                      type: "button",
                      onClick: () => {
                        let f: { [k in keyof G]: any } = {
                          ...textFilters,
                          ...numFilters,
                          ...boolFilters,
                          ...dateFiltersState,
                        };

                        for (let m of Object.keys(f)) {
                          const k = m as keyof G;

                          if (typeof f[k] === "string" && f[k] === "") f = { ...f, [k]: undefined };
                          else if (typeof f[k] === "number") {
                            if (!Number.isNaN(f[k]) && f[k] > 0) {
                              const mode = parseNumberComparisonMode(rawNumStr[k]);

                              if (mode !== undefined) {
                                f = { ...f, [`${String(k)}_compare` as keyof G]: mode };
                                setRef(`${String(k)}_compare` as keyof G, mode);
                              }
                            } else {
                              f = {
                                ...f,
                                [k]: undefined,
                                [`${String(k)}_compare`]: undefined,
                              };
                              setRef(`${String(k)}_compare` as keyof G, undefined);
                            }
                          } else if ((f[k] as any) instanceof Date) {
                            if (f[k] === null || f[k] === undefined) f = { ...f, [k]: undefined };
                          }
                        }

                        dataContext.changeQuery({ filter: f as G });
                      },
                      children: <>{translation("apply")}</>,
                    })}
                </>
              ),
            })}
          </>
        ),
      })}
      {cloneElement(filterRowElement, {
        children: (
          <>
            {cloneElement(filterWrapperElement, {
              children: (
                <>
                  {Object.keys(filterBy).map((k) => {
                    const f = k as keyof G;
                    return cloneElement(filterInputWrapperElement, {
                      key: `filter_${String(f)}_wrapper`,
                      children: (
                        <>
                          {typeof filterRef.current[f] === "string" && textFilters[f] !== undefined ? (
                            cloneElement(filterElement, {
                              key: `filter_${String(f)}`,
                              onChange: (e: React.FormEvent<HTMLInputElement>) => handleTextChange(f, e),
                              id: filterBy[f],
                              value: textFilters[f],
                              type: "text",
                              autoComplete: "off",
                              placeholder: translation(filterBy[f]),
                            })
                          ) : typeof filterRef.current[f] === "number" && numFilters[f] !== undefined ? (
                            cloneElement(filterElement, {
                              key: `filter_${String(f)}`,
                              onChange: (e: React.FormEvent<HTMLInputElement>) => handleNumberChange(f, e),
                              id: filterBy[f],
                              value: rawNumStr[f] ?? "",
                              type: "text",
                              autoComplete: "off",
                              placeholder: translation(filterBy[f]),
                            })
                          ) : typeof filterRef.current[f] === "boolean" && boolFilters[f] !== undefined ? (
                            cloneElement(filterElement, {
                              key: `filter_${String(f)}`,
                              onChange: (e: React.FormEvent<HTMLInputElement>) => handleBoolChange(f, e),
                              id: filterBy[f],
                              checked: boolFilters[f],
                              type: "checkbox",
                            })
                          ) : dateFilters?.includes(f) ? (
                            <DatePicker
                              key={`filter_${String(f)}`}
                              id={filterBy[f]}
                              onChange={(date, e) => handleDatesChange(f, date, e)}
                              selected={dateFiltersState[f]}
                              startDate={dateFiltersState[f]}
                              endDate={dateFiltersState[`${String(f)}_end` as keyof G]}
                              selectsRange={dateRangeFilters?.includes(f)}
                              dateFormat="dd-MM-yyyy"
                              locale={getLocale()}
                              adjustDateOnChange
                              isClearable
                              customInput={filterElement}
                              placeholderText={translation(filterBy[f])}
                              closeOnScroll
                            />
                          ) : null}
                        </>
                      ),
                    });
                  })}
                </>
              ),
            })}
            {children}
          </>
        ),
      })}
    </>
  );
};

interface DataPaginationProps<T, G, U> {
  gto: GenericTypeObject<T, G, U>;
  paddingRight?: string;
}

const DataPagination = <T, G, U>({ paddingRight }: DataPaginationProps<T, G, U>) => {
  const dataContext = useDataContext<T, G>();

  if (dataContext.isUndefined)
    return <ContextError consumerName="DataProvider.Pagination" providerName="DataProvider" />;
  return (
    <Pagination
      page={dataContext.page ?? 1}
      pages={dataContext.pages}
      change={(n) => dataContext.changeQuery({ page: n })}
      height="40px"
      paddingRight={paddingRight}
    />
  );
};

interface DataRefreshButtonProps<T, G, U> {
  gto: GenericTypeObject<T, G, U>;
  label: string;
}

const DataRefreshButton = <T, G, U>({ label }: DataRefreshButtonProps<T, G, U>) => {
  const dataContext = useDataContext<T, G, U>();
  const { refetchCounters } = useAppContext();

  const refreshClick = () => {
    dataContext.refetch();
    refetchCounters();
  };

  if (dataContext.isUndefined)
    return <ContextError consumerName="DataProvider.RefreshButton" providerName="DataProvider" />;
  return <Button type="button" label={label} click={refreshClick} />;
};

DataProvider.Pagination = DataPagination;
DataProvider.RefreshButton = DataRefreshButton;
