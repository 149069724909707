import { useTranslation } from "react-i18next";
import styles from "./SecondaryRow.module.css";
import { PriceWithService } from "api/entities/price";
import { CompanyCommon } from "api/entities/bases/companyCommon";
import { useEffect, useState } from "react";
import api from "api";
import Button from "commons/Button/Button";
import ListInput, { ListType } from "commons/Registration/ListInput/ListInput";
import SingleInput from "commons/Registration/SingleInput";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";
import { PriceCreate } from "api/entities/bases/price";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import i18next from "i18next";

interface ContractInputs extends PriceCreate {
  contract_id: string;
}

export default function SecondaryRow(props: {
  contract_id: string;
  expand: boolean;
  currency: string;
  company: CompanyCommon;
  editMode: boolean;
}) {
  const { t } = useTranslation(["dispatcherView", "services"], { keyPrefix: "contracts.prices" });

  const [data, setData] = useState<PriceWithService[]>();
  // 0 - listing, 1 - add, 2 - edit, 3 - remove
  const [mode, setMode] = useState(0);
  const { handleErrors } = useErrorHandling();
  const [loading, setLoading] = useState(false);
  const [editedPrice, setEditedPrice] = useState<{ [name: string]: string }>({});
  const [toRemove, setToRemove] = useState(-1);

  const [fetchedData, setFetchedData] = useState<PriceWithService[]>([]);

  // Przechowuje info o wybranym kontrakcie
  const [inputs, setInputs] = useState<ContractInputs>({
    contract_id: props.contract_id,
    service_id: "",
    price: 0,
  });

  const getPrices = () => {
    async function getData() {
      api.fetchPricesByContract(props.contract_id).then((res) => {
        setData(res.data);
      });
    }
    getData();
  };

  useEffect(() => {
    getPrices();

    // eslint-disable-next-line
  }, []);

  const clearInputs = () => {
    setInputs({
      ...inputs,
      service_id: "",
      price: 0,
    });
  };

  useEffect(() => {
    setFetchedData([]);
    if (mode === 2) {
      setEditedPrice({});
      api.fetchPricesByContractEditable(inputs.contract_id).then((res) => {
        if (handleErrors(res.error).ok) setFetchedData(res.data);
      });
    } else if (mode === 3) {
      setToRemove(-1);
      api.fetchPricesByContractRemovable(inputs.contract_id).then((res) => {
        if (handleErrors(res.error).ok) setFetchedData(res.data);
      });
    }
  }, [mode, inputs.contract_id, handleErrors]);

  const [button, setButton] = useState({
    success: false,
  });

  const resetSubmitButton = () => {
    if (button.success) setButton({ success: false });
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    resetSubmitButton();
    const value: string = event.currentTarget.value;
    setInputs({
      ...inputs,
      [event.currentTarget.id]: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    if (mode === 1) {
      try {
        api
          .registerPrice({
            contract_id: inputs.contract_id,
            service_id: inputs.service_id,
            price: inputs.price,
          })
          .then((res) => {
            clearInputs();
            if (handleErrors(res.error).ok) {
              setButton({ success: true });
            } else {
              setButton({ success: false });
            }
          })
          .catch((e) => {
            setButton({ success: false });
            console.log(e);
          })
          .finally(() => {
            setLoading(false);
            setMode(0);
            getPrices();
          });
      } catch (e) {
        setButton({ success: false });
        console.log(e);
      } finally {
      }
    } else if (mode === 2) {
      if (Object.entries(editedPrice).length === 0) setLoading(false);

      try {
        Object.entries(editedPrice).forEach(([name, price]) => {
          if (Number.isNaN(price)) {
            setLoading(false);
            return;
          }

          const edited = fetchedData.find((v) => v.service.name === name);
          if (edited && edited.price !== parseFloat(price))
            api
              .editPrice(edited.contract_id, edited.service_id, { price: parseFloat(price) })
              .then((res) => {
                handleErrors(res.error);
                clearInputs();
                setButton({ success: true });
              })
              .catch((e) => {
                setButton({ success: false });
                console.log(e);
              })
              .finally(() => {
                setLoading(false);
                setMode(0);
                getPrices();
              });
        });
      } catch (e) {
        setButton({ success: false });
        console.log(e);
      } finally {
        setEditedPrice({});
      }
    } else if (mode === 3) {
      if (toRemove !== -1) {
        try {
          api
            .removePrice(inputs.contract_id, fetchedData[toRemove].service_id)
            .then((e) => {
              handleErrors(e.error);
            })
            .catch((e) => {
              setButton({ success: false });
              console.log(e);
            })
            .finally(() => {
              setLoading(false);
              setMode(0);
              getPrices();
            });
        } catch (e) {
          setButton({ success: false });
          console.log(e);
        } finally {
          setToRemove(-1);
        }
      } else {
        setLoading(false);
      }
    }
  };

  const onChangeEdit = (event: React.FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    const valueName = event.currentTarget.id;

    if (Number.isNaN(newValue)) {
      const filtered = Object.entries(editedPrice).filter(([name]) => name !== valueName);

      setEditedPrice({});
      filtered.forEach(([name, price]) => {
        setEditedPrice({
          ...editedPrice,
          [name]: price,
        });
      });
    } else
      setEditedPrice({
        ...editedPrice,
        [valueName]: newValue,
      });
  };

  return (
    <form onSubmit={handleSubmit} autoComplete={"off"}>
      <div className={styles.content_wrapper}>
        <div className={styles.content}>
          <div className={styles.header}>{t("title")}</div>
          <div className={styles.item}>
            {mode === 0 && data && (
              <table className={styles.table}>
                <thead className={styles.thead}>
                  <tr className={styles.tr_td}>
                    <th className={styles.th}>{t("name")}</th>
                    <th className={styles.th}>{t("price")}</th>
                  </tr>
                </thead>
                <tbody className={styles.tbody}>
                  {data.map((x) => {
                    return (
                      <tr key={x.service_id}>
                        <td className={styles.td}>{i18next.t(x.service.name, { ns: "services" })}</td>
                        <td className={styles.td}>{x.price + " " + props.currency}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {mode === 1 && data && (
              <div style={{ display: "flex", flexDirection: "column", gap: "15px", alignItems: "center" }}>
                <ListInput
                  setInputs={(id: string) => setInputs({ ...inputs, service_id: id })}
                  labelText={""}
                  id="service_name"
                  mode={ListType.ServiceAvailableForContract}
                  contract_id={inputs.contract_id}
                />
                <SingleInput change={handleChange} text={t("price")} id="price" value={inputs.price} />
                <SubmitButton loading={loading} success={button.success} />
              </div>
            )}
            {mode === 2 && data && (
              <>
                <div className={styles.pricings_list_wrapper}>
                  <div className={styles.pricings_header}>Cennik:</div>
                  <div className={styles.pricings_items}>
                    {fetchedData.map((data) => {
                      const dataname = data.service.name;

                      return (
                        <div
                          className={styles.pricings_item}
                          key={data.service_id}
                          style={{
                            display: "flex",
                            textAlign: "left",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              width: "45%",
                              padding: "10px",
                              height: "36px",
                            }}
                          >
                            {i18next.t(data.service.name, { ns: "services" })}
                          </span>

                          <div style={{ width: "40%" }}>
                            <input
                              id={dataname}
                              value={editedPrice[dataname] ?? ""}
                              placeholder={`${data.price.toFixed(2)}`}
                              type="text"
                              autoComplete="off"
                              onChange={onChangeEdit}
                              style={{
                                padding: "5px",
                                border: "1px solid var(--gray-base)",
                                borderRadius: "4px",
                                height: "36px",
                                width: "80%",
                              }}
                            />

                            <span style={{ height: "36px", padding: "10px" }}>zł.</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    alignItems: "center",
                  }}
                >
                  <SubmitButton loading={loading} success={button.success} />
                </div>
              </>
            )}
            {mode === 3 && data && (
              <>
                <div className={styles.pricings_list_wrapper}>
                  <div className={styles.pricings_header}>Cennik:</div>
                  {fetchedData.map((data, i) => {
                    return (
                      <div
                        className={i === toRemove ? styles.pricings_item_marked : styles.pricings_item}
                        key={data.service_id}
                        onClick={() => {
                          setToRemove(toRemove === i ? -1 : i);
                        }}
                      >
                        <p>
                          {i18next.t(data.service.name, { ns: "services" })} - {data.price} zł.
                        </p>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    alignItems: "center",
                  }}
                >
                  <SubmitButton loading={loading} success={button.success} remove />
                </div>
              </>
            )}
            {props.editMode && (
              <tr>
                <td colSpan={5}>
                  <div style={{ display: "flex", gap: "10px", padding: "10px" }}>
                    {mode !== 0 && (
                      <Button
                        label={t("listing")}
                        click={() => {
                          setMode(0);
                          getPrices();
                          setButton({ success: false });
                        }}
                      />
                    )}
                    <Button
                      label={t("add")}
                      id={"success"}
                      click={() => {
                        setMode(1);
                        getPrices();
                        setButton({ success: false });
                      }}
                    />
                    <Button
                      label={t("edit")}
                      id={"success"}
                      click={() => {
                        setMode(2);
                        getPrices();
                        setButton({ success: false });
                      }}
                    />
                    <Button
                      label={t("remove")}
                      id={"delete"}
                      click={() => {
                        setMode(3);
                        getPrices();
                        setButton({ success: false });
                      }}
                    />
                  </div>
                </td>
              </tr>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
