import api from "api";
import { AuditorWithUserAndAddress } from "api/entities/auditor";
import { AuditorEdit } from "api/entities/bases/auditor";
import { UserCreateRequest } from "api/entities/bases/user";
import { WorkerType } from "api/entities/enums";
import { APIError, HTTPErrorType } from "api/entities/error";

type Employee = {
  addr_id: string;
  addr_name: string;
  addr_lati: number | undefined;
  addr_long: number | undefined;

  auditor_co_id?: string;

  name: string;
  surname: string;
  email: string;
  phone_prefix: string;
  phone: string;
  login: string;
  password: string;
  password_confirm: string;
  types: WorkerType[];
};

const getDefaultFormData = (auditor_co_id?: string): Employee => {
  return {
    addr_id: "",
    addr_name: "",
    addr_lati: undefined,
    addr_long: undefined,

    auditor_co_id: auditor_co_id ?? "",

    name: "",
    surname: "",
    email: "",
    phone: "",
    phone_prefix: "",
    login: "",
    password: "",
    password_confirm: "",
    types: [],
  };
};

const getFormData = (e?: AuditorWithUserAndAddress, auditor_co_id?: string) => {
  if (e === undefined) return getDefaultFormData(auditor_co_id);
  return {
    addr_id: e.addr_id,
    addr_name: e.address.name,
    addr_lati: e.address.latitude,
    addr_long: e.address.longitude,

    auditor_co_id: auditor_co_id ?? e.user.company_id,

    name: e.user_name,
    surname: e.user_surname,
    email: e.user.email,
    phone: e.user.phone,
    phone_prefix: e.user.phone_prefix,
    login: e.user_login,
    password: "",
    password_confirm: "",
    types: e.types,
  };
};

const handleSubmit = async (e: Employee): Promise<HTTPErrorType | undefined> => {
  if (e.auditor_co_id === undefined || e.auditor_co_id === "")
    return { status: 404, err_str: "err29_no_auditor_co", detail: "", params: [] } as APIError;

  if (e.password !== e.password_confirm)
    return { status: 422, err_str: "err47_passwords_must_match", detail: "", params: [] } as APIError;

  const emp: UserCreateRequest = {
    name: e.name,
    surname: e.surname,
    email: e.email,
    phone_prefix: e.phone_prefix,
    phone: e.phone,
    login: e.login,
    password: e.password,
    company_id: e.auditor_co_id,
    auditor: {
      address:
        e.addr_id === ""
          ? {
              name: e.addr_name,
              latitude: e.addr_lati!,
              longitude: e.addr_long!,
            }
          : e.addr_id,
      types: e.types,
    },
  };

  const x = await api.registerAuditor(emp);

  return x.error;
};

const handleEdit = async (employee: AuditorWithUserAndAddress, edit: Employee): Promise<HTTPErrorType | undefined> => {
  if (edit.password !== edit.password_confirm)
    return { status: 422, err_str: "err47_passwords_must_match", detail: "", params: [] } as APIError;

  const emp: AuditorEdit = {
    password: edit.password === "" ? undefined : edit.password,
  };

  if (employee.addr_id !== edit.addr_id) {
    emp.address =
      edit.addr_id === ""
        ? {
            name: edit.addr_name,
            latitude: edit.addr_lati!,
            longitude: edit.addr_long!,
          }
        : edit.addr_id;
  }
  if (employee.user_name !== edit.name) {
    emp.name = edit.name;
  }
  if (employee.user_surname !== edit.surname) {
    emp.surname = edit.surname;
  }
  if (employee.user.email !== edit.email) {
    emp.email = edit.email;
  }
  if (employee.user.phone !== edit.phone) {
    emp.phone = edit.phone.indexOf("+") === -1 ? edit.phone : edit.phone.substring(3);
  }
  if (employee.user.phone !== edit.phone) {
    emp.phone_prefix = edit.phone.indexOf("+") === -1 ? "+48" : edit.phone.substring(0, 3);
  }
  if (employee.user_login !== edit.login) {
    emp.login = edit.login;
  }
  if (employee.types.length !== edit.types.length || !employee.types.every((v) => edit.types.includes(v))) {
    emp.types = edit.types;
  }

  const x = await api.editAuditor(employee.id, emp); // TODO: Add proper error handling

  return x.error;
};

const employeeFormService = {
  getDefaultFormData,
  getFormData,
  handleSubmit,
  handleEdit,
};

export { employeeFormService, Employee };
