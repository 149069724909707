import { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import localStyles from "./ListInput.module.css";
import commonStyles from "commons/Registration/Registration.module.css";
import api from "api";
import { WorkerType, WorkerTypeList } from "api/entities/enums";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import CheckboxListInput from "../CheckboxListInput/CheckboxListInput";

export enum ListType {
  AuditorCompany,
  Auditor,
  AuditorByManagerId,
  DispatcherCompany,
  Address,
  Contract,
  Service,
  ServiceAvailableForContract,
  ServiceAvailableInCommission,
  WorkerType,
}

interface localProps {
  setInputs: (id: string, name: string, values?: string[]) => void; // funkcja ustawiająca id i nazwę pola
  labelText: string;
  id: string; // id może być dowolne, o ile nie koliduje z innymi inputami w formularzu
  mode: ListType; // Jaki typ danych ma wyświetlić lista
  auditor_co_id?: string; // do pobierania listy rewidentów danej firmy
  contract_id?: string; // do pobierania usług dostępnych do dodania do kontraktu
  resetElement?: boolean;
  updateElement?: boolean;
  onElementClear?: () => void; // dodatkowa funkcja wywoływana przy kliknięciu "x", np. do czyszczenia
  onAuditorClick?: (auditorId: string) => void;
  value?: string;
  values?: string[];
  multiple?: boolean;
  resetFormButton?: () => void;
  commissionAddress?: { latitude: number; longitude: number; name?: string };
}

const calculateStraightDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const R = 6371; // Promień Ziemi w km
  const toRad = (angle: number) => (angle * Math.PI) / 180;
  const radLat1 = toRad(lat1);
  const radLat2 = toRad(lat2);
  const radLon1 = toRad(lon1);
  const radLon2 = toRad(lon2);

  const distance = Math.acos(
    Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radLon2 - radLon1),
  );

  return R * distance;
};

export default function ListInput(props: localProps) {
  const { t } = useTranslation(["common", "services"], { keyPrefix: "listInput" });
  const { handleErrors } = useErrorHandling();
  const et = useTranslation("common", { keyPrefix: "workerTypes" });

  const [isFetched, setIsFetched] = useState(false);
  const fetchedData = useRef<Object[]>([]);

  const [lockedState, setLockedState] = useState(false);

  const [value, setValue] = useState("");
  const [values, setValues] = useState<string[]>([]);
  const [workerTypes, setWorkerTypes] = useState<WorkerType[]>([]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const listRef = useRef(null);

  const handleItemClick = (id: string, name: string) => {
    if (props.resetFormButton) props.resetFormButton();
    if (props.multiple === true) {
      const newValues = values.concat(id);
      props.setInputs("", "", newValues);
      setValues(newValues);
      setValue(`${newValues.map((d) => et.t(`${d}`)).join("; ")}`);
    } else {
      props.setInputs(id, name);
      setValue(name);
      setLockedState(true);
    }
    if (props.onAuditorClick) props.onAuditorClick(id);
    setDropdownOpen(false);
  };

  const loaded = useRef(false);
  useEffect(() => {
    if (props.multiple === true && props.values) {
      setValues(props.values);
      setValue(props.values.map((d) => et.t(`${d}`)).join("; "));
    } else if (props.value) {
      setValue(props.value);
      setLockedState(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loaded.current === false) loaded.current = true;
    else {
      setLockedState(false);
      setValue("");
      setValues([]);
    }
  }, [props.resetElement]);

  useEffect(() => {
    if (loaded.current === true) {
      if (props.multiple === true && props.values) {
        setValues(props.values);
        setValue(props.values.map((d) => et.t(`${d}`)).join("; "));
      } else if (props.value) {
        setValue(props.value);
        setLockedState(true);
      }
    }
    // eslint-disable-next-line
  }, [props.updateElement]);

  useEffect(() => {
    if (!isFetched)
      switch (props.mode) {
        case ListType.AuditorCompany: {
          api.fetchAuditorCompanies().then((res) => {
            handleErrors(res.error);
            fetchedData.current = res.data;
            setIsFetched(true);
          });
          break;
        }
        case ListType.Auditor: {
          api.fetchAuditorsWithUser().then((res) => {
            handleErrors(res.error);
            fetchedData.current = res.data;
            setIsFetched(true);
          });
          break;
        }
        case ListType.AuditorByManagerId: {
          if (props.auditor_co_id)
            api
              .fetchAuditorsByAuditorCompany(props.auditor_co_id, {
                filter: { worker_type: workerTypes },
              })
              .then((res) => {
                fetchedData.current = res.data;
                setIsFetched(true);
              });
          break;
        }
        case ListType.DispatcherCompany: {
          api.fetchDispatcherCompanies().then((res) => {
            handleErrors(res.error);
            fetchedData.current = res.data;
            setIsFetched(true);
          });
          break;
        }
        case ListType.Address: {
          api.fetchAddresses().then((res) => {
            handleErrors(res.error);
            fetchedData.current = res.data;
            setIsFetched(true);
          });
          break;
        }
        case ListType.Contract: {
          api.fetchContractsWithAuditorDispatcherCompany().then((res) => {
            handleErrors(res.error);
            fetchedData.current = res.data;
            setIsFetched(true);
          });
          break;
        }
        case ListType.Service: {
          api.fetchServices().then((res) => {
            handleErrors(res.error);
            fetchedData.current = res.data;
            setIsFetched(true);
          });
          break;
        }
        case ListType.ServiceAvailableInCommission: {
          api.fetchServicesAvailableByCompany(props.auditor_co_id ?? "error").then((res) => {
            handleErrors(res.error);
            fetchedData.current = res.data;
            setIsFetched(true);
            if (res.data.length === 0) props.setInputs("-1", "-1");
          });
          break;
        }
        case ListType.ServiceAvailableForContract: {
          if (props.contract_id)
            api.fetchServicesAvailableForContract(props.contract_id).then((res) => {
              handleErrors(res.error);
              fetchedData.current = res.data;
              setIsFetched(true);
            });
          break;
        }
        case ListType.WorkerType: {
          fetchedData.current = WorkerTypeList;
          setIsFetched(true);
          break;
        }
      }
  }, [props, props.mode, props.auditor_co_id, props.contract_id, isFetched, value, handleErrors]);

  return (
    <div style={{ position: "relative" }} className={commonStyles.inputContainer}>
      {props.mode === ListType.AuditorByManagerId && (
        <CheckboxListInput
          mode={ListType.WorkerType}
          id="workerTypeFilter"
          labelText={t("workerTypeFilter")}
          setInputs={(id, name, values) => {
            setWorkerTypes(values as WorkerType[]);
            setIsFetched(false);
          }}
          values={workerTypes}
        />
      )}
      <div className={commonStyles.single_input}>
        {props.labelText !== "" && (
          <label htmlFor={props.id} className={commonStyles.input_label} title={props.labelText}>
            {props.labelText}
          </label>
        )}
        <div className={localStyles.input_with_button}>
          <input
            id={props.id}
            className={commonStyles.input}
            value={value}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const newValue = e.currentTarget.value;
              setValue(newValue);
              if (props.multiple === true) {
                props.setInputs("", "", []);
              } else {
                props.setInputs("", newValue);
              }
            }}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setTimeout(() => setDropdownOpen(false), 150)}
            type="text"
            placeholder={t("chooseFromList") ?? ""}
            autoComplete="off"
            required
            spellCheck={false}
            onInvalid={(e) => e.currentTarget.setCustomValidity(t("onInvalid"))}
            onInput={(e) => e.currentTarget.setCustomValidity("")}
            multiple={props.multiple ?? false}
          />
          <div
            onClick={() => {
              props.onElementClear && props.onElementClear();

              setLockedState(false);
              setValue("");
              setValues([]);
              props.setInputs("", "", []);
            }}
            className={localStyles.button}
            id={lockedState === false && value === "" ? localStyles.button_disabled : ""}
          >
            <FontAwesomeIcon
              className={localStyles.icon}
              id={lockedState === false && value === "" ? localStyles.icon_disabled : ""}
              icon={faXmark}
            />
          </div>
        </div>
      </div>
      <CSSTransition
        in={dropdownOpen}
        nodeRef={listRef}
        timeout={{
          enter: 400,
          exit: 400,
        }}
        classNames={{
          enter: localStyles.enter,
          enterActive: localStyles.enterActive,
          exit: localStyles.exit,
          exitActive: localStyles.exitActive,
        }}
        mountOnEnter
        unmountOnExit
        appear
      >
        <div style={{ padding: "0 10px 0 10px" }} ref={listRef} className={localStyles.list_wrapper}>
          <div className={localStyles.list}>
            <MapData
              mode={props.mode}
              data={fetchedData.current}
              currentInput={value}
              handleClick={handleItemClick}
              isLocked={lockedState}
              commissionAddress={props.commissionAddress}
            />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

interface MapDataProps {
  mode: ListType;
  data: Object[];
  currentInput: string;
  handleClick: (id: string, name: string) => void;
  isLocked: boolean;
  commissionAddress?: { latitude: number; longitude: number; name?: string };
}

function MapData(props: MapDataProps) {
  const { t } = useTranslation("common", { keyPrefix: "listData" });

  if (props.mode === ListType.AuditorCompany) {
    const filteredData = (props.data as any[]).filter((data) =>
      data.company_name.toLowerCase().includes(props.currentInput.toLowerCase()),
    );
    if (filteredData.length === 0) {
      return <div className={localStyles.list_item}>{t("noResults")}</div>;
    }
    return (
      <>
        {filteredData.map((data) => (
          <div
            onClick={() => props.handleClick(data.id, data.company_name)}
            className={localStyles.list_item}
            key={data.id}
          >
            {data.company_name}
          </div>
        ))}
      </>
    );
  } else if (props.mode === ListType.Auditor || props.mode === ListType.AuditorByManagerId) {
    const filteredData = (props.data as any[]).filter((data) =>
      (data.user.surname + " " + data.user.name).toLowerCase().includes(props.currentInput.toLowerCase()),
    );

    if (props.commissionAddress) {
      const commissionAddress = props.commissionAddress;
      filteredData.sort((a, b) => {
        const aCords = a.address && a.address.latitude && a.address.longitude;
        const bCords = b.address && b.address.latitude && b.address.longitude;

        if (!aCords && !bCords) return 0;
        if (!aCords) return 1;
        if (!bCords) return -1;

        const distA = calculateStraightDistance(
          a.address.latitude,
          a.address.longitude,
          commissionAddress.latitude,
          commissionAddress.longitude,
        );
        const distB = calculateStraightDistance(
          b.address.latitude,
          b.address.longitude,
          commissionAddress.latitude,
          commissionAddress.longitude,
        );
        return distA - distB;
      });
    }

    if (filteredData.length === 0) {
      return <div className={localStyles.list_item}>{t("noResults")}</div>;
    }
    return (
      <>
        {filteredData.map((data) => {
          const fullName = `${data.user.surname} ${data.user.name}`;
          let distanceElement = null;
          if (props.commissionAddress && data.address && data.address.latitude && data.address.longitude) {
            const dist = calculateStraightDistance(
              data.address.latitude,
              data.address.longitude,
              props.commissionAddress.latitude,
              props.commissionAddress.longitude,
            );
            distanceElement = <span>{dist.toFixed(1)} km</span>;
          }
          return (
            <div
              onClick={() => props.handleClick(data.id, fullName)}
              key={data.id}
              className={localStyles.list_item}
              style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
            >
              <span>{fullName}</span>
              {distanceElement}
            </div>
          );
        })}
      </>
    );
  } else if (props.mode === ListType.DispatcherCompany) {
    const filteredData = (props.data as any[]).filter((data) =>
      data.company_name.toLowerCase().includes(props.currentInput.toLowerCase()),
    );
    if (filteredData.length === 0) {
      return <div className={localStyles.list_item}>{t("noResults")}</div>;
    }
    return (
      <>
        {filteredData.map((data) => (
          <div
            onClick={() => props.handleClick(data.id, data.company_name)}
            className={localStyles.list_item}
            key={data.id}
          >
            {data.company_name}
          </div>
        ))}
      </>
    );
  } else if (props.mode === ListType.Address) {
    const filteredData = (props.data as any[]).filter((data) =>
      data.name.toLowerCase().includes(props.currentInput.toLowerCase()),
    );
    if (filteredData.length === 0) {
      return <div className={localStyles.list_item}>{t("noResults")}</div>;
    }
    return (
      <>
        {filteredData.map((data) => (
          <div onClick={() => props.handleClick(data.id, data.name)} className={localStyles.list_item} key={data.id}>
            {data.name}
          </div>
        ))}
      </>
    );
  } else if (props.mode === ListType.Contract) {
    if ((props.data as any[]).length === 0) {
      return <div className={localStyles.list_item}>{t("noResults")}</div>;
    }
    return (
      <>
        {(props.data as any[]).map((data) => {
          const name = `${data.auditor_company.company_name} - ${data.dispatcher_company.company_name}`;
          return (
            <div onClick={() => props.handleClick(data.id, name)} className={localStyles.list_item} key={data.id}>
              {name}
            </div>
          );
        })}
      </>
    );
  } else if (
    props.mode === ListType.Service ||
    props.mode === ListType.ServiceAvailableForContract ||
    props.mode === ListType.ServiceAvailableInCommission
  ) {
    const filteredData = (props.data as any[]).filter((data) =>
      data.name.toLowerCase().includes(props.currentInput.toLowerCase()),
    );
    if (filteredData.length === 0) {
      return <div className={localStyles.list_item}>{t("noResults")}</div>;
    }
    return (
      <>
        {filteredData.map((data) => (
          <div onClick={() => props.handleClick(data.id, data.name)} className={localStyles.list_item} key={data.id}>
            {i18next.t(data.name, { ns: "services" })}
          </div>
        ))}
      </>
    );
  } else if (props.mode === ListType.WorkerType) {
    const filteredData = (props.data as any[]).filter((data) => !props.currentInput.includes(t(`${data}`)));
    if (filteredData.length === 0) {
      return <div className={localStyles.list_item}>{t("noResults")}</div>;
    }
    return (
      <>
        {filteredData.map((data) => (
          <div onClick={() => props.handleClick(data, t(`${data}`))} className={localStyles.list_item} key={data}>
            {t(`${data}`)}
          </div>
        ))}
      </>
    );
  } else {
    return <></>;
  }
}
