import { UserCreateRequest } from "api/entities/bases/user";
import OperatorRegisterFormData from "./form/operator-register-form-data";

const create = (formData: OperatorRegisterFormData): UserCreateRequest => {
  let phonePrefix: string | undefined;
  let phone: string | undefined;
  const formPhone = formData.phone.replace(/\s/g, "");

  if (formPhone.startsWith("+")) {
    phonePrefix = formPhone.substring(0, 3);
    phone = formPhone.substring(3);
  } else {
    phonePrefix = "+48";
    phone = formPhone;
  }

  return {
    email: formData.email.trim(),
    login: formData.login.trim(),
    name: formData.name.trim(),
    surname: formData.surname.trim(),
    password: formData.password,
    phone: phone,
    phone_prefix: phonePrefix,
    company_id: formData.companyId,
  };
};

const operatorRegisterRequestFactory = { create };

export default operatorRegisterRequestFactory;
