import { useEffect, useRef, useState } from "react";
import FormValidationResult from "./form-validation-result";
import formValidationService from "./form-validation.service";
import useAppContext from "context/useContext";

export type FormValidationDefinition<Values extends Record<string, unknown>> = Record<
  keyof Values,
  (values: Values) => FormValidationResult
>;

type FormConfig<Values extends Record<string, unknown>> = {
  emptyValues: Values;
  validationDefinition: FormValidationDefinition<Values>;
};

const useForm = <Values extends Record<string, unknown>>(params: FormConfig<Values>) => {
  const { selectedAppLanguage } = useAppContext();

  const getDefaultValues = (): Values => {
    return { ...params.emptyValues };
  };

  const getDefaultValidationResults = (): Record<keyof Values, FormValidationResult> => {
    const results: Record<keyof Values, FormValidationResult> = {} as Record<keyof Values, FormValidationResult>;

    Object.keys(params.emptyValues).forEach((formKey) => {
      results[formKey as keyof Values] = formValidationService.defaultValidationResilt;
    });

    return results;
  };

  const getDefaultFlaggedValues = (): Record<keyof Values, boolean> => {
    const results: Record<keyof Values, boolean> = {} as Record<keyof Values, boolean>;

    Object.keys(params.emptyValues).forEach((formKey) => {
      results[formKey as keyof Values] = true;
    });

    return results;
  };

  const values = useRef(getDefaultValues());
  const validationResults = useRef(getDefaultValidationResults());
  const [flaggedValues, setFlaggedValues] = useState(getDefaultFlaggedValues);

  useEffect(() => {
    const revalidationFields = Object.keys(validationResults.current).filter(
      (formKey) => validationResults.current[formKey].isValid === false,
    );

    revalidationFields.forEach((field) => validate(field));
    // eslint-disable-next-line
  }, [selectedAppLanguage]);

  const setValues = (newValues: Values) => {
    values.current = newValues;
  };

  const isFlagged = (formKey: keyof Values): boolean => {
    return flaggedValues[formKey];
  };

  const flag = (formKey: keyof Values) => {
    setFlaggedValues({ ...flaggedValues, [formKey]: true });
  };

  const unflag = (formKey: keyof Values) => {
    setFlaggedValues({ ...flaggedValues, [formKey]: false });
  };

  const setValue = (formKey: keyof Values, value: Values[keyof Values]) => {
    values.current[formKey] = value;
  };

  const validate = (formKey: keyof Values) => {
    const validationResult = params.validationDefinition[formKey](values.current);

    validationResults.current[formKey] = validationResult;
    flag(formKey);

    return validationResult;
  };

  const setAndValidate = (formKey: keyof Values, value: Values[keyof Values]) => {
    setValue(formKey, value);
    return validate(formKey);
  };

  const validateAll = () => {
    const results: Record<keyof Values, boolean> = {} as Record<keyof Values, boolean>;

    Object.keys(params.emptyValues).forEach((formKey: keyof Values) => {
      results[formKey] = validate(formKey).isValid;
    });

    return Object.values(results).every((result) => !!result);
  };

  const restore = () => {
    values.current = getDefaultValues();
    validationResults.current = getDefaultValidationResults();
    setFlaggedValues(getDefaultFlaggedValues);
  };

  return {
    values: values.current,
    setValue,
    setValues,
    setAndValidate,
    validationResults: validationResults.current,
    validate,
    validateAll,
    flaggedValues,
    isFlagged,
    flag,
    unflag,
    restore,
  };
};

export default useForm;
