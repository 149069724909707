import backend from "api/backend";
import { LoginCredentials } from "api/entities/auth";

/**
 *  ## Object containing functions that fetch API endpoints related to authorization
 *  ### **_authApiService_** functions:
        loginAndGetToken:
            (cred: Credentials) => Promise<backendResponse<AuthResponse, undefined>>
    <br/>
        fetchPublicKey:
            () => Promise<backendResponse<string, undefined>>
 */
export const authApiService = {
  login: (cred: LoginCredentials) => {
    return backend.login(`/auth/token`, cred);
  },
  logout: () => {
    return backend.logout("/auth/logout");
  },
  refreshToken: () => {
    return backend.refreshToken("/auth/token");
  },
};
