import AuditorAddFormData from "./auditor-add-form-data";
import { FormValidationDefinition } from "commons/form/useForm";
import formValidationService from "commons/form/form-validation.service";
import auditorEditFormValidationService from "./auditor-add-form-validation.service";
import registerValidationService from "../../common/register-validation-service";

const getEmptyFormData = (): AuditorAddFormData => {
  return {
    name: "",
    surname: "",
    email: "",
    phone: "",
    login: "",
    originalLogin: "",
    loginCheck: {
      available: true,
      login: "",
    },
    companyId: "",
    companyName: "",
    types: [],
    addressName: "",
    address: "",
    password: "",
    passwordConfirm: "",
  };
};

const getFormValidationDefinition = (): FormValidationDefinition<AuditorAddFormData> => {
  return {
    address: (values) => auditorEditFormValidationService.validateAddress(values.address),
    addressName: formValidationService.ok,
    companyId: formValidationService.ok,
    companyName: formValidationService.ok,
    email: (values) => registerValidationService.validateEmail(values.email),
    login: (values) =>
      auditorEditFormValidationService.validateLogin(values.login, values.originalLogin, values.loginCheck),
    originalLogin: formValidationService.ok,
    loginCheck: formValidationService.ok,
    name: (values) => registerValidationService.validateName(values.name),
    password: (values) => formValidationService.validatePassword(values.password),
    passwordConfirm: (values) => formValidationService.validatePasswordConfirm(values.password, values.passwordConfirm),
    phone: (values) => registerValidationService.validatePhone(values.phone),
    surname: (values) => registerValidationService.validateSurname(values.surname),
    types: formValidationService.ok,
  };
};

const auditorAddFormFactory = {
  getEmptyFormData,
  getFormValidationDefinition,
};

export default auditorAddFormFactory;
