import { useTranslation } from "react-i18next";
import styles from "./SecondaryRow.module.css";
import { ContractWithDispatcherCompany } from "api/entities/contract";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import { useEffect, useState } from "react";
import { PriceCreate } from "api/entities/bases/price";
import { PriceWithService } from "api/entities/price";
import api from "api";
import ListInput, { ListType } from "commons/Registration/ListInput/ListInput";
import SingleInput from "commons/Registration/SingleInput";
import Button from "commons/Button/Button";
import i18next from "i18next";

interface ContractInputs extends PriceCreate {
  contract_id: string;
}

enum ButtonMode {
  Default,
  Add,
  Edit,
  Delete,
}

export default function SecondaryRow(props: { data: ContractWithDispatcherCompany; expand: boolean }) {
  const { t } = useTranslation(["auditorManagerView", "services"], { keyPrefix: "contracts.prices" });
  const { handleErrors } = useErrorHandling();
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(ButtonMode.Default);
  const [editedPrice, setEditedPrice] = useState<{ [name: string]: string }>({});
  const [toRemove, setToRemove] = useState(-1);

  const [fetchedData, setFetchedData] = useState<PriceWithService[]>([]);

  // Przechowuje info o wybranym kontrakcie
  const [inputs, setInputs] = useState<ContractInputs>({
    contract_id: props.data.id,
    service_id: "",
    price: 0,
  });

  useEffect(() => {
    setFetchedData([]);
    if (mode === ButtonMode.Default) {
      api.fetchPricesByContract(inputs.contract_id).then((res) => {
        if (handleErrors(res.error).ok) setFetchedData(res.data);
      });
    } else if (mode === ButtonMode.Edit) {
      setEditedPrice({});
      api.fetchPricesByContractEditable(inputs.contract_id).then((res) => {
        if (handleErrors(res.error).ok) setFetchedData(res.data);
      });
    } else if (mode === ButtonMode.Delete) {
      setToRemove(-1);
      api.fetchPricesByContractRemovable(inputs.contract_id).then((res) => {
        if (handleErrors(res.error).ok) setFetchedData(res.data);
      });
    }
  }, [mode, inputs.contract_id, handleErrors]);

  const clearInputs = () => {
    setInputs({
      ...inputs,
      service_id: "",
      price: 0,
    });
  };

  const [button, setButton] = useState({
    success: false,
  });

  const resetSubmitButton = () => {
    if (button.success) setButton({ success: false });
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    resetSubmitButton();
    const value: string = event.currentTarget.value;
    setInputs({
      ...inputs,
      [event.currentTarget.id]: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    if (mode === ButtonMode.Add) {
      try {
        api
          .registerPrice({
            contract_id: inputs.contract_id,
            service_id: inputs.service_id,
            price: inputs.price,
          })
          .then((res) => {
            clearInputs();
            if (handleErrors(res.error).ok) {
              setButton({ success: true });
            } else {
              setButton({ success: false });
            }
          })
          .catch((e) => {
            setButton({ success: false });
            console.log(e);
          })
          .finally(() => {
            setLoading(false);
            setMode(ButtonMode.Default);
          });
      } catch (e) {
        setButton({ success: false });
        console.log(e);
      } finally {
      }
    } else if (mode === ButtonMode.Edit) {
      if (Object.entries(editedPrice).length === 0) setLoading(false);

      try {
        Object.entries(editedPrice).forEach(([name, price]) => {
          if (Number.isNaN(price)) {
            setLoading(false);
            return;
          }

          const edited = fetchedData.find((v) => v.service.name === name);
          if (edited && edited.price !== parseFloat(price))
            api
              .editPrice(edited.contract_id, edited.service_id, { price: parseFloat(price) })
              .then((res) => {
                handleErrors(res.error);
                clearInputs();
                setButton({ success: true });
              })
              .catch((e) => {
                setButton({ success: false });
                console.log(e);
              })
              .finally(() => {
                setLoading(false);
                setMode(ButtonMode.Default);
              });
        });
      } catch (e) {
        setButton({ success: false });
        console.log(e);
      } finally {
        setEditedPrice({});
      }
    } else if (mode === ButtonMode.Delete) {
      if (toRemove !== -1) {
        try {
          api
            .removePrice(inputs.contract_id, fetchedData[toRemove].service_id)
            .then((e) => {
              handleErrors(e.error);
            })
            .catch((e) => {
              setButton({ success: false });
              console.log(e);
            })
            .finally(() => {
              setLoading(false);
              setMode(ButtonMode.Default);
            });
        } catch (e) {
          setButton({ success: false });
          console.log(e);
        } finally {
          setToRemove(-1);
        }
      } else {
        setLoading(false);
      }
    }
  };

  const onChangeEdit = (event: React.FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    const valueName = event.currentTarget.id;

    if (Number.isNaN(newValue)) {
      const filtered = Object.entries(editedPrice).filter(([name]) => name !== valueName);

      setEditedPrice({});
      filtered.forEach(([name, price]) => {
        setEditedPrice({
          ...editedPrice,
          [name]: price,
        });
      });
    } else
      setEditedPrice({
        ...editedPrice,
        [valueName]: newValue,
      });
  };

  return (
    <div className={styles.content_wrapper}>
      <form onSubmit={handleSubmit} autoComplete={"off"}>
        <div className={styles.button_row}>
          <div className={styles.button_group}>
            <Button
              id={mode === ButtonMode.Default ? "success" : ""}
              label={t("services")}
              click={() => {
                setMode(ButtonMode.Default);
                resetSubmitButton();
              }}
            />
            <Button
              id={mode === ButtonMode.Add ? "success" : ""}
              label={t("addService")}
              click={() => {
                setMode(ButtonMode.Add);
                clearInputs();
                resetSubmitButton();
              }}
            />
          </div>
          <div className={styles.button_group} />
          <div className={styles.button_group}>
            <Button
              id={mode === ButtonMode.Edit ? "success" : ""}
              label={t("editService")}
              click={() => {
                setMode(ButtonMode.Edit);
                resetSubmitButton();
              }}
            />
            <Button
              id={mode === ButtonMode.Delete ? "success" : ""}
              label={t("removeService")}
              click={() => {
                setMode(ButtonMode.Delete);
                resetSubmitButton();
              }}
            />
          </div>
        </div>
        {mode === ButtonMode.Default && (
          <div className={styles.pricings_list_wrapper}>
            <div className={styles.pricings_header}>Cennik:</div>
            {fetchedData.map((data) => {
              return (
                <div className={styles.pricings_item} key={data.service_id}>
                  <p>
                    {i18next.t(data.service.name, { ns: "services" })} - {data.price.toFixed(2)} zł.
                  </p>
                </div>
              );
            })}
          </div>
        )}
        {mode === ButtonMode.Add && (
          <div style={{ display: "flex", flexDirection: "column", gap: "15px", alignItems: "center" }}>
            <ListInput
              setInputs={(id: string) => setInputs({ ...inputs, service_id: id })}
              labelText={""}
              id="service_name"
              mode={ListType.ServiceAvailableForContract}
              contract_id={inputs.contract_id}
            />
            <SingleInput change={handleChange} text={t("price")} id="price" value={inputs.price} />
            <SubmitButton loading={loading} success={button.success} />
          </div>
        )}
        {mode === ButtonMode.Edit && (
          <>
            <div className={styles.pricings_list_wrapper}>
              <div className={styles.pricings_header}>{t("title")}</div>
              <div className={styles.pricings_items}>
                {fetchedData.map((data) => {
                  const dataname = data.service.name;

                  return (
                    <div
                      className={styles.pricings_item}
                      key={data.service_id}
                      style={{
                        display: "flex",
                        textAlign: "left",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          width: "45%",
                          padding: "10px",
                          height: "36px",
                        }}
                      >
                        {i18next.t(data.service.name, { ns: "services" })}
                      </span>

                      <div style={{ width: "40%" }}>
                        <input
                          id={dataname}
                          value={editedPrice[dataname] ?? ""}
                          placeholder={`${data.price.toFixed(2)}`}
                          type="text"
                          autoComplete="off"
                          onChange={onChangeEdit}
                          style={{
                            padding: "5px",
                            border: "1px solid var(--gray-base)",
                            borderRadius: "4px",
                            height: "36px",
                            width: "80%",
                          }}
                        />

                        <span style={{ height: "36px", padding: "10px" }}>zł.</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "15px", alignItems: "center" }}>
              <SubmitButton loading={loading} success={button.success} />
            </div>
          </>
        )}
        {mode === ButtonMode.Delete && (
          <>
            <div className={styles.pricings_list_wrapper}>
              <div className={styles.pricings_header}>Cennik:</div>
              {fetchedData.map((data, i) => {
                return (
                  <div
                    className={i === toRemove ? styles.pricings_item_marked : styles.pricings_item}
                    key={data.service_id}
                    onClick={() => {
                      setToRemove(toRemove === i ? -1 : i);
                    }}
                  >
                    <p>
                      {i18next.t(data.service.name, { ns: "services" })} - {data.price} zł.
                    </p>
                  </div>
                );
              })}
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "15px", alignItems: "center" }}>
              <SubmitButton loading={loading} success={button.success} remove />
            </div>
          </>
        )}
      </form>
    </div>
  );
}
