import { FormValidationDefinition } from "commons/form/useForm";
import AdminRegisterFormData from "./admin-register-form-data";
import registerValidationService from "../../common/register-validation-service";
import formValidationService from "commons/form/form-validation.service";

const getEmptyFormData = (): AdminRegisterFormData => {
  return {
    name: "",
    surname: "",
    email: "",
    phone: "",
    login: "",
    loginCheck: {
      available: true,
      login: "",
    },
    password: "",
    passwordConfirm: "",
  };
};

const getFormValidationDefinition = (): FormValidationDefinition<AdminRegisterFormData> => {
  return {
    email: (values) => registerValidationService.validateEmail(values.email),
    login: (values) => registerValidationService.validateLogin(values.login, values.loginCheck),
    loginCheck: formValidationService.ok,
    name: (values) => registerValidationService.validateName(values.name),
    password: (values) => formValidationService.validatePassword(values.password, true),
    passwordConfirm: (values) => formValidationService.validatePasswordConfirm(values.password, values.passwordConfirm),
    phone: (values) => registerValidationService.validatePhone(values.phone),
    surname: (values) => registerValidationService.validateSurname(values.surname),
  };
};

const adminRegisterFormFactory = {
  getEmptyFormData,
  getFormValidationDefinition,
};

export default adminRegisterFormFactory;
