import { useContext } from "react";
import AuthContext from "./ContextProvider";

const useAppContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAppContext should be inside a ContextProvider");
  }
  return context;
};

export default useAppContext;
