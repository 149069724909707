import { useTranslation } from "react-i18next";
import styles from "styles/UserListing.module.css";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import api from "api";
import CustomCssTransition from "commons/CustomCssTransition";
import ExpandedRow from "./ExpandedRow/ExpandedRow";
import { State } from "api/entities/enums";
import { DataList, DataProvider, TitleProvider } from "commons/DataManagement";
import format from "date-fns/format";
import { CommissionForAuditorCompany } from "api/entities/commission";
import { useRef, useState } from "react";
import { CommissionFilter } from "api/filters/commission";
import Button from "commons/Button/Button";
import CSVButton from "./CSVButton";
import i18next from "i18next";
import { DatetimeComparisonMode } from "api/filters/filterEnums";
import { startOfMonth, endOfMonth } from "date-fns";

export const CommissionsInactive = () => {
  const { t } = useTranslation(["auditorManagerView", "services"], { keyPrefix: "commissions" });
  const headerTranslation = useTranslation("auditorManagerView", {
    keyPrefix: "commissions.data",
  });
  const [fetchStatus, setFetchStatus] = useState(State.completed);

  const filterRef = useRef({
    dispatcher_company_name: "",
    identifier: Number.NaN,
    commission_no: "",
    address_primary_name: "",
    address_secondary_name: "",
    locomotive: "",
    service_name: "",
    target_date: startOfMonth(new Date()),
    target_date_end: endOfMonth(new Date()),
    target_date_compare: DatetimeComparisonMode.AFTER,
  } as CommissionFilter);

  return (
    <>
      <TitleProvider title={`${t("title_inactive")} | Raily Marketplace`} />
      <div className={styles.content}>
        <div className={styles.content_row}>
          <h1 className={styles.h1}> {t("title_inactive")} </h1>
        </div>
        <DataProvider
          fetchFun={
            fetchStatus === State.completed
              ? api.fetchCommissionsFinishedForAuditorCompany
              : api.fetchCommissionsCanceledForAuditorCompany
          }
          fetchArgs={[sessionStorageHelper.getAuditorCoId()]}
          query={{
            order_by: "identifier" as keyof CommissionForAuditorCompany,
            descending: true,
          }}
        >
          {(gto, d, f, refetch, q) => {
            const columns: { [key: string]: any } = {
              targetDate: {
                label: (e: CommissionForAuditorCompany) => {
                  let date: Date = new Date(e.target_date?.toString() as string);
                  return format(date, "dd.MM.yyyy - HH:mm");
                },
                column: "target_date",
                filter: {
                  key: "target_date",
                  type: "date_range",
                },
              },
              coName: {
                label: (e: CommissionForAuditorCompany) => e.dispatcher.user.company.company_name ?? "---",
                column: "dispatcher_co_name",
              },
              identifier: {
                label: (e: CommissionForAuditorCompany) => e.identifier,
                column: "identifier",
                filter: {
                  key: "identifier",
                  type: "int",
                },
              },
              trainNo: {
                label: (e: CommissionForAuditorCompany) => e.train_no,
                column: "train_no",
                filter: {
                  key: "commission_no",
                  type: "string",
                },
              },
              address: {
                label: (e: CommissionForAuditorCompany) => e.address_primary.name,
                column: "address_primary_name",
                filter: {
                  key: "address_primary_name",
                  type: "string",
                },
              },
              addressSecondary: {
                label: (e: CommissionForAuditorCompany) => e.address_secondary?.name ?? "---",
                column: "address_secondary_name",
                filter: {
                  key: "address_secondary_name",
                  type: "string",
                },
              },
              locomotive: {
                label: (e: CommissionForAuditorCompany) => e.locomotive ?? "---",
                column: "locomotive",
                filter: {
                  key: "locomotive",
                  type: "string",
                },
              },
              service: {
                label: (e: CommissionForAuditorCompany) => i18next.t(e.service.name, { ns: "services" }),
                column: "service_name",
                filter: {
                  key: "service_name",
                  type: "string",
                },
              },
            };

            if (fetchStatus === State.completed && sessionStorageHelper.getAspects().includes("operator")) {
              columns.auditor = {
                label: (e: CommissionForAuditorCompany) => e.auditor ?? "---",
                column: "auditor",
                filter: {
                  key: "auditor",
                  type: "string",
                },
              };
            }

            return (
              <>
                <div className={styles.content_row}>
                  <div className={styles.row_items}>
                    <Button
                      type="button"
                      label={t("button_completed")}
                      className={fetchStatus === State.completed ? styles.button_active : styles.button_inactive}
                      click={() => {
                        if (fetchStatus !== State.completed) {
                          setFetchStatus(State.completed);
                          refetch();
                        }
                      }}
                    />
                    <Button
                      type="button"
                      label={t("button_canceled")}
                      className={fetchStatus === State.canceled ? styles.button_active : styles.button_inactive}
                      click={() => {
                        if (fetchStatus !== State.canceled) {
                          setFetchStatus(State.canceled);
                          refetch();
                        }
                      }}
                    />
                  </div>
                  <div className={styles.row_items}>
                    {fetchStatus === State.completed && (
                      <CSVButton gto={gto} filterRef={filterRef} query={q} label={t("genCSV")} />
                    )}
                    <DataProvider.RefreshButton gto={gto} label={t("refresh")} />
                  </div>
                </div>
                <div className={styles.table_wrapper}>
                  <DataList
                    gto={gto}
                    keyGenerator={(e) => e.id}
                    labelGenerator={columns}
                    defaultOrder={"identifier"}
                    descendingDefaultOrder
                    tableWrapperElement={<table className={styles.table} />}
                    headerElement={
                      <DataList.Header
                        gto={gto}
                        translation={headerTranslation.t}
                        headerElement={<thead className={styles.thead} />}
                        labelElement={<th scope="col" className={styles.th} />}
                        filterElement={<input className={styles.input} />}
                        filterRef={filterRef}
                      />
                    }
                    dataRowElement={<tr className={styles.tr} />}
                    dataLabelElement={<td className={styles.td} />}
                    statusMarkerElement={<div className={styles.status_table} />}
                  >
                    {(d, e, k, reload) => {
                      return (
                        <CustomCssTransition in={e} trMode={true} colSpan={Object.keys(columns).length} key={k}>
                          <ExpandedRow data={d} expand={e} reloadOneRow={reload} />
                        </CustomCssTransition>
                      );
                    }}
                  </DataList>
                </div>
                <div className={styles.row_items}>
                  <DataProvider.Pagination gto={gto} />
                </div>
              </>
            );
          }}
        </DataProvider>
      </div>
    </>
  );
};

export default CommissionsInactive;
