import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, TileLayer, useMapEvent } from "react-leaflet";
import MapNewAddrMessageBox from "./MapNewAddrMessageBox";
import { nominatimURL } from "api/backend";
import L, { Map } from "leaflet";

const icon = L.icon({
  iconUrl: require("assets/pendingMarker.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 40],
});

export default function MapNewAddr(props: {
  newAddr: (lat: number, lon: number, name: string) => void;
  lat?: number;
  lon?: number;
  height?: string;
  title?: boolean;
  autoFit?: boolean;
}) {
  const { t } = useTranslation("common", { keyPrefix: "map" });
  const mapRef = useRef<Map>(null);

  const [marker, setMarker] = useState<{ lat: number; lng: number } | undefined>(undefined);
  const [pendingMarker, setPendingMarker] = useState<{ lat: number; lng: number } | null>(null);
  const [isReverseFetched, setIsReverseFetched] = useState(false);
  const [nominatimName, setNominatimName] = useState<string>("");
  const [isMapReady, setIsMapReady] = useState(false);

  function SetNewAddrMarker() {
    useMapEvent("click", (e) => {
      setPendingMarker({ lat: e.latlng.lat, lng: e.latlng.lng });
      setIsReverseFetched(false);
    });
    return null;
  }

  const refreshZoom = () => {
    mapRef.current?.setView({ lat: marker?.lat!, lng: marker?.lng! }, 10);
  };

  useEffect(() => {
    if (!pendingMarker || isReverseFetched) return;
    const delayDebounceFn = setTimeout(() => {
      const query_params = new URLSearchParams();
      query_params.append("accept-language", "pl");
      query_params.append("lat", String(pendingMarker.lat));
      query_params.append("lon", String(pendingMarker.lng));
      query_params.append("format", "json");
      query_params.append("layer", "address");

      fetch(nominatimURL + "/reverse?" + query_params, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((x) => {
          setNominatimName(x.display_name);
          if (x.lat && x.lon) {
            setPendingMarker({ lat: parseFloat(x.lat), lng: parseFloat(x.lon) });
          }
          setIsReverseFetched(true);
        });
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
  }, [pendingMarker?.lat, pendingMarker?.lng]);

  const handleConfirm = () => {
    if (pendingMarker) {
      props.newAddr(pendingMarker.lat, pendingMarker.lng, nominatimName);
      setMarker(pendingMarker);
      setPendingMarker(null);
      if (mapRef.current) {
        mapRef.current.setView([pendingMarker.lat, pendingMarker.lng], 10);
      }
    }
  };

  useEffect(() => {
    if (!mapRef.current || !isMapReady || !props.autoFit || !marker) {
      return;
    }

    refreshZoom();
    // eslint-disable-next-line
  }, [mapRef.current, isMapReady, props.autoFit, JSON.stringify(marker)]);

  useEffect(() => {
    if (props.lat === undefined || props.lon === undefined) {
      setMarker(undefined);
      if (mapRef.current) {
        mapRef.current.setView([52.06, 19.25], 6);
      }
    } else {
      setMarker({ lat: props.lat, lng: props.lon });
    }
  }, [props.lat, props.lon]);

  return (
    <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
      {props.title === true && <div>{t("title")}</div>}
      <div style={{ border: "1px solid var(--gray-base)", borderRadius: "4px", width: "100%", position: "relative" }}>
        <MapContainer
          style={{ width: "100%", height: props.height ?? "550px" }}
          center={[52.06, 19.25]}
          zoom={6}
          scrollWheelZoom={true}
          ref={mapRef}
          whenReady={() => {
            setIsMapReady(true);
            if (marker) {
              mapRef.current?.setView([marker.lat, marker.lng], 10);
            }
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {marker && <Marker position={[marker.lat, marker.lng]} />}
          {pendingMarker && <Marker position={[pendingMarker.lat, pendingMarker.lng]} icon={icon} />}

          <SetNewAddrMarker />
        </MapContainer>

        {pendingMarker && (
          <div
            style={{
              position: "absolute",
              bottom: 15,
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1000,
            }}
          >
            <MapNewAddrMessageBox text={nominatimName} onConfirm={handleConfirm} />
          </div>
        )}
      </div>
    </div>
  );
}
