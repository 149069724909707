import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";
import ListInput, { ListType } from "commons/Registration/ListInput/ListInput";
import stylesLocal from "./Prices.module.css";
import { PriceWithService } from "api/entities/price";
import api from "api";
import SingleInput from "commons/Registration/SingleInput";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import DataList from "commons_new/DataList/DataList";
import { useLocation } from "react-router-dom";
import Modal from "commons/Modal";
import registerValidationService from "./common/register-validation-service";

interface ContractInputs {
  contract_id: string;
  service_id: string;
  price: number | string;
}

enum ButtonMode {
  Default,
  Add,
  Edit,
  Delete,
}

interface LocalProps {
  contract?: string;
}

export default function Prices(props: LocalProps) {
  const { t } = useTranslation("adminView", { keyPrefix: "register" });
  const location = useLocation();
  const { handleErrors } = useErrorHandling();
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState(ButtonMode.Default);
  const [editedPrice, setEditedPrice] = useState<{ [name: string]: string }>({});
  const [toRemove, setToRemove] = useState(-1);
  const [contractLocked, setContractLocked] = useState(() => {
    return false;
  });
  const stateLoaded = useRef(false);

  const [fetchedData, setFetchedData] = useState<PriceWithService[]>([]);
  const [err, setErr] = useState(false);
  const [key, setKey] = useState(Math.random());
  const [registerAlert, setRegisterAlert] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [removeAlert, setRemoveAlert] = useState(false);

  // Przechowuje info o wybranym kontrakcie
  const [inputs, setInputs] = useState<ContractInputs>({
    contract_id: props.contract ?? "",
    service_id: "",
    price: 0,
  });

  useEffect(() => {
    if (inputs.contract_id !== "") {
      setFetchedData([]);
      if (mode === ButtonMode.Default) {
        api.fetchPricesByContract(inputs.contract_id).then((res) => {
          if (handleErrors(res.error).ok) setFetchedData(res.data);
        });
      } else if (mode === ButtonMode.Edit) {
        setEditedPrice({});
        api.fetchPricesByContractEditable(inputs.contract_id).then((res) => {
          if (handleErrors(res.error).ok) setFetchedData(res.data);
        });
      } else if (mode === ButtonMode.Delete) {
        setToRemove(-1);
        api.fetchPricesByContractRemovable(inputs.contract_id).then((res) => {
          if (handleErrors(res.error).ok) setFetchedData(res.data);
        });
      }
    } else if (!stateLoaded.current && !!location.state && !!location.state.contract_id) {
      stateLoaded.current = true;
      setInputs({ ...inputs, contract_id: location.state.contract_id });
      setContractLocked(true);
    } else {
      setContractLocked(false);
    }
    // eslint-disable-next-line
  }, [mode, inputs.contract_id, handleErrors]);

  const clearInputs = () => {
    setInputs({
      ...inputs,
      service_id: "",
      price: 0,
    });
  };

  const mainRef = useRef(null);
  const [addPriceInputFlag, setAddPriveInputFlag] = useState(false);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value: string = event.currentTarget.value.replace(",", ".");
    const numberValue = Number(value);

    if (value === "") {
      setInputs({
        ...inputs,
        [event.currentTarget.id]: value,
      });
      event.currentTarget.setCustomValidity(registerValidationService.validateServicePrice(numberValue).errorMessage);
      setAddPriveInputFlag(true);
      return;
    }

    const validationPattern = /^\d{0,6}\.?\d{0,2}$/;
    if (validationPattern.test(value) && numberValue <= 1e6) {
      setInputs({
        ...inputs,
        [event.currentTarget.id]: value,
      });
      event.currentTarget.setCustomValidity(registerValidationService.validateServicePrice(numberValue).errorMessage);
      setAddPriveInputFlag(true);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setErr(false);
    if (mode === ButtonMode.Add) {
      try {
        if (inputs.service_id === "" || inputs.price === "") {
          setLoading(false);
          setErr(true);
          return;
        }
        api
          .registerPrice({
            contract_id: inputs.contract_id,
            service_id: inputs.service_id,
            price: Number(inputs.price),
          })
          .then((res) => {
            clearInputs();
            setKey(Math.random());
            if (handleErrors(res.error).ok) {
              setRegisterAlert(true);
            } else {
              setRegisterAlert(false);
            }
          })
          .catch((e) => {
            setRegisterAlert(false);
            console.log(e);
          })
          .finally(() => {
            setLoading(false);
            setMode(ButtonMode.Default);
          });
      } catch (e) {
        setRegisterAlert(false);
        console.log(e);
      } finally {
      }
    } else if (mode === ButtonMode.Edit) {
      if (Object.entries(editedPrice).length === 0) {
        setLoading(false);
      }
      try {
        Object.entries(editedPrice).forEach(([name, price]) => {
          price = parseFloat(price.replace(",", ".")).toFixed(2);
          if (Number.isNaN(price)) {
            setLoading(false);
            return;
          }

          const edited = fetchedData.find((v) => v.service.name === name);
          if (edited && edited.price !== parseFloat(price)) {
            api
              .editPrice(edited.contract_id, edited.service_id, { price: parseFloat(price) })
              .then((res) => {
                handleErrors(res.error);
                clearInputs();
                setEditAlert(true);
              })
              .catch((e) => {
                setEditAlert(false);
                console.log(e);
              })
              .finally(() => {
                setLoading(false);
                setMode(ButtonMode.Default);
              });
          }
        });
      } catch (e) {
        setEditAlert(false);
        console.log(e);
      } finally {
        setEditedPrice({});
      }
    } else if (mode === ButtonMode.Delete) {
      if (toRemove !== -1) {
        try {
          api
            .removePrice(inputs.contract_id, fetchedData[toRemove].service_id)
            .then((e) => {
              handleErrors(e.error);
              setRemoveAlert(true);
            })
            .catch((e) => {
              setRemoveAlert(false);
              console.log(e);
            })
            .finally(() => {
              setLoading(false);
              setMode(ButtonMode.Default);
            });
        } catch (e) {
          setRemoveAlert(false);
          console.log(e);
        } finally {
          setToRemove(-1);
        }
      } else {
        setLoading(false);
      }
    }
  };

  const onChangeEdit = (event: React.FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    const valueName = event.currentTarget.id;

    if (Number.isNaN(newValue)) {
      const filtered = Object.entries(editedPrice).filter(([name]) => name !== valueName);

      setEditedPrice({});
      filtered.forEach(([name, price]) => {
        setEditedPrice({
          ...editedPrice,
          [name]: price,
        });
      });
    } else {
      const validationPattern = /^\d{0,6}\.?\d{0,2}$/;
      const numberValue = Number(newValue);
      if (validationPattern.test(newValue) && numberValue <= 1e6) {
        setEditedPrice({
          ...editedPrice,
          [valueName]: newValue,
        });
      }
    }
  };

  return (
    <>
      {registerAlert && (
        <Modal
          action1={() => {
            setRegisterAlert(false);
          }}
          title={t("modal.registerTitle")}
        />
      )}
      {editAlert && (
        <Modal
          action1={() => {
            setEditAlert(false);
          }}
          title={t("modal.editTitle")}
        />
      )}
      {removeAlert && (
        <Modal
          action1={() => {
            setRemoveAlert(false);
          }}
          title={t("modal.removeTitle")}
        />
      )}
      <form onSubmit={handleSubmit} autoComplete={"off"}>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          {/* Choose from contracts input */}
          <ListInput
            setInputs={(id: string) => {
              setInputs({ ...inputs, contract_id: id });
              setContractLocked(true);
            }}
            labelText={t("pricing.contract")}
            id="contract_name"
            mode={ListType.Contract}
            value={
              !!location.state && !!location.state.comp_1 && !!location.state.comp_2
                ? `${location.state.comp_1} - ${location.state.comp_2} `
                : undefined
            }
          />

          {/* If contract chosen */}
          <CSSTransition
            in={contractLocked}
            nodeRef={mainRef}
            timeout={{
              enter: 300,
              exit: 100,
            }}
            classNames={{
              enter: stylesLocal.pricingsEnter,
              enterActive: stylesLocal.pricingsEnterActive,
              exit: stylesLocal.pricingsExit,
              exitActive: stylesLocal.pricingsExitActive,
            }}
            unmountOnExit
          >
            <div ref={mainRef} className={stylesLocal.contract_pricings}>
              <div className={stylesLocal.choices}>
                <button
                  type="button"
                  className={stylesLocal.button}
                  id={mode === ButtonMode.Default ? stylesLocal.button_active : ""}
                  onClick={() => {
                    setMode(ButtonMode.Default);
                  }}
                >
                  {t("pricing.services")}
                </button>
                <button
                  type="button"
                  className={stylesLocal.button}
                  id={mode === ButtonMode.Add ? stylesLocal.button_active : ""}
                  onClick={() => {
                    setMode(ButtonMode.Add);
                    clearInputs();
                  }}
                >
                  {t("pricing.addService")}
                </button>
                <button
                  type="button"
                  className={stylesLocal.button}
                  id={mode === ButtonMode.Edit ? stylesLocal.button_active : ""}
                  onClick={() => {
                    setMode(ButtonMode.Edit);
                  }}
                >
                  {t("pricing.editService")}
                </button>
                <button
                  type="button"
                  className={stylesLocal.button}
                  id={mode === ButtonMode.Delete ? stylesLocal.button_active : ""}
                  onClick={() => {
                    setMode(ButtonMode.Delete);
                  }}
                >
                  {t("pricing.removeService")}
                </button>
              </div>
              {mode === ButtonMode.Default && (
                <div className={stylesLocal.pricings_list_wrapper}>
                  <div className={stylesLocal.pricings_header}>{t("pricing.")}</div>
                  {fetchedData.map((data) => {
                    return (
                      <div className={stylesLocal.pricings_item} key={data.service_id}>
                        <p>
                          {data.service?.name} - {data.price.toFixed(2)} zł.
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
              {mode === ButtonMode.Add && (
                <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                  <DataList
                    key={key}
                    type="service"
                    setInputs={(id: string) => {
                      setInputs({ ...inputs, service_id: id });
                    }}
                    id="service_name"
                    labelText={t("pricing.serviceName")}
                    mode="s"
                    comp_id={inputs.contract_id}
                  />
                  <SingleInput
                    change={handleChange}
                    text={t("pricing.price")}
                    placeholder={t("pricing.price")}
                    id="price"
                    value={inputs.price}
                    title={t("pricing.onInvalid") ?? "error"}
                    errorMsg={t("pricing.onInvalid") ?? "error"}
                    optional
                    onFocus={() => {
                      setAddPriveInputFlag(true);
                    }}
                    onBlur={(event) => {
                      if (addPriceInputFlag) {
                        const numberValue = Number(event.currentTarget.value);
                        const result = registerValidationService.validateServicePrice(numberValue);
                        event.currentTarget.setCustomValidity(result.errorMessage);
                        event.currentTarget.reportValidity();
                        setAddPriveInputFlag(false);
                      }
                    }}
                  />

                  {err && <div style={{ color: "var(--red-base)", textAlign: "center" }}>{t("pricing.valueErr")}</div>}

                  <SubmitButton loading={loading} success={false} />
                </div>
              )}
              {mode === ButtonMode.Edit && (
                <>
                  <div className={stylesLocal.pricings_list_wrapper}>
                    <div className={stylesLocal.pricings_header}>Cennik:</div>
                    <div className={stylesLocal.pricings_items}>
                      {fetchedData.map((data) => {
                        const dataname = data.service.name;

                        return (
                          <div
                            className={stylesLocal.pricings_item}
                            key={data.service_id}
                            style={{
                              display: "flex",
                              textAlign: "left",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                display: "inline-block",
                                width: "45%",
                                padding: "10px",
                                height: "36px",
                              }}
                            >
                              {dataname}
                            </span>

                            <div style={{ width: "40%" }}>
                              <input
                                id={dataname}
                                value={editedPrice[dataname] ?? ""}
                                placeholder={`${data.price.toFixed(2)}`}
                                type="text"
                                autoComplete="off"
                                onChange={onChangeEdit}
                                style={{
                                  padding: "5px",
                                  border: "1px solid var(--gray-base)",
                                  borderRadius: "4px",
                                  height: "36px",
                                  width: "80%",
                                }}
                                pattern={"[0-9]*[,.]?[0-9]*"}
                                onInvalid={(e) => e.currentTarget.setCustomValidity(t("pricing.onInvalid"))}
                                onInput={(e) => e.currentTarget.setCustomValidity("")}
                              />

                              <span style={{ height: "36px", padding: "10px" }}>zł.</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <SubmitButton loading={loading} success={false} />
                </>
              )}
              {mode === ButtonMode.Delete && (
                <>
                  <div className={stylesLocal.pricings_list_wrapper}>
                    <div className={stylesLocal.pricings_header}>Cennik:</div>
                    {fetchedData.map((data, i) => {
                      return (
                        <div
                          className={i === toRemove ? stylesLocal.pricings_item_marked : stylesLocal.pricings_item}
                          key={data.service_id}
                          onClick={() => {
                            setToRemove(toRemove === i ? -1 : i);
                          }}
                        >
                          <p>
                            {data.service?.name} - {data.price} zł.
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <SubmitButton loading={loading} success={false} remove />
                </>
              )}
            </div>
          </CSSTransition>
        </div>
      </form>
    </>
  );
}
