import React, { Fragment, Key, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";
import { ContractCreate } from "api/entities/bases/contract";
import api from "api";
import { Currency } from "api/entities/enums";
import ListInputDefault from "commons/Registration/ListInput/ListInputDefault";
import DataList from "commons_new/DataList/DataList";
import Modal from "commons/Modal";

export default function Contract() {
  const { t } = useTranslation("adminView", { keyPrefix: "register" });
  const [loading, setLoading] = useState(false);
  const inputKey = useRef<Key>(String(Math.random()));
  const [key, setKey] = useState(Math.random());
  const [key2, setKey2] = useState(Math.random());
  const [alert, setAlert] = useState(false);

  // Pola do tworzenia nowego kontraktu
  const [inputs, setInputs] = useState<ContractCreate>({
    auditor_co_id: "",
    dispatcher_co_id: "",
    currency: Currency.PLN,
  });

  // Czyszczenie pól
  const clearInputs = () => {
    setInputs({
      auditor_co_id: "",
      dispatcher_co_id: "",
      currency: Currency.PLN,
    });
  };

  const [error, setError] = useState<{ show: boolean; msg: string }>({ show: false, msg: "" });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (inputs.currency === "" || inputs.auditor_co_id === "" || inputs.dispatcher_co_id === "") {
      setError({ show: true, msg: "emptyField" });
      setLoading(false);
      return;
    }

    api.registerContract(inputs).then((res) => {
      if (res.ok) {
        resetList.current = !resetList.current;
        clearInputs();
        setLoading(false);
        setKey(Math.random());
        setKey2(Math.random());
        inputKey.current = String(Math.random());
        setAlert(true);
      } else {
        setLoading(false);
        setError({ show: true, msg: "errorExists" });
        setAlert(false);
      }
    });
  };

  const resetList = useRef(false);

  return (
    <>
      {alert && (
        <Modal
          action1={() => {
            setAlert(false);
          }}
          title={t("modal.registerTitle")}
        />
      )}
      <form onSubmit={handleSubmit} autoComplete={"off"}>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Fragment key={inputKey.current}>
            <DataList
              key={key}
              type="company"
              setInputs={(id: string) => {
                setInputs({ ...inputs, auditor_co_id: id });
                if (error.show) setError({ show: false, msg: "" });
              }}
              id="auditor_co_name"
              labelText={t("contract.auditorCo")}
            />

            <DataList
              key={key2}
              type="company"
              setInputs={(id: string) => {
                setInputs({ ...inputs, dispatcher_co_id: id });
                if (error.show) setError({ show: false, msg: "" });
              }}
              id="dispatcher_co_name"
              labelText={t("contract.dispatcherCo")}
            />

            <ListInputDefault
              data={[{ name: Currency.PLN }, { name: Currency.USD }, { name: Currency.EUR }]}
              setInputs={(id: string) => {
                setInputs({ ...inputs, currency: id });
                if (error) setError({ show: false, msg: "" });
              }}
              value={inputs.currency}
              id={"currency"}
              onElementClear={() => {
                setInputs({ ...inputs, currency: "" });
              }}
              labelText={t("contract.currency")}
            />
          </Fragment>

          {error.msg === "errorExists" && (
            <p style={{ color: "#df4f5d", textAlign: "center", padding: "20px" }}> {t("contract.errorExists")}</p>
          )}

          {error.msg === "emptyField" && (
            <p style={{ color: "#df4f5d", textAlign: "center", padding: "20px" }}> {t("contract.emptyField")}</p>
          )}

          <SubmitButton loading={loading} success={false} />
        </div>
      </form>
    </>
  );
}
