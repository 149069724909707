import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function MapNewAddrMessageBox(props: { text: string; onConfirm: () => void }) {
  return (
    <div
      style={{
        width: "350px",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "5px",
        borderRadius: "10px",
        padding: "10px",
        boxShadow: "0px 16px 9px -5px rgba(66, 68, 90, 0.6)",
      }}
    >
      <p style={{ fontSize: "12px", textOverflow: "ellipsis", overflow: "hidden", width: "270px" }} title={props.text}>
        {props.text}
      </p>

      <div
        style={{
          width: "36px",
          height: "36px",
          backgroundColor: "#00b386",
          margin: "0",
          borderRadius: "4px",
        }}
        onClick={props.onConfirm}
      >
        <FontAwesomeIcon
          style={{ cursor: "pointer", width: "100%", height: "100%", color: "#fff", borderRadius: "4px" }}
          icon={faCheck}
        />
      </div>
    </div>
  );
}
