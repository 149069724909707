import { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "api";
import ListInputAddr from "commons/Registration/ListInput/ListInputAddr";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "commons/Modal";
import useForm from "commons/form/useForm";
import companyFormFactory from "./form/compnay-form.factory";
import { AuditorCoWithAddress } from "api/entities/auditorCo";
import companyAddRequestFactory from "./company-add-request.factory";
import companyEditRequestFactory from "./company-edit-request.factory";
import Button from "commons/Button/Button";
import styles from "./Company.module.css";
import CompanyFormData from "./form/company-from-data";

type CompanyEditData = {
  isEdit: boolean;
  id: string;
};

const Company: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("adminView", { keyPrefix: "register" });
  const { handleErrors } = useErrorHandling();
  const [alert, setAlert] = useState(false);
  const [companyEditData, setCompanyEditData] = useState<CompanyEditData>({ isEdit: false, id: "" });

  const form = useForm({
    emptyValues: companyFormFactory.getDefaultFormData(),
    validationDefinition: companyFormFactory.getFormValidationDefinition(),
  });

  const companyNameInputRef = useRef<HTMLInputElement>(null);
  const companyTaxIdInputRef = useRef<HTMLInputElement>(null);
  const companyRegonInputRef = useRef<HTMLInputElement>(null);
  const companyKrsInputRef = useRef<HTMLInputElement>(null);
  const companyInsuranceInputRef = useRef<HTMLInputElement>(null);
  const companyLicenseInputRef = useRef<HTMLInputElement>(null);
  const addressInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!location.state?.companyEditData) return;

    const companyData: AuditorCoWithAddress = location.state.companyEditData;

    form.setValues(companyFormFactory.createFormData(companyData));
    setCompanyEditData({ isEdit: true, id: companyData.id });
    // eslint-disable-next-line
  }, []);

  const onChangeInsurance = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    if (value === "") {
      const result = form.setAndValidate("insurance", "");
      companyInsuranceInputRef.current?.setCustomValidity(result.errorMessage);
      return;
    }

    const validationPattern = /^\d{0,9}?\.?\d{0,2}?$/;
    const numberValue = Number.parseFloat(value);
    if (validationPattern.test(value) && numberValue <= 1e9) {
      const result = form.setAndValidate("insurance", value);
      companyInsuranceInputRef.current?.setCustomValidity(result.errorMessage);
    }
  };

  const onNumberFieldsChange = (
    event: ChangeEvent<HTMLInputElement>,
    formKey: keyof CompanyFormData,
    validationPattern: RegExp = /^\d{0,10}$/,
  ) => {
    const value = event.currentTarget.value;

    if (value === "") {
      const result = form.setAndValidate(formKey, value);
      event.currentTarget.setCustomValidity(result.errorMessage);
      return;
    }

    if (validationPattern.test(value)) {
      const result = form.setAndValidate(formKey, value);
      event.currentTarget.setCustomValidity(result.errorMessage);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!form.validateAll()) {
      companyNameInputRef.current?.setCustomValidity(form.validationResults["companyName"].errorMessage);
      companyTaxIdInputRef.current?.setCustomValidity(form.validationResults["taxId"].errorMessage);
      companyRegonInputRef.current?.setCustomValidity(form.validationResults["regon"].errorMessage);
      companyKrsInputRef.current?.setCustomValidity(form.validationResults["krs"].errorMessage);
      companyInsuranceInputRef.current?.setCustomValidity(form.validationResults["insurance"].errorMessage);
      companyLicenseInputRef.current?.setCustomValidity(form.validationResults["licenceNumber"].errorMessage);

      event.currentTarget.reportValidity();
      return;
    }

    if (companyEditData.isEdit) {
      const request = companyEditRequestFactory.create(form.values);

      api.editAuditorCompany(companyEditData.id, request).then((res) => {
        if (handleErrors(res.error).ok) {
          form.restore();
          setAlert(true);
        } else {
          setAlert(false);
        }
      });
    } else {
      const request = companyAddRequestFactory.create(form.values);

      api.registerCompany(request).then((res) => {
        if (handleErrors(res.error).ok) {
          form.restore();
          setAlert(true);
        } else {
          setAlert(false);
        }
      });
    }
  };

  const companyNameLabel = t("auditorCo.coName");
  const companyTaxIdLabel = t("auditorCo.taxNo");
  const companyRegonLabel = t("auditorCo.regon");
  const companyKrsLabel = t("auditorCo.krs");
  const companyInsuranceLabel = t("auditorCo.insurance");
  const companyLicenseLabel = t("auditorCo.license");

  return (
    <>
      {alert && (
        <Modal
          action1={() => {
            setAlert(false);
            if (companyEditData.isEdit) {
              navigate(location.pathname, { replace: true });
            }
          }}
          title={t("modal.registerTitle")}
        />
      )}
      <form onSubmit={handleSubmit} autoComplete={"off"}>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            <div className={styles.column}>
              <label className={styles.input_label}>{companyNameLabel}</label>
              <input
                ref={companyNameInputRef}
                id="company_name"
                className={styles.input}
                value={form.values.companyName}
                placeholder={companyNameLabel}
                onChange={(event) => {
                  const result = form.setAndValidate("companyName", event.currentTarget.value);
                  event.currentTarget.setCustomValidity(result.errorMessage);
                }}
                onFocus={() => {
                  form.flag("companyName");
                }}
                onBlur={(event) => {
                  if (form.isFlagged("companyName")) {
                    const result = form.validate("companyName");
                    event.currentTarget.setCustomValidity(result.errorMessage);
                    event.currentTarget.reportValidity();
                    form.unflag("companyName");
                  }
                }}
              />
            </div>
            <div className={styles.column}>
              <label className={styles.input_label}>{companyTaxIdLabel}</label>
              <input
                ref={companyTaxIdInputRef}
                id="taxId"
                className={styles.input}
                value={form.values.taxId}
                placeholder={companyTaxIdLabel}
                onChange={(event) => {
                  onNumberFieldsChange(event, "taxId");
                }}
                onFocus={() => {
                  form.flag("taxId");
                }}
                onBlur={(event) => {
                  if (form.isFlagged("taxId")) {
                    const result = form.validate("taxId");
                    event.currentTarget.setCustomValidity(result.errorMessage);
                    event.currentTarget.reportValidity();
                    form.unflag("taxId");
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <label className={styles.input_label}>{companyRegonLabel}</label>
              <input
                ref={companyRegonInputRef}
                id="regon"
                className={styles.input}
                value={form.values.regon}
                placeholder={companyRegonLabel}
                onChange={(event) => {
                  onNumberFieldsChange(event, "regon", /^\d{0,14}$/);
                }}
                onFocus={() => {
                  form.flag("regon");
                }}
                onBlur={(event) => {
                  if (form.isFlagged("regon")) {
                    const result = form.validate("regon");
                    event.currentTarget.setCustomValidity(result.errorMessage);
                    event.currentTarget.reportValidity();
                    form.unflag("regon");
                  }
                }}
              />
            </div>
            <div className={styles.column}>
              <label className={styles.input_label}>{companyKrsLabel}</label>
              <input
                ref={companyKrsInputRef}
                id="krs"
                className={styles.input}
                value={form.values.krs}
                placeholder={companyKrsLabel}
                onChange={(event) => {
                  onNumberFieldsChange(event, "krs");
                }}
                onFocus={() => {
                  form.flag("krs");
                }}
                onBlur={(event) => {
                  if (form.isFlagged("krs")) {
                    const result = form.validate("krs");
                    event.currentTarget.setCustomValidity(result.errorMessage);
                    event.currentTarget.reportValidity();
                    form.unflag("krs");
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <label className={styles.input_label}>{companyInsuranceLabel}</label>
              <input
                ref={companyInsuranceInputRef}
                id="insurance"
                className={styles.input}
                value={form.values.insurance}
                placeholder={companyInsuranceLabel}
                onChange={onChangeInsurance}
                onFocus={() => {
                  form.flag("insurance");
                }}
                onBlur={(event) => {
                  if (form.isFlagged("insurance")) {
                    const result = form.validate("insurance");
                    event.currentTarget.setCustomValidity(result.errorMessage);
                    event.currentTarget.reportValidity();
                    form.unflag("insurance");
                  }
                }}
              />
            </div>
            <div className={styles.column}>
              <label className={styles.input_label}>{companyLicenseLabel}</label>
              <input
                ref={companyLicenseInputRef}
                id="license"
                className={styles.input}
                value={form.values.licenceNumber}
                placeholder={companyLicenseLabel}
                onChange={(event) => {
                  const result = form.setAndValidate("licenceNumber", event.currentTarget.value);
                  event.currentTarget.setCustomValidity(result.errorMessage);
                }}
                onFocus={() => {
                  form.flag("licenceNumber");
                }}
                onBlur={(event) => {
                  if (form.isFlagged("licenceNumber")) {
                    const result = form.validate("licenceNumber");
                    event.currentTarget.setCustomValidity(result.errorMessage);
                    event.currentTarget.reportValidity();
                    form.unflag("licenceNumber");
                  }
                }}
              />
            </div>
          </div>

          <ListInputAddr
            inputRef={addressInputRef}
            setInput={(id: string, name: string) => {
              form.setValue("addressId", id);
              const result = form.setAndValidate("addressName", name);
              addressInputRef.current?.setCustomValidity(result.errorMessage);
            }}
            onFocus={() => {
              form.flag("addressName");
            }}
            onBlur={() => {
              if (form.isFlagged("addressName")) {
                const result = form.validate("addressName");
                addressInputRef.current?.setCustomValidity(result.errorMessage);
                addressInputRef.current?.reportValidity();
                form.unflag("addressName");
              }
            }}
            labelText={t("auditorCo.address")}
            id="addr_name"
            value={form.values.addressName}
          />

          <Button type="submit" label={companyEditData.isEdit ? t("auditorCo.edit") : t("auditorCo.register")} />
        </div>
      </form>
    </>
  );
};

export default Company;
