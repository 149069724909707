import { ReactNode, startTransition } from "react";
import { useNavigate } from "react-router-dom";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import { useTranslation } from "react-i18next";
import styles from "./Header.module.css";
import LanguageMenu from "commons/LanguageMenu";
import api from "api";
import useAppContext from "context/useContext";
import { routesHelper } from "routes/routesHelper";

export default function Header(props: { children?: ReactNode }) {
  const navigate = useNavigate();

  const { t } = useTranslation("common", { keyPrefix: "header" });
  const { setIsUserLoggedIn } = useAppContext();

  const handleLogout = () => {
    startTransition(() => {
      api.logout().then(() => {
        sessionStorageHelper.clearStorage();
        setIsUserLoggedIn(false);
        navigate(routesHelper.sighInPath);
      });
    });
  };

  const loggedInName = sessionStorageHelper.getFullName();

  return (
    <div className={styles.header}>
      <div className={styles.header_content}>
        {props.children}
        <div className={styles.items_wrapper}>
          <div className={styles.loggedInAs}>{`${t("loggedAs")} ${loggedInName}`}</div>

          <LanguageMenu type="center" />

          <button onClick={handleLogout} type="button" className={styles.logout}>
            {t("logout")}
          </button>
        </div>
      </div>
    </div>
  );
}
