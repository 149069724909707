import { AuditorCoEdit } from "api/entities/bases/auditorCo";
import CompanyFormData from "./form/company-from-data";

const create = (formData: CompanyFormData): AuditorCoEdit => {
  return {
    company_name: formData.companyName,
    headquarters_addr_id: formData.addressId,
    insurance_amount: Number(formData.insurance),
    krs_no: formData.krs,
    licence_number: formData.licenceNumber,
    regon: formData.regon,
    tax_id: formData.taxId,
  };
};

const companyEditRequestFactory = { create };

export default companyEditRequestFactory;
