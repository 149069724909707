import { useEffect, useRef, useState } from "react";
import styles from "./NewCommission.module.css";
import SingleInput from "commons/Registration/SingleInput";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";
import { newCommissionService } from "./newCommissionService";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import { useTranslation } from "react-i18next";
import ErrorMsg from "commons/ErrorMsg";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import { DataList, TitleProvider } from "commons/DataManagement";
import ListInput, { ListType } from "commons/Registration/ListInput/ListInput";
import ListInputAddr from "commons/Registration/ListInput/ListInputAddr";
import CustomDatePicker from "commons/Registration/DatePicker/CustomDatePicker";
import Attachments from "commons/NewAttachments";
import MapComponent, { AddressForMap } from "commons/Map/MapComponent";
import { CommissionForDispatcherCompany } from "api/entities/commission";
import api from "api";
import { GenericTypeObject } from "api/backend";
import { CommissionGroupFilter } from "api/filters/commission_group";
import { createDataContext } from "commons/DataManagement/DataProvider";
import format from "date-fns/format";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CommissionGroupWithCommissions } from "api/entities/commission_group";
import moment from "moment";
import { createDataStatusContext } from "commons/DataManagement/DataStatusProvider";
import PopUpConfirmation from "commons/PopUpConfirmation";
import UserMenuInput from "./UserMenuInput";
import Button from "commons/Button/Button";

export const NewCommission = () => {
  const { t } = useTranslation("dispatcherView", { keyPrefix: "newCommission" });
  const { handleErrors } = useErrorHandling();
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState(() => {
    if (location.state !== null) {
      return location.state.data as CommissionForDispatcherCompany;
    }
    return undefined;
  });
  const [addToGroup, setAddToGroup] = useState(() => {
    if (location.state !== null) {
      return location.state.addToGroup as boolean;
    }
    return false;
  });

  const isEdit = useRef(data !== undefined && !addToGroup);
  const [previousCommissionId, setPreviousCommissionId] = useState(data?.id);
  const [groupid, setGroupid] = useState(data?.group_id);

  useEffect(() => {
    setGroupid(data?.group_id);
    setCommission(
      addToGroup
        ? newCommissionService.newCopyFormData(data, sessionStorageHelper.getDispatcherId())
        : newCommissionService.getFormData(data, sessionStorageHelper.getDispatcherId()),
    );
    setTimeout(() => setUpdateBool(!updateBool), 10);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    isEdit.current = data !== undefined && !addToGroup;
  }, [data, addToGroup]);

  const dataContext = createDataContext<
    CommissionForDispatcherCompany[],
    CommissionGroupFilter,
    CommissionForDispatcherCompany
  >();
  const statusContext = createDataStatusContext<CommissionForDispatcherCompany>();

  const GTO = useRef(
    {} as GenericTypeObject<CommissionForDispatcherCompany[], CommissionGroupFilter, CommissionForDispatcherCompany>,
  );
  const [group, setGroup] = useState<CommissionGroupWithCommissions>();
  const [commission, setCommission] = useState(
    addToGroup
      ? newCommissionService.newCopyFormData(data, sessionStorageHelper.getDispatcherId())
      : newCommissionService.getFormData(data, sessionStorageHelper.getDispatcherId()),
  );
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState<{ msg: string; isShown: boolean }>({ msg: "", isShown: false });

  // Used for reseting ListInputAddr component
  const [listBool, setListBool] = useState(false);
  const [serviceListBool, setServiceListBool] = useState(false);
  const [updateBool, setUpdateBool] = useState(false);

  // Stan przycisku submit
  const [button, setButton] = useState({
    success: false,
  });

  useEffect(() => {
    if (groupid !== undefined && groupid !== null) {
      api.fetchCommissionGroup(groupid).then((res) => {
        if (handleErrors(res.error).ok) setGroup(res.data);
      });
    } else {
      setGroup(undefined);
    }
  }, [groupid, handleErrors]);

  const getMarkers = (): AddressForMap[] => {
    var array: AddressForMap[] = [];

    if (commission.addr_primary_latitude !== 0.0 && commission.addr_primary_longitude !== 0.0)
      array.push({
        x: commission.addr_primary_latitude,
        y: commission.addr_primary_longitude,
        name: commission.addr_primary_name,
      });

    if (commission.addr_secondary_latitude !== 0.0 && commission.addr_secondary_longitude !== 0.0)
      array.push({
        x: commission.addr_secondary_latitude,
        y: commission.addr_secondary_longitude,
        name: commission.addr_secondary_name,
      });

    return array;
  };

  const resetSubmitButton = () => {
    if (button.success) setButton({ success: false });
  };

  const clearInputs = () => {
    setCommission(newCommissionService.getDefaultFormData(sessionStorageHelper.getDispatcherId()));
    setAddToGroup(false);
    setData(undefined);
    setPreviousCommissionId(undefined);
    setFiles([]);
    setServiceListBool(!serviceListBool);
    setListBool(!listBool);
    setLoading(false);
    setErrMsg({ msg: "", isShown: false });
  };

  const [alert, setAlert] = useState(false);
  const [prevGI, setPrevGI] = useState(false);
  const prevId = useRef("");
  const prevGId = useRef("");

  const handleEdit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    if (data !== undefined)
      newCommissionService
        .handleEdit(data, commission, files)
        .then((e) => {
          const er = handleErrors(e.res.error, false);
          if (er.ok) {
            if (!e.errs.every((e) => handleErrors(e, false).ok)) {
              setErrMsg({ msg: t("attachmentError"), isShown: true });
              setButton({ success: true });
            } else {
              navigate("/dispatcher");
            }
          } else {
            setButton({ success: false });
            setErrMsg({ msg: er.err_str, isShown: true });
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setButton({ success: false });
        });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (
      commission.service_id === "-1" ||
      commission.service_id === "" ||
      commission.service_id === null ||
      commission.service_id === undefined
    ) {
      console.log("service");
      setErrMsg({ msg: t("noService"), isShown: true });
      setLoading(false);
      return;
    }
    newCommissionService
      .handleSubmit(commission, files)
      .then((e) => {
        const er = handleErrors(e.res.error, false);
        if (er.ok) {
          if (!e.errs.every((e) => handleErrors(e, false).ok)) {
            setErrMsg({ msg: t("attachmentError"), isShown: true });
            setButton({ success: true });
          } else {
            if (previousCommissionId !== undefined) {
              api.groupCommissions(e.res.data.id, previousCommissionId).then((res) => {
                /* if (window.confirm(t('newCommission') ?? undefined)) {
                                    if (window.confirm(t('nextCommission') ?? undefined)) {
                                        setPreviousCommissionId(e.res.data.id);
                                        setCommission(newCommissionService.newCopyFormData(commission));
                                        setUpdateBool(!updateBool);
                                        setServiceListBool(!serviceListBool);
                                        setGroupid(res.data.id);
                                    } else {
                                        setPreviousCommissionId(undefined);
                                        setData(undefined);
                                        setGroupid(undefined);
                                        clearInputs();
                                    }
                                } else {
                                    navigate('/dispatcher');
                                } */
                prevId.current = e.res.data.id;
                prevGId.current = res.data.id;
                setPrevGI(true);
                setAlert(true);
              });
            } else {
              prevId.current = e.res.data.id;
              setAlert(true);
            }
          }
        } else {
          setButton({ success: false });
          setErrMsg({ msg: er.err_str, isShown: true });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setButton({ success: false });
      });
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    resetSubmitButton();
    setErrMsg({ ...errMsg, isShown: false });
    setCommission({
      ...commission,
      [event.currentTarget.id]: event.currentTarget.value,
    });
  };

  return (
    <>
      {alert && (
        <PopUpConfirmation
          action1={() => {
            navigate("/dispatcher");
            setAlert(false);
          }}
          action2={() => {
            setPreviousCommissionId(prevId.current);
            setCommission(newCommissionService.newCopyFormData(commission));
            setUpdateBool(!updateBool);
            setServiceListBool(!serviceListBool);
            if (prevGI) {
              setGroupid(prevGId.current);
              setPrevGI(false);
            }
            setAlert(false);
          }}
          action3={() => {
            setPreviousCommissionId(undefined);
            setData(undefined);
            setGroupid(undefined);
            clearInputs();
            setAlert(false);
          }}
        />
      )}
      <form onSubmit={isEdit.current ? handleEdit : handleSubmit}>
        <TitleProvider title={`${t("title")} | Raily Marketplace`} />
        <div className={styles.content}>
          <div className={styles.row}>
            <h1 className={styles.h1}>{t("title")}</h1>
            {commission.service_id === "-1" && commission.service_name === "-1" && (
              <h2
                style={{ textAlign: "center", width: "100%", padding: "10px", color: "#ff4444" }}
                className={styles.h2}
              >
                {t("noServices")}{" "}
              </h2>
            )}
          </div>
          <div className={styles.inputs_wrapper}>
            <div className={styles.inputs} style={{ marginBottom: "50px" }}>
              <div className={styles.inputs_left}>
                <SingleInput
                  change={handleChange}
                  id="train_no"
                  text={t("trainNo")}
                  value={commission.train_no}
                  placeholder={t("trainPlaceholder")}
                  pattern={".{1,20}"}
                  errorMsg={t("pattern") ?? "error"}
                />

                <SingleInput
                  change={handleChange}
                  id="locomotive"
                  text={t("locomotive")}
                  value={commission.locomotive}
                  placeholder={t("locomotivePlaceholder")}
                  pattern={".{1,20}"}
                  errorMsg={t("pattern") ?? "error"}
                />

                <ListInput
                  setInputs={(id, name) => setCommission({ ...commission, service_id: id, service_name: name })}
                  labelText={t("serviceName")}
                  id="service_name"
                  mode={ListType.ServiceAvailableInCommission}
                  auditor_co_id={sessionStorageHelper.getDispatcherCoId()}
                  resetElement={serviceListBool}
                  updateElement={updateBool}
                  value={commission.service_name}
                />

                <ListInputAddr
                  setInput={(id: string, name: string, lati: number, long: number) => {
                    resetSubmitButton();
                    setCommission({
                      ...commission,
                      addr_primary_id: id,
                      addr_primary_name: name,
                      addr_primary_latitude: lati,
                      addr_primary_longitude: long,
                    });
                  }}
                  labelText={t("addressName")}
                  id={"address"}
                  resetElement={listBool}
                  updateElement={updateBool}
                  value={commission.addr_primary_name}
                />

                <ListInputAddr
                  setInput={(id: string, name: string, lati: number, long: number) => {
                    resetSubmitButton();
                    setCommission({
                      ...commission,
                      addr_secondary_id: id,
                      addr_secondary_name: name,
                      addr_secondary_latitude: lati,
                      addr_secondary_longitude: long,
                    });
                  }}
                  labelText={t("addressSecondaryName")}
                  id={"addressSecondary"}
                  resetElement={listBool}
                  updateElement={updateBool}
                  required={false}
                  value={commission.addr_secondary_name}
                />

                <UserMenuInput
                  value1={commission.contact_person}
                  value2={commission.contact_phone}
                  setInputs={(value1: string, value2: string) => {
                    setCommission({ ...commission, contact_person: value1, contact_phone: value2 });
                  }}
                  placeholder1={t("contactPerson")}
                  placeholder2={t("contactPhone")}
                />

                <CustomDatePicker
                  value={commission.target_date}
                  onChange={(d: Date) => {
                    setCommission({ ...commission, target_date: d });
                  }}
                  scrollToTop={true}
                />

                <div style={{ padding: "0 10px 10px 10px" }}>
                  <div className={styles.label}>{t("comment")}</div>
                  <div style={{ width: "100%" }}>
                    <textarea
                      id="comment"
                      className={styles.input}
                      placeholder={t("commentPlaceholder") ?? ""}
                      onChange={(event: React.FormEvent<HTMLTextAreaElement>) => {
                        setCommission({ ...commission, comment: event.currentTarget.value });
                      }}
                      value={commission.comment}
                      maxLength={255}
                      style={{ width: "100%", maxWidth: "470px", minWidth: "470px" }}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.inputs_left}>
                <MapComponent
                  markers={getMarkers()}
                  title={true}
                  padding={"0 10px 0px 10px"}
                  height={"100%"}
                  autoFit
                  autoFitOnUpdate
                />
                <Attachments
                  files={files}
                  addFile={(file) => {
                    setFiles([...files, file]);
                  }}
                  removeFile={(file) => {
                    setFiles(files.filter((f) => f.name !== file.name));
                  }}
                />
              </div>
            </div>

            <ErrorMsg msg={errMsg.msg} isShown={errMsg.isShown} />

            <div style={{ display: "flex", width: "620px" }}>
              <SubmitButton
                label={(isEdit.current ? t("edit") : t("register")) ?? undefined}
                loading={loading}
                success={button.success}
              />
              <div>
                <Link
                  to={"..."}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  <Button
                    width="300px"
                    click={() => {}}
                    id="delete"
                    label={location.state !== null ? t("cancelEdit") : t("cancel")}
                  />
                </Link>
              </div>
            </div>
          </div>
          {!!groupid && (
            <div className={styles.table_wrapper}>
              <div className={styles.row}>
                <h1 className={styles.h1}>{t("groupedCommissions")}</h1>
              </div>
              <dataContext.Provider
                value={{
                  changeQuery: () => {},
                  loading: false,
                  refetch: () => {},
                  data: group?.commissions.sort((a, b) => moment(a.target_date).diff(b.target_date)),
                  page: 1,
                  pages: 1,
                }}
              >
                <statusContext.Provider
                  value={{
                    active: {
                      edit: true,
                      prev: true,
                    },
                    setActive: () => {},
                    statuses: {
                      edit: {
                        color: "var(--blue-base)",
                        condition: (e) => isEdit.current && data?.id === e.id,
                      },
                      prev: {
                        color: "var(--green-base)",
                        condition: (e) => isEdit.current && previousCommissionId === e.id,
                      },
                    },
                    statusKeys: ["edit", "prev"],
                  }}
                >
                  <DataList
                    gto={GTO.current}
                    keyGenerator={(e) => e.id}
                    labelGenerator={{
                      targetDate: {
                        label: (e) => {
                          let date: Date = new Date(e.target_date?.toString() as string);
                          return format(date, "dd.MM.yyyy - HH:mm");
                        },
                      },
                      identifier: { label: (e) => e.identifier },
                      trainNo: { label: (e) => e.train_no },
                      address: { label: (e) => e.address_primary.name },
                      addressSecondary: { label: (e) => e.address_secondary?.name ?? "---" },
                      locomotive: { label: (e) => e.locomotive ?? "---" },
                      service: { label: (e) => e.service.name },
                    }}
                    defaultOrder={"target_date"}
                    descendingDefaultOrder
                    headerElement={
                      <DataList.Header
                        gto={GTO.current}
                        translation={t}
                        headerElement={<thead className={styles.thead_no_shadow} />}
                        labelElement={<th scope="col" className={styles.th} />}
                      />
                    }
                    tableWrapperElement={<table className={styles.table} />}
                    dataRowElement={<tr className={styles.tr} />}
                    dataLabelElement={<td className={styles.td} />}
                    statusMarkerElement={<div className={styles.status} />}
                  />
                </statusContext.Provider>
              </dataContext.Provider>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default NewCommission;
