import OperatorEditFormData from "./form/operator-edit-form-data";
import { AuditorManagerEdit } from "api/entities/bases/auditorManager";

const create = (formData: OperatorEditFormData, originalLogin: string | undefined): AuditorManagerEdit => {
  let phonePrefix: string | undefined;
  let phone: string | undefined;
  const formPhone = formData.phone.replace(/\s/g, "");

  if (formPhone.startsWith("+")) {
    phonePrefix = formPhone.substring(0, 3);
    phone = formPhone.substring(3);
  } else {
    phonePrefix = "+48";
    phone = formPhone;
  }

  const login = formData.login === originalLogin ? undefined : formData.login.trim();

  return {
    email: formData.email.trim(),
    login: login,
    name: formData.name.trim(),
    surname: formData.surname.trim(),
    password: !!formData.password ? formData.password : undefined,
    phone: phone,
    phone_prefix: phonePrefix,
    company_id: !!formData.companyId ? formData.companyId : undefined,
  };
};

const operatorEditRequestFactory = {
  create,
};

export default operatorEditRequestFactory;
