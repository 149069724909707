import { useEffect, useRef, useState } from "react";
import SingleInputWithClear from "../SingleInputWithClear";
import { nominatimURL } from "api/backend";
import SearchAddressResponseItem from "../ListInput/search-address-response";
import { CSSTransition } from "react-transition-group";
import styles from "./registerNewNominatimAddr.module.css";
import Spinner from "commons/Spinner/Spinner";
import { useTranslation } from "react-i18next";
import MapNewAddr from "commons/Map/MapNewAddr";

export default function RegisterNewNominatimAddr(props: {
  value: { name: string; lat: number | undefined; lng: number | undefined };
  htmlId: string;
  setValue: (value: string, lat: number | undefined, lng: number | undefined) => void;

  placeholder?: string;
  label?: string; // specifies label above component
}) {
  const { t } = useTranslation("common", { keyPrefix: "listInputAddr" });

  // False - no address is selected/locked in
  // True  - an address is currently selected / locked in
  const [selectedState, setSelectedState] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  // Nominatim interface
  const [addrSearchRes, setAddrSearchRes] = useState<SearchAddressResponseItem[]>([]);

  // For Csstransition
  const listRef = useRef(null);

  useEffect(() => {
    if (props.value.name.length === 0) {
      setSelectedState(false);
    } else if (!isFetched) {
      const delayDebounceFn = setTimeout(() => {
        const query_params = new URLSearchParams();

        query_params.append("addressdetails", "1");
        query_params.append("accept-language", "pl");
        query_params.append("q", String(props.value.name));

        fetch(nominatimURL + "/search?" + query_params, {
          method: "GET",
        })
          .then((x) => x.json())
          .then((x) => {
            setAddrSearchRes(x);
            setIsFetched(true);
          });
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [isFetched, props.value.name]);

  return (
    <>
      <div>
        <SingleInputWithClear
          value={props.value.name}
          placeholder={props.placeholder ?? ""}
          htmlId="address"
          label={props.label ?? ""}
          setValue={(value: string) => {
            props.setValue(value, props.value.lat, props.value.lng);
            setIsFetched(false);
          }}
          setOnClear={() => {
            setSelectedState(false);
            setAddrSearchRes([]);
            setIsFetched(false);
            props.setValue("", undefined, undefined);
          }}
        />

        <CSSTransition
          in={!selectedState && props.value.name.length > 0}
          nodeRef={listRef}
          timeout={{
            enter: 300,
            exit: 300,
          }}
          classNames={{
            enter: styles.enterAddr,
            enterActive: styles.enterActiveAddr,
            exit: styles.exitAddr,
            exitActive: styles.exitActiveAddr,
          }}
          mountOnEnter
          unmountOnExit
          appear
        >
          <div ref={listRef}>
            <div className={styles.list_wrapper}>
              <div className={styles.list}>
                <Spinner isFetched={isFetched} size={"50px"} padding={"20px"}>
                  {addrSearchRes.length === 0 ? (
                    <div className={styles.list_item} key={"error: no entries"}>
                      {t("noEntries")}
                    </div>
                  ) : (
                    addrSearchRes.map((data, key) => {
                      return (
                        <div
                          onClick={() => {
                            props.setValue(data.display_name, Number.parseFloat(data.lat), Number.parseFloat(data.lon));
                            setSelectedState(true);
                          }}
                          className={styles.list_item}
                          key={`${data.display_name + data.lat + key}`}
                        >
                          {data.display_name}
                        </div>
                      );
                    })
                  )}
                </Spinner>
              </div>
            </div>
          </div>
        </CSSTransition>
        <div className={styles.map_wrapper}>
          <MapNewAddr
            lat={props.value.lat}
            lon={props.value.lng}
            height="450px"
            newAddr={(x: number, y: number, name: string) => {
              props.setValue(name, x, y);
              setSelectedState(true);
            }}
            autoFit
          />
        </div>
      </div>
    </>
  );
}
