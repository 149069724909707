import { useTranslation } from "react-i18next";
import styles from "styles/UserListing.module.css";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import api from "api";
import { State } from "api/entities/enums";
import { DataList, DataProvider, DataStatusProvider, TitleProvider } from "commons/DataManagement";
import format from "date-fns/format";
import { CommissionForAuditorCompany, CommissionGroup } from "api/entities/commission";
import { useRef } from "react";
import { CommissionFilter } from "api/filters/commission";
import DataGrouper from "commons/DataManagement/DataGrouper";
import { GenericTypeObject } from "api/backend";
import CommissionGroupComponent from "modules/DispatcherView/Commissions/CommissionGroupComponent";
import CustomCssTransition from "commons/CustomCssTransition";
import ExpandedRow from "./ExpandedRow/ExpandedRow";
import i18next from "i18next";

export const Commissions = () => {
  const { t } = useTranslation(["auditorManagerView", "services"], { keyPrefix: "commissions" });
  const statusTranslation = useTranslation("auditorManagerView", {
    keyPrefix: "commissions.statuses",
  });
  const headerTranslation = useTranslation("auditorManagerView", {
    keyPrefix: "commissions.data",
  });

  const filterRef = useRef({
    dispatcher_company_name: "",
    identifier: Number.NaN,
    commission_no: "",
    address_primary_name: "",
    address_secondary_name: "",
    locomotive: "",
    service_name: "",
  } as CommissionFilter);

  const columns: { [key: string]: any } = {
    targetDate: {
      label: (e: CommissionForAuditorCompany) => {
        const date: Date = new Date(e.target_date?.toString() as string);
        return format(date, "dd.MM.yyyy - HH:mm");
      },
      column: "target_date",
      filter: {
        key: "target_date",
        type: "date_range",
      },
    },
    coName: {
      label: (e: CommissionForAuditorCompany) => e.dispatcher.user.company.company_name ?? "---",
      column: "dispatcher_co_name",
    },
    identifier: {
      label: (e: CommissionForAuditorCompany) => e.identifier,
      column: "identifier",
      filter: {
        key: "identifier",
        type: "int",
      },
    },
    trainNo: {
      label: (e: CommissionForAuditorCompany) => e.train_no,
      column: "train_no",
      filter: {
        key: "commission_no",
        type: "string",
      },
    },
    address: {
      label: (e: CommissionForAuditorCompany) => e.address_primary.name,
      column: "address_primary_name",
      filter: {
        key: "address_primary_name",
        type: "string",
      },
    },
    addressSecondary: {
      label: (e: CommissionForAuditorCompany) => e.address_secondary?.name ?? "---",
      column: "address_secondary_name",
      filter: {
        key: "address_secondary_name",
        type: "string",
      },
    },
    locomotive: {
      label: (e: CommissionForAuditorCompany) => e.locomotive ?? "---",
      column: "locomotive",
      filter: {
        key: "locomotive",
        type: "string",
      },
    },
    service: {
      label: (e: CommissionForAuditorCompany) => i18next.t(e.service.name, { ns: "services" }),
      column: "service_name",
      filter: {
        key: "service_name",
        type: "string",
      },
    },
  };

  if (sessionStorageHelper.getAspects().includes("operator")) {
    columns.auditor = {
      label: (e: CommissionForAuditorCompany) => e.auditor ?? "---",
      column: "auditor",
      filter: {
        key: "auditor",
        type: "string",
      },
    };
  }

  return (
    <>
      <TitleProvider title={`${t("title")} | Raily Marketplace`} />
      <div className={styles.content}>
        <div className={styles.content_row}>
          <h1 className={styles.h1}> {t("title")} </h1>
        </div>
        <DataProvider
          fetchFun={api.fetchCommissionsForAuditorCompany}
          fetchArgs={[sessionStorageHelper.getAuditorCoId()]}
          query={{
            order_by: "identifier" as keyof CommissionForAuditorCompany,
            descending: true,
          }}
        >
          {(gto) => (
            <DataStatusProvider
              gto={gto}
              statuses={{
                commission_edited: {
                  color: "var(--status-edited)",
                  condition: (e) => e.frontend_status_auditor === State.commission_edited,
                },
                new: {
                  color: "var(--status-new)",
                  condition: (e) => e.frontend_status_auditor === State.new,
                },
                pending: {
                  color: "var(--status-pending)",
                  condition: (e) => e.frontend_status_auditor === State.pending,
                },
                active: {
                  color: "var(--status-accepted)",
                  condition: (e) => e.frontend_status_auditor === State.active,
                },
                selected_offer_edited: {
                  color: "black",
                  condition: (e) => e.frontend_status_auditor === State.selected_offer_edited,
                },
              }}
            >
              {() => (
                <>
                  <div className={styles.content_row}>
                    <div className={styles.row_items}>
                      <div className={styles.statuses}>
                        <DataStatusProvider.Label
                          gto={gto}
                          st={statusTranslation.t}
                          statusWrapperElement={<div className={styles.status_wrapper} />}
                          statusElement={<div className={styles.status_header} />}
                        />
                      </div>
                    </div>
                    <div className={styles.row_items}>
                      <DataProvider.RefreshButton gto={gto} label={t("refresh")} />
                    </div>
                  </div>
                  <div className={styles.table_wrapper}>
                    <DataGrouper
                      gto={
                        gto as GenericTypeObject<
                          CommissionForAuditorCompany[],
                          CommissionFilter,
                          CommissionForAuditorCompany
                        >
                      }
                      group_type={
                        {
                          data: [],
                          group_id: "",
                        } as CommissionGroup<CommissionForAuditorCompany>
                      }
                      group_func={(e) => {
                        return {
                          grp_id: e.group_id ?? e.id,
                          ungrouped: e.group_id === undefined || e.group_id === null,
                        };
                      }}
                    >
                      {(groups) => (
                        <DataList
                          gto={
                            gto as GenericTypeObject<
                              CommissionForAuditorCompany[],
                              CommissionFilter,
                              CommissionForAuditorCompany
                            >
                          }
                          keyGenerator={(e) => e.id}
                          labelGenerator={columns}
                          defaultOrder={"identifier"}
                          descendingDefaultOrder
                          tableWrapperElement={<table className={styles.table} />}
                          statusMarkerElement={<div />}
                          customBody
                        >
                          {(d, e, k, r, dl, lg, ds) => {
                            return (
                              <>
                                <DataList.Header
                                  gto={gto}
                                  translation={headerTranslation.t}
                                  headerElement={<thead className={styles.thead_no_shadow} />}
                                  labelElement={<th scope="col" className={styles.th} />}
                                  filterElement={<input className={styles.input} />}
                                  filterRef={filterRef}
                                />
                                {groups.map((g) => {
                                  return (
                                    <CommissionGroupComponent<
                                      CommissionForAuditorCompany[],
                                      CommissionFilter,
                                      CommissionForAuditorCompany
                                    >
                                      key={`group_${g.group.group_id === "" ? g.group.data[0].id : g.group.group_id}`}
                                      data={g}
                                      t={t}
                                    >
                                      {(g, d, e, r) => (
                                        <CustomCssTransition in={e} trMode={true} colSpan={12} key={k}>
                                          <ExpandedRow data={d} expand={e} reloadOneRow={r} />
                                        </CustomCssTransition>
                                      )}
                                    </CommissionGroupComponent>
                                  );
                                })}
                              </>
                            );
                          }}
                        </DataList>
                      )}
                    </DataGrouper>
                  </div>
                  <div className={styles.row_items}>
                    <DataProvider.Pagination gto={gto} />
                  </div>
                </>
              )}
            </DataStatusProvider>
          )}
        </DataProvider>
      </div>
    </>
  );
};

export default Commissions;
