import { ReactNode } from "react";
import Prices from "./forms/Prices";
import Address from "./forms/Address";
import Auditor from "./forms/auditor/Auditor";
import Dispatcher from "./forms/dispatcher/Dispatcher";
import Operator from "./forms/operator/Operator";
import Admin from "./forms/admin/Admin";
import Company from "./forms/company/Company";
import Service from "./forms/service/Service";
import Contract from "./forms/Contract";

export enum Mode {
  Auditor = "auditor",
  Dispatcher = "dispatcher",
  AuditorMngr = "manager",
  Company = "auditorCo",
  Address = "address",
  Admin = "admin",
  Contract = "contract",
  Service = "service",
  Pricing = "pricing",
  Commission = "commission",
}

type RegisterForm = {
  id: string;
  mode: Mode;
  component: ReactNode;
};

const forms: RegisterForm[] = [
  {
    id: "1",
    mode: Mode.Auditor,
    component: <Auditor />,
  },
  {
    id: "2",
    mode: Mode.AuditorMngr,
    component: <Operator />,
  },
  {
    id: "3",
    mode: Mode.Dispatcher,
    component: <Dispatcher />,
  },
  {
    id: "4",
    mode: Mode.Company,
    component: <Company />,
  },
  {
    id: "6",
    mode: Mode.Address,
    component: <Address />,
  },
  {
    id: "7",
    mode: Mode.Admin,
    component: <Admin />,
  },
  {
    id: "8",
    mode: Mode.Contract,
    component: <Contract />,
  },
  {
    id: "9",
    mode: Mode.Service,
    component: <Service />,
  },
  {
    id: "10",
    mode: Mode.Pricing,
    component: <Prices />,
  },
];

const getForms = (): RegisterForm[] => {
  return forms;
};

const getForm = (id: string) => {
  return forms.find((obj) => {
    return obj.id === id;
  });
};

const registerHelper = {
  getForms,
  getForm,
};

export { registerHelper };
