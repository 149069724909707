import { ServiceCreate } from "api/entities/bases/service";
import ServiceFormData from "./form/service-form-data";
import { ServiceCategory } from "api/entities/enums";

const create = (formData: ServiceFormData): ServiceCreate => {
  return {
    category: ServiceCategory.auditor,
    name: formData.serviceName,
  };
};

const serviceAddRequestFactory = {
  create,
};

export default serviceAddRequestFactory;
