import { useTranslation } from "react-i18next";
import style from "./Modal.module.css";

interface localProps {
  action1: () => void;
  title: string;
}

export default function Modal(props: localProps) {
  const { t } = useTranslation("modal");
  return (
    <>
      <div className={style.container}>
        <div className={style.buttons_container}>
          <div style={{ color: "#00b386", borderBottom: "2px solid #002C7D" }} className={style.title}>
            <h3>{props.title}</h3>
          </div>
          <div className={style.button} onClick={props.action1}>
            {t("closeButton")}
          </div>
        </div>
      </div>
    </>
  );
}
