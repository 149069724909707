import api, { useLoginDebounce } from "api";
import useForm from "commons/form/useForm";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import operatorEditFormFactory from "./form/operator-edit-form.factory";
import operatorEditRequestFactory from "./operator-edit-request.factory";
import DataList from "commons_new/DataList/DataList";
import Button from "commons/Button/Button";
import styles from "./OperatorEdit.module.css";
import Modal from "commons/Modal";

const OperatorEditComponent: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { handleErrors } = useErrorHandling();
  const { t } = useTranslation("adminView", { keyPrefix: "register.manager" });
  const [originalLogin, setOriginalLogin] = useState<string>();
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    document.title = `${t("editHeader")} | Raily Marketplace`;
  }, [t]);

  const form = useForm({
    emptyValues: operatorEditFormFactory.getEmptyFormData(),
    validationDefinition: operatorEditFormFactory.getFormValidationDefinition(),
  });

  const nameInputRef = useRef<HTMLInputElement>(null);
  const surnameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const loginInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const passwordConfirmInputRef = useRef<HTMLInputElement>(null);

  const loginCheck = useLoginDebounce(form.values.login);

  useEffect(() => {
    form.setValue("loginCheck", loginCheck);

    if (loginInputRef.current) {
      const loginValidation = form.validate("login");
      loginInputRef.current?.setCustomValidity(loginValidation.errorMessage);
    }

    // eslint-disable-next-line
  }, [loginCheck]);

  useEffect(() => {
    if (!location.state.id) return;

    api.fetchAuditorManagerWithAuditorCompanyAndUser(location.state.id).then((response) => {
      if (handleErrors(response.error).ok) {
        form.setValues(operatorEditFormFactory.createFormData(response.data));
        setOriginalLogin(response.data.user.login);
      }
    });
    // eslint-disable-next-line
  }, []);

  const onPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    if (value === "") {
      const result = form.setAndValidate("phone", event.currentTarget.value);
      event.currentTarget.setCustomValidity(result.errorMessage);
      return;
    }

    const validationPattern = /^\+?[0-9]{0,16}$/;
    if (validationPattern.test(value)) {
      const result = form.setAndValidate("phone", event.currentTarget.value);
      event.currentTarget.setCustomValidity(result.errorMessage);
    }
  };

  const handleEdit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!location.state.id) return;

    if (!form.validateAll()) {
      nameInputRef.current?.setCustomValidity(form.validationResults["name"].errorMessage);
      surnameInputRef.current?.setCustomValidity(form.validationResults["surname"].errorMessage);
      emailInputRef.current?.setCustomValidity(form.validationResults["email"].errorMessage);
      phoneInputRef.current?.setCustomValidity(form.validationResults["phone"].errorMessage);
      loginInputRef.current?.setCustomValidity(form.validationResults["login"].errorMessage);
      passwordInputRef.current?.setCustomValidity(form.validationResults["password"].errorMessage);
      passwordConfirmInputRef.current?.setCustomValidity(form.validationResults["passwordConfirm"].errorMessage);

      event.currentTarget.reportValidity();
      return;
    }

    const request = operatorEditRequestFactory.create(form.values, originalLogin);

    api.editAuditorManager(location.state.id, request).then((response) => {
      if (handleErrors(response.error).ok) {
        setAlert(true);
      }
    });
  };

  const nameLabel = t("name");
  const surnameLabel = t("surname");
  const emailLabel = t("email");
  const phoneLabel = t("phone");
  const loginLabel = t("login");
  const passwordLabel = t("password");
  const passwordConfirmLabel = t("password_confirm");

  return (
    <div className={styles.content}>
      {alert && <Modal action1={() => navigate(-1)} title={t("modalSuccess")} />}
      <div className={styles.form_wrapper}>
        <div className={styles.form}>
          <div className={styles.form_header}>{t(`editHeader`)}</div>
          <form onSubmit={handleEdit} autoComplete="off">
            <div className={styles.wrapper}>
              <div className={styles.row}>
                <div className={styles.column}>
                  <label className={styles.input_label}>{nameLabel}</label>
                  <input
                    ref={nameInputRef}
                    id="name"
                    className={styles.input}
                    value={form.values.name}
                    placeholder={nameLabel}
                    onChange={(event) => {
                      const result = form.setAndValidate("name", event.currentTarget.value);
                      event.currentTarget.setCustomValidity(result.errorMessage);
                    }}
                    onFocus={() => {
                      form.flag("name");
                    }}
                    onBlur={(event) => {
                      if (form.isFlagged("name")) {
                        const result = form.validate("name");
                        event.currentTarget.setCustomValidity(result.errorMessage);
                        event.currentTarget.reportValidity();
                        form.unflag("name");
                      }
                    }}
                  />
                </div>
                <div className={styles.column}>
                  <label className={styles.input_label}>{surnameLabel}</label>
                  <input
                    ref={surnameInputRef}
                    id="surname"
                    className={styles.input}
                    value={form.values.surname}
                    placeholder={surnameLabel}
                    onChange={(event) => {
                      const result = form.setAndValidate("surname", event.currentTarget.value);
                      event.currentTarget.setCustomValidity(result.errorMessage);
                    }}
                    onFocus={() => {
                      form.flag("surname");
                    }}
                    onBlur={(event) => {
                      if (form.isFlagged("surname")) {
                        const result = form.validate("surname");
                        event.currentTarget.setCustomValidity(result.errorMessage);
                        event.currentTarget.reportValidity();
                        form.unflag("surname");
                      }
                    }}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.column}>
                  <label className={styles.input_label}>{emailLabel}</label>
                  <input
                    ref={emailInputRef}
                    id="email"
                    className={styles.input}
                    value={form.values.email}
                    placeholder={emailLabel}
                    onChange={(event) => {
                      const result = form.setAndValidate("email", event.currentTarget.value);
                      event.currentTarget.setCustomValidity(result.errorMessage);
                    }}
                    onFocus={() => {
                      form.flag("email");
                    }}
                    onBlur={(event) => {
                      if (form.isFlagged("email")) {
                        const result = form.validate("email");
                        event.currentTarget.setCustomValidity(result.errorMessage);
                        event.currentTarget.reportValidity();
                        form.unflag("email");
                      }
                    }}
                  />
                </div>
                <div className={styles.column}>
                  <label className={styles.input_label}>{phoneLabel}</label>
                  <input
                    ref={phoneInputRef}
                    id="phone"
                    className={styles.input}
                    value={form.values.phone}
                    placeholder={phoneLabel}
                    onChange={(event) => {
                      onPhoneChange(event);
                    }}
                    onFocus={() => {
                      form.flag("phone");
                    }}
                    onBlur={(event) => {
                      if (form.isFlagged("phone")) {
                        const result = form.validate("phone");
                        event.currentTarget.setCustomValidity(result.errorMessage);
                        event.currentTarget.reportValidity();
                        form.unflag("phone");
                      }
                    }}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.login_column}>
                  <label className={styles.input_label}>{loginLabel}</label>
                  <input
                    ref={loginInputRef}
                    id="login"
                    className={`${styles.input} ${form.values.login !== form.values.originalLogin ? (loginCheck.available ? styles.login_available : styles.login_not_available) : ""}`}
                    value={form.values.login}
                    placeholder={loginLabel}
                    onChange={(event) => {
                      const result = form.setAndValidate("login", event.currentTarget.value);
                      event.currentTarget.setCustomValidity(result.errorMessage);
                    }}
                    onFocus={() => {
                      form.flag("login");
                    }}
                    onBlur={(event) => {
                      if (form.isFlagged("login")) {
                        const result = form.validate("login");
                        event.currentTarget.setCustomValidity(result.errorMessage);
                        event.currentTarget.reportValidity();
                        form.unflag("login");
                      }
                    }}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.column}>
                  <label className={styles.input_label}>{passwordLabel}</label>
                  <input
                    ref={passwordInputRef}
                    id="password"
                    type="password"
                    className={styles.input}
                    value={form.values.password}
                    placeholder={passwordLabel}
                    onChange={(event) => {
                      const result = form.setAndValidate("password", event.currentTarget.value);
                      event.currentTarget.setCustomValidity(result.errorMessage);
                    }}
                    onFocus={() => {
                      form.flag("password");
                    }}
                    onBlur={(event) => {
                      if (form.isFlagged("password")) {
                        const result = form.validate("password");
                        event.currentTarget.setCustomValidity(result.errorMessage);
                        event.currentTarget.reportValidity();
                        const confirmElement = passwordConfirmInputRef.current;
                        if (confirmElement) {
                          const confirmInput = confirmElement as HTMLInputElement;
                          const result = form.setAndValidate("passwordConfirm", confirmInput.value);
                          confirmInput.setCustomValidity(result.errorMessage);
                        }
                        form.unflag("password");
                      }
                    }}
                  />
                </div>
                <div className={styles.column}>
                  <label className={styles.input_label}>{passwordConfirmLabel}</label>
                  <input
                    ref={passwordConfirmInputRef}
                    id="passwordConfirm"
                    type="password"
                    className={styles.input}
                    value={form.values.passwordConfirm}
                    placeholder={passwordConfirmLabel}
                    onChange={(event) => {
                      const result = form.setAndValidate("passwordConfirm", event.currentTarget.value);
                      event.currentTarget.setCustomValidity(result.errorMessage);
                    }}
                    onFocus={() => {
                      form.flag("passwordConfirm");
                    }}
                    onBlur={(event) => {
                      if (form.isFlagged("passwordConfirm")) {
                        const result = form.validate("passwordConfirm");
                        event.currentTarget.setCustomValidity(result.errorMessage);
                        event.currentTarget.reportValidity();
                        form.unflag("passwordConfirm");
                      }
                    }}
                  />
                </div>
              </div>

              <DataList
                type="company"
                setInputs={(id: string) => {
                  form.setValue("companyId", id);
                }}
                id="dispatcher_co_name"
                labelText={t("auditorCo")}
                value={form.values.companyName}
              />

              <Button type="submit" className={styles.button} label={t("editSubmit")} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OperatorEditComponent;
