import React, {
  Fragment,
  MutableRefObject,
  ReactElement,
  SyntheticEvent,
  cloneElement,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDataContext } from "./DataProvider";
import { ContextError, GeneralContextProps } from "./ContextError";
import { once } from "lodash";
import { useDataStatusContext } from "./DataStatusProvider";
import Spinner from "commons/Spinner/Spinner";
import { GenericTypeObject } from "api/backend";
import styles from "./DataManagement.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { NumberComparisonMode, numberComparisonModeToString, parseNumberComparisonMode } from "api/filters/filterEnums";
import DatePicker from "react-datepicker";
import { getLocale } from "i18n";
import api from "api";

export type FilterType = "string" | "int" | "boolean" | "date" | "date_range";

interface ListContextProps<U, G> extends GeneralContextProps {
  displayLabels: string[];
  labelGenerator: {
    [label: string]: { label: (e: U) => string; column?: keyof U; filter?: { key: keyof G; type: FilterType } };
  };
  orderColumn: { o: keyof U; d: boolean };
  hasStatuses: boolean;
  changeOrderColumn: (e?: string) => void;
}

const createDataListContext = once(<U, G>() => createContext({ isUndefined: true } as ListContextProps<U, G>));
export const useDataListContext = <U, G>() => useContext(createDataListContext<U, G>());

const LabelOrderElement = (props: { orderable: boolean; descending?: boolean; onClick: () => void }) => {
  return (
    <div className={`${styles.order} ${props.orderable ? styles.orderActive : ""}`} onClick={() => props.onClick()}>
      {props.descending !== undefined &&
        (props.descending ? (
          <FontAwesomeIcon className={styles.icon} icon={faArrowDown} />
        ) : (
          <FontAwesomeIcon className={styles.icon} icon={faArrowUp} />
        ))}
    </div>
  );
};

interface DataListLabelProps<T, G, U> {
  gto: GenericTypeObject<T, G, U>;
  headerElement?: ReactElement;
  labelElement?: ReactElement;
  filterElement?: ReactElement;
  translation: any;
  filterRef?: MutableRefObject<G>;
  companyFilterValue?: string;
}

const DataListLabel = <T, G, U>({
  headerElement = <thead />,
  labelElement = <label />,
  filterElement = <input />,
  translation,
  filterRef,
  companyFilterValue,
}: DataListLabelProps<T, G, U>) => {
  const { displayLabels, labelGenerator, orderColumn, hasStatuses, changeOrderColumn, isUndefined } =
    useDataListContext<U, G>();
  const { changeQuery } = useDataContext<T, G, U>();
  const debouncedApiCall = useRef<NodeJS.Timeout>();

  const [dateFiltersState, setDateFilters] = useState<{ [k in keyof G]?: Date }>({});
  const [textFilters, setTextFilters] = useState<{ [k in keyof G]?: string }>({});
  const [numFilters, setNumFilters] = useState<{ [k in keyof G]?: number }>({});
  const [boolFilters, setBoolFilters] = useState<{ [k in keyof G]?: boolean }>({});

  const [rawNumStr, setRawNumStr] = useState<{ [k in keyof G]?: string }>({});

  const [filterBy, setFilterBy] = useState<{ key: keyof G; type: FilterType }[]>([]);

  useEffect(() => {
    let temp: { key: keyof G; type: FilterType }[] = [];

    displayLabels.forEach((label) => {
      let lg = labelGenerator[label];
      if (lg.filter) temp.push(lg.filter);
    });

    setFilterBy(temp);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filterRef !== undefined) {
      let tempS: { [k in keyof G]?: string } = {};
      let tempN: { [k in keyof G]?: number } = {};
      let tempB: { [k in keyof G]?: boolean } = {};
      let tempRNS: { [k in keyof G]?: string } = {};
      let tempD: { [k in keyof G]?: Date } = {};

      filterBy.forEach(({ key, type }) => {
        switch (type) {
          case "string":
            tempS = { ...tempS, [key]: filterRef.current[key] ?? "" };
            break;

          case "boolean":
            tempB = { ...tempB, [key]: filterRef.current[key] ?? false };
            break;

          case "int":
            tempN = { ...tempN, [key]: filterRef.current[key] };

            if (!Number.isNaN(filterRef.current[key]))
              if (filterRef.current[`${String(key)}_compare` as keyof G] !== undefined) {
                const mode = filterRef.current[`${String(key)}_compare` as keyof G] as NumberComparisonMode;
                let prefix = numberComparisonModeToString(mode);
                tempRNS = { ...tempRNS, [key]: `${prefix} ${filterRef.current[key]}` };
              } else tempRNS = { ...tempRNS, [key]: filterRef.current[key] };
            else tempRNS = { ...tempRNS, [key]: "" };
            break;

          case "date":
            tempD = { ...tempD, [key]: filterRef.current[key] };
            break;

          case "date_range":
            tempD = { ...tempD, [key]: filterRef.current[key] };
            if (filterRef.current[`${String(key)}_end` as keyof G] !== undefined) {
              const v = filterRef.current[`${String(key)}_end` as keyof G] as Date;
              tempD = { ...tempD, [`${String(key)}_end`]: v };
            }
            break;
        }
      });

      if (companyFilterValue !== null && companyFilterValue !== "") {
        setRef("company_name" as keyof G, companyFilterValue);
        tempS = { ...tempS, company_name: companyFilterValue };
      }

      setTextFilters(tempS);
      setNumFilters(tempN);
      setBoolFilters(tempB);
      setDateFilters(tempD);

      setRawNumStr(tempRNS);
    }
    // eslint-disable-next-line
  }, [filterBy, filterRef, companyFilterValue]);

  const setRef = (key: keyof G, value: any) => {
    if (filterRef) {
      clearTimeout(debouncedApiCall.current);

      filterRef.current = { ...filterRef.current, [key]: value } as G;

      debouncedApiCall.current = setTimeout(() => {
        changeQuery({ filter: filterRef.current });
      }, api.DEFAULT_DEBOUNCE_DELAY);
    }
  };

  const handleTextChange = (key: keyof G, event: React.FormEvent<HTMLInputElement>) => {
    let value: string = event.currentTarget.value;
    if (value.trim().length === 0) {
      value = value.trim();
    }
    setTextFilters({ ...textFilters, [key]: value });
    setRef(key, value);
  };

  const handleNumberChange = (key: keyof G, event: React.FormEvent<HTMLInputElement>) => {
    const v = event.currentTarget.value;
    const formatted = `${numberComparisonModeToString(parseNumberComparisonMode(v))} ${v.replaceAll(/[^0-9]/gi, "")}`;
    const value: number = Number.parseInt(formatted.replaceAll(/[^0-9]/gi, ""));
    setRawNumStr({ ...rawNumStr, [key]: formatted });
    setNumFilters({ ...numFilters, [key]: value });
    setRef(key, value);
  };

  const handleBoolChange = (key: keyof G, event: React.FormEvent<HTMLInputElement>) => {
    const value: boolean = event.currentTarget.checked;
    setBoolFilters({ ...boolFilters, [key]: value });
    setRef(key, value);
  };

  const handleDatesChange = (
    key: keyof G,
    date: Date | [Date | null, Date | null] | null,
    e: SyntheticEvent<any, Event> | undefined,
  ) => {
    if (date instanceof Date) {
      setDateFilters({ ...dateFiltersState, [key]: date });
      setRef(key, date);
    } else if (date !== null && e !== undefined) {
      const [start, end] = date;
      setDateFilters({ ...dateFiltersState, [key]: start, [`${String(key)}_end` as keyof G]: end });
      setRef(key, start);
      setRef(`${String(key)}_end` as keyof G, end);
    }
  };

  const areDatesEqual = (date_1?: Date, date_2?: Date): boolean => {
    if (!date_1 || !date_2) return false;

    return (
      date_1.getFullYear() === date_2.getFullYear() &&
      date_1.getMonth() === date_2.getMonth() &&
      date_1.getDate() === date_2.getDate()
    );
  };

  if (isUndefined) return <ContextError consumerName="DataListLabel" providerName="DataList" />;
  return cloneElement(headerElement, {
    children: (
      <tr>
        {hasStatuses && cloneElement(labelElement, { key: "status_header" })}
        {displayLabels.map((label) => {
          const isOrderable = labelGenerator[label].column !== undefined;
          const order = labelGenerator[label].column === orderColumn.o;
          const filter = labelGenerator[label].filter;

          return cloneElement(labelElement, {
            key: label,
            children: (
              <div className={styles.labelWrapper}>
                {(filter === undefined || filterRef === undefined) && translation(label)}
                {filter &&
                  filterRef &&
                  (filter.type === "string" && textFilters[filter.key] !== undefined ? (
                    cloneElement(filterElement, {
                      key: `filter_${String(filter.key)}`,
                      onChange: (e: React.FormEvent<HTMLInputElement>) => {
                        handleTextChange(filter.key, e);
                      },
                      id: filter.key,
                      value: textFilters[filter.key],
                      type: "text",
                      autoComplete: "off",
                      placeholder: translation(label),
                    })
                  ) : filter.type === "int" && numFilters[filter.key] !== undefined ? (
                    cloneElement(filterElement, {
                      key: `filter_${String(filter.key)}`,
                      onChange: (e: React.FormEvent<HTMLInputElement>) => handleNumberChange(filter.key, e),
                      id: filter.key,
                      value: rawNumStr[filter.key] ?? "",
                      type: "text",
                      autoComplete: "off",
                      placeholder: translation(label),
                    })
                  ) : filter.type === "boolean" && boolFilters[filter.key] !== undefined ? (
                    cloneElement(filterElement, {
                      key: `filter_${String(filter.key)}`,
                      onChange: (e: React.FormEvent<HTMLInputElement>) => handleBoolChange(filter.key, e),
                      id: filter.key,
                      checked: boolFilters[filter.key],
                      type: "checkbox",
                    })
                  ) : filter.type === "date" || filter.type === "date_range" ? (
                    <DatePicker
                      dayClassName={(date) => {
                        if (areDatesEqual(date, dateFiltersState[`${String(filter.key)}_end` as keyof G])) {
                          return "react-datepicker__day--selected";
                        }
                        return null;
                      }}
                      key={`filter_${String(filter.key)}`}
                      id={String(filter.key)}
                      onChange={(date, e) => handleDatesChange(filter.key, date, e)}
                      selected={dateFiltersState[filter.key]}
                      startDate={dateFiltersState[filter.key]}
                      endDate={dateFiltersState[`${String(filter.key)}_end` as keyof G]}
                      selectsRange={filter.type === "date_range"}
                      dateFormat="dd-MM-yyyy"
                      locale={getLocale()}
                      adjustDateOnChange
                      isClearable
                      customInput={filterElement}
                      placeholderText={translation(label)}
                      closeOnScroll
                    />
                  ) : null)}
                <LabelOrderElement
                  orderable={isOrderable}
                  descending={order ? orderColumn.d : undefined}
                  onClick={() => {
                    if (isOrderable) {
                      if (order && !orderColumn.d) {
                        changeOrderColumn(`rev:${label}`);
                      } else {
                        changeOrderColumn(`${label}`);
                      }
                    }
                  }}
                />
              </div>
            ),
          });
        })}
      </tr>
    ),
  });
};

interface DataListProps<T, G, U> {
  gto: GenericTypeObject<T, G, U>;
  labelGenerator: {
    [label: string]: { label: (e: U) => string; column?: keyof U; filter?: { key: keyof G; type: FilterType } };
  };
  defaultOrder: keyof U;
  descendingDefaultOrder?: boolean;
  keyGenerator: (e: U) => React.Key;
  tableWrapperElement?: ReactElement;
  headerElement?: ReactElement;
  dataWrapperElement?: ReactElement;
  dataRowElement?: ReactElement;
  dataLabelElement?: ReactElement;
  statusMarkerElement?: ReactElement;
  listOnTop?: boolean;
  customBody?: boolean;
  children?: <K extends U>(
    data: K,
    expand: boolean,
    generated_key: React.Key,
    reloadOneRow: () => void,
    labels: string[],
    labelGenerator: {
      [label: string]: { label: (e: U) => string; column?: keyof U; filter?: { key: keyof G; type: FilterType } };
    },
    statuses: { [label: string]: string },
  ) => ReactElement;
}

export const DataList = <T, G, U>({
  labelGenerator,
  defaultOrder,
  descendingDefaultOrder = false,
  keyGenerator,
  tableWrapperElement = <table />,
  headerElement = <thead />,
  dataWrapperElement = <tbody />,
  dataRowElement = <tr />,
  dataLabelElement = <td />,
  statusMarkerElement,
  listOnTop = false,
  customBody = false,
  children,
}: DataListProps<T, G, U>) => {
  const dataContext = useDataContext<U[], G>();
  const displayLabels = Object.keys(labelGenerator);
  const [orderColumn, setOrderColumn] = useState({ o: defaultOrder, d: descendingDefaultOrder });

  const dataListContext = createDataListContext<U, G>();
  const dataStatusContext = useDataStatusContext<U>();
  const hasStatuses = statusMarkerElement !== undefined && !dataStatusContext.isUndefined;

  const [dataStatuses, setDataStatuses] = useState<{ [k: React.Key]: string }>({});
  const [expand, setExpand] = useState<{ [k: React.Key]: boolean }>({});

  useEffect(() => {
    if (hasStatuses) {
      let temp = {};

      dataContext.data?.forEach((e) => {
        for (let s of dataStatusContext.statusKeys) {
          if (dataStatusContext.statuses[s].condition(e)) {
            temp = { ...temp, [keyGenerator(e)]: s };
            break;
          }
        }
      });

      setDataStatuses(temp);
    }
  }, [hasStatuses, dataContext, dataStatusContext, keyGenerator]);

  useEffect(() => {
    let temp = {};

    dataContext.data?.forEach((e) => {
      temp = { ...temp, [keyGenerator(e)]: false };
    });

    setExpand(temp);
  }, [dataContext, keyGenerator]);

  useEffect(() => {
    dataContext.changeQuery({
      order_by: orderColumn.o as keyof (U extends (infer U)[] ? U : U),
      descending: orderColumn.d,
    });
    // eslint-disable-next-line
  }, [orderColumn]);

  if (dataContext.isUndefined) return <ContextError consumerName="DataList" providerName="DataProvider" />;
  return (
    <dataListContext.Provider
      value={{
        displayLabels: displayLabels,
        labelGenerator: labelGenerator,
        orderColumn: orderColumn,
        hasStatuses: hasStatuses,
        changeOrderColumn: (e) => {
          if (e === undefined) {
            setOrderColumn({ o: defaultOrder, d: false });
          } else if (e.startsWith("rev:")) {
            setOrderColumn({
              o: (labelGenerator[e.split(":")[1]].column ?? defaultOrder) as keyof U,
              d: true,
            });
          } else {
            setOrderColumn({ o: (labelGenerator[e].column ?? defaultOrder) as keyof U, d: false });
          }
        },
      }}
    >
      <Spinner isFetched={!dataContext.loading} size={"80px"}>
        {customBody &&
          cloneElement(tableWrapperElement, {
            children: (
              <>
                {children &&
                  children(
                    {} as U,
                    true,
                    "row_custom_body",
                    dataContext.refetch,
                    displayLabels,
                    labelGenerator,
                    dataStatuses,
                  )}
              </>
            ),
          })}
        {!customBody && (
          <>
            {cloneElement(tableWrapperElement, {
              children: (
                <>
                  {!listOnTop && headerElement}
                  {cloneElement(dataWrapperElement, {
                    children: (
                      <>
                        {dataContext.data?.map((e) => {
                          const key = keyGenerator(e);
                          return (
                            <Fragment key={key}>
                              {cloneElement(dataRowElement, {
                                key: "row_" + key + "_primary",
                                onClick: () => {
                                  setExpand({ ...expand, [key]: !expand[key] });
                                },
                                children: (
                                  <>
                                    {hasStatuses &&
                                      cloneElement(dataLabelElement, {
                                        key: "row_" + key + "_status_wrapper",
                                        style: { padding: "5px 0 5px 10px" },
                                        children: (
                                          <>
                                            {cloneElement(statusMarkerElement, {
                                              style: {
                                                backgroundColor: dataStatusContext.active[dataStatuses[key]]
                                                  ? dataStatusContext.statuses[dataStatuses[key]].color
                                                  : "white",
                                              },
                                            })}
                                          </>
                                        ),
                                      })}
                                    {displayLabels.map((label) =>
                                      cloneElement(dataLabelElement, {
                                        key: key + "_" + label,
                                        children: labelGenerator[label].label(e),
                                      }),
                                    )}
                                  </>
                                ),
                              })}
                              {children &&
                                children(
                                  e,
                                  expand[key],
                                  `row_${key}_secondary`,
                                  () => {
                                    dataContext.refetch();
                                    setTimeout(() => setExpand({ ...expand, [key]: true }), 50);
                                  },
                                  displayLabels,
                                  labelGenerator,
                                  dataStatuses,
                                )}
                            </Fragment>
                          );
                        })}
                      </>
                    ),
                  })}
                  {listOnTop && headerElement}
                </>
              ),
            })}
          </>
        )}
      </Spinner>
    </dataListContext.Provider>
  );
};

DataList.Header = DataListLabel;
