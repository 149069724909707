import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import api, { useLoginDebounce } from "api";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import DataList from "commons_new/DataList/DataList";
import CheckField from "commons/Registration/CheckField/CheckField";
import Modal from "commons/Modal";
import styles from "./Dispatcher.module.css";
import Button from "commons/Button/Button";
import useForm from "commons/form/useForm";
import dispatcherRegisterRequestFactory from "./dispatcher-register-request.factory";
import dispatcherRegisterFormFactory from "./form/dispatcher-register-form.factory";

export default function Dispatcher() {
  const { t } = useTranslation("adminView", { keyPrefix: "register" });
  const { handleErrors } = useErrorHandling();
  const [dualRole, setDualRole] = useState(false);
  const [alert, setAlert] = useState(false);

  const form = useForm({
    emptyValues: dispatcherRegisterFormFactory.getEmptyFormData(),
    validationDefinition: dispatcherRegisterFormFactory.getFormValidationDefinition(),
  });

  const nameInputRef = useRef<HTMLInputElement>(null);
  const surnameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const loginInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const passwordConfirmInputRef = useRef<HTMLInputElement>(null);
  const companyNameInputRef = useRef<HTMLInputElement>(null);

  const loginCheck = useLoginDebounce(form.values.login);

  useEffect(() => {
    form.setValue("loginCheck", loginCheck);

    if (loginInputRef.current) {
      const loginValidation = form.validate("login");
      loginInputRef.current?.setCustomValidity(loginValidation.errorMessage);
    }

    // eslint-disable-next-line
  }, [loginCheck]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!form.validateAll()) {
      nameInputRef.current?.setCustomValidity(form.validationResults["name"].errorMessage);
      surnameInputRef.current?.setCustomValidity(form.validationResults["surname"].errorMessage);
      emailInputRef.current?.setCustomValidity(form.validationResults["email"].errorMessage);
      phoneInputRef.current?.setCustomValidity(form.validationResults["phone"].errorMessage);
      loginInputRef.current?.setCustomValidity(form.validationResults["login"].errorMessage);
      passwordInputRef.current?.setCustomValidity(form.validationResults["password"].errorMessage);
      passwordConfirmInputRef.current?.setCustomValidity(form.validationResults["passwordConfirm"].errorMessage);
      companyNameInputRef.current?.setCustomValidity(form.validationResults["companyName"].errorMessage);

      event.currentTarget.reportValidity();
      return;
    }

    const request = dispatcherRegisterRequestFactory.create(form.values);

    if (dualRole) {
      api.registerDispatcherAndOperator(request).then((res) => {
        if (handleErrors(res.error).ok) {
          form.restore();
          setAlert(true);
        } else {
          setAlert(false);
        }
      });
    } else {
      api.registerDispatcher(request).then((res) => {
        if (handleErrors(res.error).ok) {
          form.restore();
          setAlert(true);
        } else {
          setAlert(false);
        }
      });
    }
  };

  const onPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    if (value === "") {
      const result = form.setAndValidate("phone", event.currentTarget.value);
      event.currentTarget.setCustomValidity(result.errorMessage);
      return;
    }

    const validationPattern = /^\+?[0-9]{0,16}$/;
    if (validationPattern.test(value)) {
      const result = form.setAndValidate("phone", event.currentTarget.value);
      event.currentTarget.setCustomValidity(result.errorMessage);
    }
  };

  const nameLabel = t("dispatcher.name");
  const surnameLabel = t("dispatcher.surname");
  const emailLabel = t("dispatcher.email");
  const phoneLabel = t("dispatcher.phone");
  const loginLabel = t("dispatcher.login");
  const passwordLabel = t("dispatcher.password");
  const passwordConfirmLabel = t("dispatcher.password_confirm");

  return (
    <>
      {alert && (
        <Modal
          action1={() => {
            setAlert(false);
          }}
          title={t("modal.registerTitle")}
        />
      )}
      <form onSubmit={handleSubmit} autoComplete={"off"}>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            <div className={styles.column}>
              <label className={styles.input_label}>{nameLabel}</label>
              <input
                ref={nameInputRef}
                id="name"
                className={styles.input}
                value={form.values.name}
                placeholder={nameLabel}
                onChange={(event) => {
                  const result = form.setAndValidate("name", event.currentTarget.value);
                  event.currentTarget.setCustomValidity(result.errorMessage);
                }}
                onFocus={() => {
                  form.flag("name");
                }}
                onBlur={(event) => {
                  if (form.isFlagged("name")) {
                    const result = form.validate("name");
                    event.currentTarget.setCustomValidity(result.errorMessage);
                    event.currentTarget.reportValidity();
                    form.unflag("name");
                  }
                }}
              />
            </div>
            <div className={styles.column}>
              <label className={styles.input_label}>{surnameLabel}</label>
              <input
                ref={surnameInputRef}
                id="surname"
                className={styles.input}
                value={form.values.surname}
                placeholder={surnameLabel}
                onChange={(event) => {
                  const result = form.setAndValidate("surname", event.currentTarget.value);
                  event.currentTarget.setCustomValidity(result.errorMessage);
                }}
                onFocus={() => {
                  form.flag("surname");
                }}
                onBlur={(event) => {
                  if (form.isFlagged("surname")) {
                    const result = form.validate("surname");
                    event.currentTarget.setCustomValidity(result.errorMessage);
                    event.currentTarget.reportValidity();
                    form.unflag("surname");
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <label className={styles.input_label}>{emailLabel}</label>
              <input
                ref={emailInputRef}
                id="email"
                className={styles.input}
                value={form.values.email}
                placeholder={emailLabel}
                onChange={(event) => {
                  const result = form.setAndValidate("email", event.currentTarget.value);
                  event.currentTarget.setCustomValidity(result.errorMessage);
                }}
                onFocus={() => {
                  form.flag("email");
                }}
                onBlur={(event) => {
                  if (form.isFlagged("email")) {
                    const result = form.validate("email");
                    event.currentTarget.setCustomValidity(result.errorMessage);
                    event.currentTarget.reportValidity();
                    form.unflag("email");
                  }
                }}
              />
            </div>
            <div className={styles.column}>
              <label className={styles.input_label}>{phoneLabel}</label>
              <input
                ref={phoneInputRef}
                id="phone"
                className={styles.input}
                value={form.values.phone}
                placeholder={phoneLabel}
                onChange={(event) => {
                  onPhoneChange(event);
                }}
                onFocus={() => {
                  form.flag("phone");
                }}
                onBlur={(event) => {
                  if (form.isFlagged("phone")) {
                    const result = form.validate("phone");
                    event.currentTarget.setCustomValidity(result.errorMessage);
                    event.currentTarget.reportValidity();
                    form.unflag("phone");
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.login_column}>
              <label className={styles.input_label}>{loginLabel}</label>
              <input
                ref={loginInputRef}
                id="login"
                className={`${styles.input} ${!!form.values.login ? (loginCheck.available ? styles.login_available : styles.login_not_available) : ""}`}
                value={form.values.login}
                placeholder={loginLabel}
                onChange={(event) => {
                  const result = form.setAndValidate("login", event.currentTarget.value);
                  event.currentTarget.setCustomValidity(result.errorMessage);
                }}
                onFocus={() => {
                  form.flag("login");
                }}
                onBlur={(event) => {
                  if (form.isFlagged("login")) {
                    const result = form.validate("login");
                    event.currentTarget.setCustomValidity(result.errorMessage);
                    event.currentTarget.reportValidity();
                    form.unflag("login");
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <label className={styles.input_label}>{passwordLabel}</label>
              <input
                ref={passwordInputRef}
                id="password"
                type="password"
                className={styles.input}
                value={form.values.password}
                placeholder={passwordLabel}
                onChange={(event) => {
                  const result = form.setAndValidate("password", event.currentTarget.value);
                  event.currentTarget.setCustomValidity(result.errorMessage);
                }}
                onFocus={() => {
                  form.flag("password");
                }}
                onBlur={(event) => {
                  if (form.isFlagged("password")) {
                    const result = form.validate("password");
                    event.currentTarget.setCustomValidity(result.errorMessage);
                    event.currentTarget.reportValidity();

                    const confirmInput = passwordConfirmInputRef.current;
                    if (confirmInput) {
                      const result = form.setAndValidate("passwordConfirm", confirmInput.value);
                      confirmInput.setCustomValidity(result.errorMessage);
                    }
                    form.unflag("password");
                  }
                }}
              />
            </div>
            <div className={styles.column}>
              <label className={styles.input_label}>{passwordConfirmLabel}</label>
              <input
                ref={passwordConfirmInputRef}
                id="passwordConfirm"
                type="password"
                className={styles.input}
                value={form.values.passwordConfirm}
                placeholder={passwordConfirmLabel}
                onChange={(event) => {
                  const result = form.setAndValidate("passwordConfirm", event.currentTarget.value);
                  event.currentTarget.setCustomValidity(result.errorMessage);
                }}
                onFocus={() => {
                  form.flag("passwordConfirm");
                }}
                onBlur={(event) => {
                  if (form.isFlagged("passwordConfirm")) {
                    const result = form.validate("passwordConfirm");
                    event.currentTarget.setCustomValidity(result.errorMessage);
                    event.currentTarget.reportValidity();
                    form.unflag("passwordConfirm");
                  }
                }}
              />
            </div>
          </div>

          <DataList
            inputRef={companyNameInputRef}
            type="company"
            setInputs={(id: string, name) => {
              form.setValue("companyId", id);
              const result = form.setAndValidate("companyName", name);
              companyNameInputRef.current?.setCustomValidity(result.errorMessage);
            }}
            onFocus={() => {
              form.flag("companyName");
            }}
            onBlur={(event) => {
              if (form.isFlagged("companyName")) {
                const result = form.validate("companyName");
                event.currentTarget.setCustomValidity(result.errorMessage);
                event.currentTarget.reportValidity();
                form.unflag("companyName");
              }
            }}
            id="dispatcher_co_name"
            labelText={t("dispatcher.dispatcherCo")}
            value={form.values.companyName}
          />

          <CheckField
            labelText={t("dispatcher.doubleRole")}
            value={dualRole}
            setValue={() => {
              setDualRole(!dualRole);
            }}
          />

          <Button type="submit" className={styles.button} label={t("dispatcher.register")} />
        </div>
      </form>
    </>
  );
}
