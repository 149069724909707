import React, { useState, useEffect, useRef, RefObject, FocusEventHandler } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import { AddressBase } from "api/entities/bases/address";
import Spinner from "commons/Spinner/Spinner";
import localStyles from "./ListInput.module.css";
import commonStyles from "commons/Registration/Registration.module.css";

import api from "api";
import MapNewAddr from "commons/Map/MapNewAddr";
import { StringMatchingMode } from "api/filters/filterEnums";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import { SearchAddressResponseItem } from "./search-address-response";
import { nominatimURL } from "api/backend";

/* 
    setInput - 
        when selected existing one -> id, lati, long -> filled
        when creating new -> name, lati, long -> filled
            name - from input field
            lati, long - from the included map

*/

interface localProps {
  setInput: (id: string, name: string, lati: number | undefined, long: number | undefined) => void; // function setting id field in a component above
  labelText: string; // Label name
  id: string; // id can be anything, except other html input ids in a form

  resetElement?: boolean; //switch this -> !this to clear element
  updateElement?: boolean;
  required?: boolean;

  setAddrExists?: (m: boolean) => void; // sets commission create mode: true - uses existing addres, false - creates new
  newAddressEnabled?: boolean;

  value?: string;
  inputRef?: RefObject<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

enum ListMode {
  default,
  selected,
  new,
}

export default function ListInputAddrNomi(props: localProps) {
  const { t } = useTranslation("common", { keyPrefix: "listInputAddr" });
  const { handleErrors } = useErrorHandling();

  const [isFetched, setIsFetched] = useState(false);

  // Stores fetched data
  const fetchedData = useRef<AddressBase[]>([]);

  // Stores component state
  const [state, setState] = useState<ListMode>(ListMode.default);
  const [coords, setCoords] = useState<{ lati: number; long: number } | undefined>(undefined);
  const [newSelected, setNewSelected] = useState(false);

  // Stores value for main input
  const [input, setInput] = useState("");

  // For Csstransition
  const listRef = useRef(null);

  // Nominatim interface
  const [addrSearchRes, setAddrSearchRes] = useState<SearchAddressResponseItem[]>([]);

  // Asks server for addresses when 500ms passes after last keystroke
  useEffect(() => {
    if (!isFetched && state === ListMode.default) {
      if (input.length > 0) {
        const delayDebounceFn = setTimeout(() => {
          api
            .fetchAddresses({
              filter: {
                name: input,
                name_match: StringMatchingMode.INCLUDE,
              },
            })
            .then((res) => {
              handleErrors(res.error);
              fetchedData.current = res.data;
              setIsFetched(true);
            });
        }, 500);

        return () => clearTimeout(delayDebounceFn);
      } else {
        setIsFetched(true);
        fetchedData.current = [];
      }
    } else if (!isFetched && state === ListMode.new) {
      if (input.length > 0) {
        const delayDebounceFn = setTimeout(() => {
          const query_params = new URLSearchParams();

          query_params.append("addressdetails", "1");
          query_params.append("accept-language", "pl");
          query_params.append("q", String(input));

          fetch(nominatimURL + "/search?" + query_params, {
            method: "GET",
          })
            .then((x) => x.json())
            .then((x) => {
              setAddrSearchRes(x);
              handleErrors(x.error);
              setIsFetched(true);
            });
        }, 500);

        return () => clearTimeout(delayDebounceFn);
      } else {
        setIsFetched(true);
      }
    }
  }, [isFetched, input, state, handleErrors]);

  const loaded = useRef(false);

  // Resets component
  useEffect(() => {
    if (loaded.current === false) loaded.current = true;
    else {
      setState(ListMode.default);
      setInput("");
      props.setInput("", "", 0.0, 0.0);
    }

    // eslint-disable-next-line
  }, [props.resetElement]);

  useEffect(() => {
    if (loaded.current === true) {
      if (props.value) {
        setInput(props.value);
        setState(ListMode.selected);
      }
    }
    // eslint-disable-next-line
  }, [loaded.current, props.value, props.updateElement]);

  return (
    <div className={localStyles.content}>
      <div className={commonStyles.single_input}>
        {props.labelText !== "" && (
          <label htmlFor={props.id} className={commonStyles.input_label} title={props.labelText}>
            {props.labelText}
          </label>
        )}
        <div className={localStyles.input_with_button}>
          {/* Main input */}
          <input
            ref={props.inputRef}
            id={props.id}
            className={commonStyles.input}
            value={input}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              /* Do not allow users to change this input when state === ListMode.selected */
              setInput(e.currentTarget.value);
              setIsFetched(false);
              if (state === ListMode.new) props.setInput("", input, coords?.lati, coords?.long);
              if (state === ListMode.new && newSelected) setNewSelected(false);
            }}
            type="text"
            placeholder={(state === ListMode.default ? t("chooseFromList") : t("newAddressInput")) ?? ""}
            autoComplete="off"
            required={props.required ?? true}
            title={(state === ListMode.default ? t("chooseFromList") : t("newAddressInput")) ?? ""}
            spellCheck={false}
            style={{ cursor: state === ListMode.selected ? "not-allowed" : "text" }}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
          ></input>

          {/* X Button */}
          <div
            onClick={() => {
              props.setInput("", "", undefined, undefined);
              setInput("");
              setCoords(undefined);
              if (state === ListMode.selected) setState(ListMode.default);
              if (state === ListMode.new && newSelected) setNewSelected(false);
            }}
            className={localStyles.button}
            id={input === "" ? localStyles.button_disabled : ""}
          >
            <FontAwesomeIcon
              className={localStyles.icon}
              id={input === "" ? localStyles.icon_disabled : ""}
              icon={faXmark}
            />
          </div>
        </div>
      </div>

      {/* Expandable list with new address button */}
      {state !== ListMode.new ? (
        <CSSTransition
          in={state === ListMode.default && input.length !== 0}
          nodeRef={listRef}
          timeout={{
            enter: 300,
            exit: 300,
          }}
          classNames={{
            enter: localStyles.enterAddr,
            enterActive: localStyles.enterActiveAddr,
            exit: localStyles.exitAddr,
            exitActive: localStyles.exitActiveAddr,
          }}
          mountOnEnter
          unmountOnExit
          appear
        >
          <div ref={listRef}>
            <div className={localStyles.list_wrapper}>
              <div className={localStyles.list}>
                <Spinner isFetched={isFetched} size={"50px"} padding={"20px"}>
                  {fetchedData.current.length === 0 ? (
                    <div className={localStyles.list_item} key={"error: no entries"}>
                      {t("noEntries")}
                    </div>
                  ) : (
                    fetchedData.current.map((data) => {
                      return (
                        <div
                          onClick={() => {
                            props.setInput(data.id, data.name, data.latitude, data.longitude);
                            setInput(data.name);
                            setState(ListMode.selected);
                            setNewSelected(true);
                          }}
                          className={localStyles.list_item}
                          key={`${data.id}`}
                        >
                          {data.name}
                        </div>
                      );
                    })
                  )}
                </Spinner>
              </div>
            </div>
          </div>
        </CSSTransition>
      ) : (
        // wyswietlane w przypadku dodawania nowego adresu z nominatim
        <>
          <CSSTransition
            in={state === ListMode.new && input.length !== 0 && !newSelected}
            nodeRef={listRef}
            timeout={{
              enter: 300,
              exit: 300,
            }}
            classNames={{
              enter: localStyles.enterAddr,
              enterActive: localStyles.enterActiveAddr,
              exit: localStyles.exitAddr,
              exitActive: localStyles.exitActiveAddr,
            }}
            mountOnEnter
            unmountOnExit
            appear
          >
            <div ref={listRef}>
              <div className={localStyles.list_wrapper}>
                <div className={localStyles.list}>
                  <Spinner isFetched={isFetched} size={"50px"} padding={"20px"}>
                    {addrSearchRes.length === 0 ? (
                      <div className={localStyles.list_item} key={"error: no entries"}>
                        {t("noEntries")}
                      </div>
                    ) : (
                      addrSearchRes.map((data) => {
                        return (
                          <div
                            onClick={() => {
                              props.setInput("", data.display_name, parseFloat(data.lat), parseFloat(data.lon));
                              setInput(data.display_name);
                              setCoords({
                                lati: parseFloat(data.lat),
                                long: parseFloat(data.lon),
                              });
                              setNewSelected(true);
                            }}
                            className={localStyles.list_item}
                            key={`${data.display_name} + ${Math.random()}`}
                          >
                            {data.display_name}
                          </div>
                        );
                      })
                    )}
                  </Spinner>
                </div>
              </div>
            </div>
          </CSSTransition>
          <div className={localStyles.map_wrapper}>
            <MapNewAddr
              lat={coords?.lati}
              lon={coords?.long}
              height="450px"
              newAddr={(x: number, y: number, name: string) => {
                setCoords({ lati: x, long: y });
                setInput(name);
                props.setInput("", name, x, y);
                setNewSelected(true);
              }}
              autoFit
            />
          </div>
        </>
      )}

      {/* Mode switch button: default -> new; new -> default */}
      {props.newAddressEnabled === true && (
        <>
          <div className={`${localStyles.new_addr_wrapper} ${localStyles.new_addr_or}`}>{t("or")}</div>
          <div
            onClick={() => {
              state === ListMode.default || state === ListMode.selected
                ? setState(ListMode.new)
                : setState(ListMode.default);

              setInput("");
              setCoords(undefined);
              props.setInput("", "", undefined, undefined);
            }}
            className={localStyles.new_addr_wrapper}
          >
            <div className={localStyles.new_addr}>
              {state === ListMode.new ? t("selectExistingAddress") : t("addNewAddress")}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
