import { DecodedToken, UserProfile } from "api/entities/auth";
import jwt_decode from "jwt-decode";

/*

    Even though file is called 'sessionStorageHelper'
    its using a localStorage to keep data in the browser

*/
const languageLocalStorageKey = "LANGUAGE";
const i18nLanguageLocalStorageKey = "i18nextLng";
const accessTokenStorageKey = "token";
const accessTokenExpirationTimeKey = "token_expire";
const userProfileStorageKey = "user";

export type Aspect = "admin" | "dispatcher" | "operator" | "worker" | "none";

const setAccessToken = (token: string) => {
  localStorage.setItem(accessTokenStorageKey, token);
};

const getAccessToken = () => {
  return localStorage.getItem(accessTokenStorageKey);
};

const getAccessTokenExpirationTime = (): number | undefined => {
  const value = localStorage.getItem(accessTokenExpirationTimeKey);

  if (!value) {
    return undefined;
  }

  return Number(value);
};

const setAccessTokenExpirationTime = (value: number) => {
  localStorage.setItem(accessTokenExpirationTimeKey, String(value * 1000));
};

const getUserProfile = (): UserProfile | undefined => {
  const user_json = JSON.parse(localStorage.getItem(userProfileStorageKey) ?? "{}");

  if (user_json.id !== undefined) return user_json as UserProfile;

  return undefined;
};

const setUserProfile = (user: UserProfile) => {
  localStorage.setItem(userProfileStorageKey, JSON.stringify(user));
};

const createUserProfile = (decodedToken: DecodedToken): UserProfile => {
  return {
    admin: decodedToken.admin,
    auditor: decodedToken.auditor,
    auditor_manager: decodedToken.auditor_manager,
    company_id: decodedToken.company_id,
    dispatcher: decodedToken.dispatcher,
    email: decodedToken.email,
    id: decodedToken.id,
    login: decodedToken.login,
    name: decodedToken.name,
    phone: decodedToken.phone,
    surname: decodedToken.surname,
  };
};

const setStorageOnLogin = (token: string): void => {
  const decodedToken: DecodedToken = jwt_decode(token);

  setAccessToken(token);
  setAccessTokenExpirationTime(decodedToken.exp);

  const userProfile = createUserProfile(decodedToken);
  setUserProfile(userProfile);
};

const checkIsAccessTokenValid = () => {
  const accessToken = getAccessToken();
  const accessTokenExpirationTimeKey = getAccessTokenExpirationTime();

  const isTokenExpired = !!accessTokenExpirationTimeKey && accessTokenExpirationTimeKey < new Date().getTime();

  return !!accessToken && !!accessTokenExpirationTimeKey && !isTokenExpired;
};

const getAspects = (user?: UserProfile): Aspect[] => {
  if (user === undefined) user = getUserProfile();

  let aspects: Aspect[] = [];

  if (user === undefined) return aspects;

  if (user.admin !== undefined && user.admin !== null) aspects.push("admin");
  if (user.dispatcher !== undefined && user.dispatcher !== null) aspects.push("dispatcher");
  if (user.auditor_manager !== undefined && user.auditor_manager !== null) aspects.push("operator");
  if (user.auditor !== undefined && user.auditor !== null) aspects.push("worker");

  if (aspects.length === 0) aspects.push("none");

  return aspects;
};

const getFullName = (): string => {
  const user = getUserProfile();

  if (user === undefined) return "Error";

  return user.name + " " + user.surname;
};

const getEmail = (): string => {
  const user = getUserProfile();

  if (user === undefined) return "Error";

  return user.email;
};

const getPhone = (): string => {
  const user = getUserProfile();

  if (user === undefined) return "Error";

  return user.phone;
};

const getAuditorCoId = (): string => {
  const user = getUserProfile();

  if (user === undefined) return "";

  return user.company_id ?? "error";
};

const getDispatcherCoId = (): string => {
  const user = getUserProfile();

  if (user === undefined) return "";

  return user.company_id ?? "error";
};

const getUserId = (): string => {
  const user = getUserProfile();

  if (user === undefined) return "Error";

  return user.id;
};

const getAuditorManagerId = (): string => {
  const user = getUserProfile();

  if (user === undefined) return "";

  if (user.auditor_manager !== null) return user.auditor_manager.id ?? "error";

  return "";
};

const getAuditorId = (): string => {
  const user = getUserProfile();

  if (user === undefined) return "";

  if (user.auditor) return user.auditor.id ?? "error";

  return "";
};

const getDispatcherId = (): string => {
  const user = getUserProfile();

  if (user === undefined) return "";

  if (user.dispatcher) return user.dispatcher.id ?? "error";

  return "";
};

const clearStorage = (): void => {
  localStorage.removeItem(accessTokenStorageKey);
  localStorage.removeItem(accessTokenExpirationTimeKey);
  localStorage.removeItem(userProfileStorageKey);
};

const setLanguage = (language: string) => {
  localStorage.setItem(languageLocalStorageKey, language);
};

const getLanguage = () => {
  return localStorage.getItem(languageLocalStorageKey) ?? localStorage.getItem(i18nLanguageLocalStorageKey) ?? "pl";
};

const sessionStorageHelper = {
  setStorageOnLogin,

  getToken: getAccessToken,
  getAccessTokenExpirationTime,
  setAccessTokenExpirationTime,
  checkIsAccessTokenValid,
  getUserProfile,

  getAspects,
  getFullName,
  getEmail,
  getPhone,

  getUserId,
  getAuditorManagerId,
  getAuditorId,
  getDispatcherId,
  getAuditorCoId,
  getDispatcherCoId,

  clearStorage,

  setLanguage,
  getLanguage,
};

export { sessionStorageHelper };
