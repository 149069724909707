import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmerica } from "@fortawesome/free-solid-svg-icons";
import styles from "./LanguageMenu.module.css";
import useAppContext from "context/useContext";

// type: css style align
// currently supports:
// left, center
export default function LanguageMenu(props: { type: string }) {
  const [langMenu, setLangMenu] = useState(false);
  const { setAppLanguage } = useAppContext();

  const menuCssType = useRef(styles.languages_left);

  useEffect(() => {
    if (props.type === "center") menuCssType.current = styles.languages_center;
  }, [props.type]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.menu} onClick={() => setLangMenu(!langMenu)}>
        <div className={styles.icon_wrapper}>
          <FontAwesomeIcon className={langMenu ? styles.icon_selected : styles.icon} icon={faEarthAmerica} />
        </div>
        {langMenu && (
          <div className={menuCssType.current}>
            <div
              className={styles.langSelect}
              onClick={() => {
                setAppLanguage("pl");
              }}
            >
              <span>Polski</span>
            </div>
            <div
              className={styles.langSelect}
              onClick={() => {
                setAppLanguage("en");
              }}
            >
              <span>English</span>
            </div>
            <div
              className={styles.langSelect}
              onClick={() => {
                setAppLanguage("de");
              }}
            >
              <span>Deutsch</span>
            </div>
            <div
              className={styles.langSelect}
              onClick={() => {
                setAppLanguage("pt");
              }}
            >
              <span>Português</span>
            </div>
            <div
              className={styles.langSelect}
              onClick={() => {
                setAppLanguage("cs");
              }}
            >
              <span>Čeština</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
