import { LoginCheckResponse } from "api/entities/user";
import FormValidationResult from "commons/form/form-validation-result";
import formValidationService from "commons/form/form-validation.service";
import Joi from "joi";

const validateName = (value: string): FormValidationResult => {
  const schema = Joi.string().trim().min(2).max(20).pattern(formValidationService.BASE_NAME_PATTERN).required();

  return formValidationService.validate(value, schema);
};

const validateSurname = (value: string): FormValidationResult => {
  const schema = Joi.string().trim().min(2).max(20).pattern(formValidationService.BASE_NAME_PATTERN).required();

  return formValidationService.validate(value, schema);
};

const validateEmail = (value: string): FormValidationResult => {
  const schema = Joi.string().trim().email({ tlds: false }).required();

  return formValidationService.validate(value, schema);
};

const validatePhone = (value: string): FormValidationResult => {
  const schema = Joi.string()
    .pattern(/^\+?\d{2,16}$/)
    .required();

  return formValidationService.validate(value, schema);
};

const validateLogin = (login: string, loginCheck: LoginCheckResponse): FormValidationResult => {
  const schema = Joi.string().trim().min(3).max(40).pattern(formValidationService.LOGIN_PATTERN).required();

  const result = formValidationService.validate(login, schema);

  if (result.isValid && !loginCheck.available) {
    const customMessages = formValidationService.getValidationCustomMessages();
    return formValidationService.bad(customMessages.loginNotAvailable);
  }

  return result;
};

const validateCompanyName = (value: string): FormValidationResult => {
  const schema = Joi.string().trim().min(1).max(255).required();

  return formValidationService.validate(value, schema);
};

const validateCompanyTaxId = (value: string): FormValidationResult => {
  const schema = Joi.string()
    .trim()
    .pattern(/^\d{1,10}$/)
    .required();

  return formValidationService.validate(value, schema);
};

const validateCompanyRegon = (value: string): FormValidationResult => {
  const schema = Joi.string()
    .trim()
    .pattern(/^\d{1,14}$/)
    .required();

  return formValidationService.validate(value, schema);
};

const validateCompanyKrs = (value: string): FormValidationResult => {
  const schema = Joi.string()
    .trim()
    .pattern(/^\d{1,10}$/)
    .required();

  return formValidationService.validate(value, schema);
};

const validateCompanyInsurance = (value: number): FormValidationResult => {
  const schema = Joi.number().min(0).max(1e9).precision(2);

  return formValidationService.validate(value, schema);
};

const validateCompanyLicenseNumber = (value: string): FormValidationResult => {
  const schema = Joi.string().trim().min(1).max(50);

  return formValidationService.validate(value, schema);
};

const validateAddressName = (value: string): FormValidationResult => {
  const schema = Joi.string().trim().min(1).max(200).required();

  return formValidationService.validate(value, schema);
};

const validateServiceName = (value: string): FormValidationResult => {
  const schema = Joi.string().trim().min(2).max(40).required();

  return formValidationService.validate(value, schema);
};

const validateServicePrice = (value: number): FormValidationResult => {
  const schema = Joi.number().min(0).max(1e6).precision(2).required();

  return formValidationService.validate(value, schema);
};

const registerValidationService = {
  validateName,
  validateSurname,
  validateEmail,
  validatePhone,
  validateLogin,
  validateCompanyName,
  validateCompanyTaxId,
  validateCompanyRegon,
  validateCompanyKrs,
  validateCompanyInsurance,
  validateCompanyLicenseNumber,
  validateAddressName,
  validateServiceName,
  validateServicePrice,
};

export default registerValidationService;
