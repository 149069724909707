import { LoginCheckResponse } from "api/entities/user";
import FormValidationResult from "commons/form/form-validation-result";
import formValidationService from "commons/form/form-validation.service";
import Joi from "joi";

const validateName = (value: string): FormValidationResult => {
  const schema = Joi.string().trim().min(2).max(20).pattern(formValidationService.BASE_NAME_PATTERN).required();

  return formValidationService.validate(value, schema);
};

const validateSurname = (value: string): FormValidationResult => {
  const schema = Joi.string().trim().min(2).max(20).pattern(formValidationService.BASE_NAME_PATTERN).required();

  return formValidationService.validate(value, schema);
};

const validateEmail = (value: string): FormValidationResult => {
  const schema = Joi.string().trim().email({ tlds: false }).required();

  return formValidationService.validate(value, schema);
};

const validatePhone = (value: string): FormValidationResult => {
  const schema = Joi.string()
    .pattern(/^\+?\d{2,16}$/)
    .required();

  return formValidationService.validate(value, schema);
};

const validateLogin = (login: string, originalLogin: string, loginCheck: LoginCheckResponse): FormValidationResult => {
  if (login === originalLogin) return formValidationService.ok();

  const schema = Joi.string().trim().min(3).max(40).pattern(formValidationService.LOGIN_PATTERN).required();

  const result = formValidationService.validate(login, schema);

  if (result.isValid && !loginCheck.available) {
    const customMessages = formValidationService.getValidationCustomMessages();
    return formValidationService.bad(customMessages.loginNotAvailable);
  }

  return result;
};

const auditorEditFormValidationService = {
  validateName,
  validateSurname,
  validateEmail,
  validatePhone,
  validateLogin,
};

export default auditorEditFormValidationService;
