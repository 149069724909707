import { Navigate } from "react-router-dom";
import { ReactNode } from "react";
import { Aspect, sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import { IS_DOC } from "index";
import { routesHelper } from "./routesHelper";

interface localProps {
  aspect: Aspect;
  children?: ReactNode;
}

// TODO wysłanie requesta z token i na podstawie odpowiedzi pozwolic przejsc lub nie
const ProtectedRoute = (props: localProps) => {
  const aspects = sessionStorageHelper.getAspects();
  let element: ReactNode;

  if (aspects.length === 0 || aspects.includes("none") || props.aspect === "none") {
    if (!IS_DOC) element = <Navigate to={routesHelper.sighInPath} replace />;
    else element = <></>;
  } else {
    if (aspects.includes(props.aspect)) element = props.children;
    else element = <Navigate to="/unauthorized" />;
  }

  return <>{element}</>;
};

export default ProtectedRoute;
