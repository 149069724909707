import { Mode } from "index";
import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "../Listings.module.css";
import { CSSTransition } from "react-transition-group";

import api from "api";
import { AuditorManagerWithAuditorCompanyUser } from "api/entities/auditorManager";
import { DispatcherWithDispatcherCompanyUser } from "api/entities/dispatcher";
import { AuditorWithAuditorCompanyUserAddress } from "api/entities/auditor";
import { DataList, DataProvider, TitleProvider } from "commons/DataManagement";
import { DispatcherWithCompanyFilter } from "api/filters/dispatcher";
import { AuditorManagerWithCompanyFilter } from "api/filters/auditor_manager";
import { AuditorUserCompanyFilter } from "api/filters/auditor";
import { useErrorHandling } from "commons/hooks/useErrorHandling";

function SecondaryRow(props: {
    data:
        | AuditorWithAuditorCompanyUserAddress
        | AuditorManagerWithAuditorCompanyUser
        | DispatcherWithDispatcherCompanyUser;
    mode: Mode;
    reload: () => void;
}) {
    const { t } = useTranslation("adminView", { keyPrefix: "users.handles" });
    const navigate = useNavigate();
    const { handleErrors } = useErrorHandling();
    return (
        <>
            <div className={styles.td_exp_heading_content}></div>
            <div className={styles.td_exp_heading_content}>
                <div>
                    <button
                        onClick={() => {
                            switch (props.mode) {
                                case Mode.Auditor:
                                    navigate("/admin/auditor/edit", { state: { id: props.data.id } });
                                    break;
                                case Mode.AuditorMngr:
                                    navigate("/admin/operator/edit", { state: { id: props.data.id } });
                                    break;
                                case Mode.Dispatcher:
                                    navigate("/admin/dispatcher/edit", { state: { id: props.data.id } });
                                    break;
                            }
                        }}
                        id={styles.edit}
                        className={styles.td_exp_button}
                    >
                        {t("edit")}
                    </button>
                </div>
                <div>
                    <button
                        onClick={() => {
                            switch (props.mode) {
                                case Mode.Auditor:
                                    api.removeAuditor(props.data.id).then((response) => {
                                        if (handleErrors(response.error).ok) {
                                            props.reload();
                                        }
                                    });
                                    break;
                                case Mode.AuditorMngr:
                                    api.removeAuditorManager(props.data.id).then((response) => {
                                        if (handleErrors(response.error).ok) {
                                            props.reload();
                                        }
                                    });
                                    break;
                                case Mode.Dispatcher:
                                    api.removeDispatcher(props.data.id).then((response) => {
                                        if (handleErrors(response.error).ok) {
                                            props.reload();
                                        }
                                    });
                                    break;
                            }
                        }}
                        id={styles.delete}
                        className={styles.td_exp_button}
                    >
                        {t("delete")}
                    </button>
                </div>
            </div>
        </>
    );
}

const AuditorProvider = (props: { ht: any; ft: any; companyName: string }) => {
    const filterRef = useRef({
        surname: "",
        company_name: props.companyName,
    } as AuditorUserCompanyFilter);
    // eslint-disable-next-line
    const nodeRef = useRef(null);

    return (
        <DataProvider
            fetchFun={api.fetchAuditorsWithAuditorCompanyAndUser}
            fetchArgs={[]}
            query={{
                order_by: "user_surname" as keyof AuditorWithAuditorCompanyUserAddress,
                filter: {
                    company_name: props.companyName,
                },
            }}
        >
            {(gto) => (
                <>
                    <div className={styles.table_wrapper}>
                        <DataList
                            gto={gto}
                            keyGenerator={(e) => e.id}
                            labelGenerator={{
                                surname: {
                                    label: (e) => e.user.surname,
                                    column: "user_surname",
                                    filter: { key: "user_surname", type: "string" },
                                },
                                name: {
                                    label: (e) => e.user.name,
                                    column: "user_name",
                                    filter: { key: "user_name", type: "string" },
                                },
                                coName: {
                                    label: (e) => e.user.company.company_name,
                                    column: "company_name",
                                    filter: { key: "company_name", type: "string" },
                                },
                                email: {
                                    label: (e) => e.user.email,
                                    column: "user_email",
                                },
                                phone: {
                                    label: (e) => e.user.phone_prefix + " " + e.user.phone,
                                    filter: { key: "user_phone", type: "string" },
                                },
                                login: {
                                    label: (e) => e.user.login,
                                    column: "user_login",
                                    filter: { key: "user_login", type: "string" },
                                },
                            }}
                            defaultOrder="user_surname"
                            tableWrapperElement={<table className={styles.table} />}
                            headerElement={
                                <DataList.Header
                                    gto={gto}
                                    translation={props.ht}
                                    headerElement={<thead className={styles.thead} />}
                                    labelElement={<th scope="col" className={styles.th} />}
                                    filterElement={<input className={styles.input} />}
                                    filterRef={filterRef}
                                    companyFilterValue={props.companyName}
                                />
                            }
                            dataRowElement={<tr className={styles.tr} />}
                            dataLabelElement={<td className={styles.td} />}
                        >
                            {(data, expand, key, reloadOneRow) => (
                                <tr className={styles.tr_exp} key={key}>
                                    <CSSTransition
                                        in={expand}
                                        nodeRef={nodeRef}
                                        timeout={300}
                                        classNames={{
                                            enter: styles.csstrEnter,
                                            enterActive: styles.csstrEnterActive,
                                            exit: styles.csstrExit,
                                            exitActive: styles.csstrExitActive,
                                        }}
                                        unmountOnExit
                                        appear
                                    >
                                        <td ref={nodeRef} className={styles.td_exp} colSpan={6}>
                                            <div className={styles.td_exp_content}>
                                                <div className={styles.td_exp_heading}>
                                                    <SecondaryRow
                                                        data={data}
                                                        mode={Mode.Auditor}
                                                        reload={reloadOneRow}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </CSSTransition>
                                </tr>
                            )}
                        </DataList>
                    </div>
                    <div className={styles.row_items}>
                        <DataProvider.Pagination gto={gto} />
                    </div>
                </>
            )}
        </DataProvider>
    );
};

const AuditorManagerProvider = (props: { ht: any; ft: any; companyName: string }) => {
    const filterRef = useRef({
        surname: "",
        company_name: props.companyName,
    } as AuditorManagerWithCompanyFilter);
    // eslint-disable-next-line
    const nodeRef = useRef(null);

    return (
        <DataProvider
            fetchFun={api.fetchAuditorManagersWithAuditorCompanyAndUser}
            fetchArgs={[]}
            query={{
                order_by: "user_surname" as keyof AuditorManagerWithAuditorCompanyUser,
                filter: {
                    company_name: props.companyName,
                },
            }}
        >
            {(gto) => (
                <>
                    <div className={styles.row_items}>
                        <DataProvider.Pagination gto={gto} />
                    </div>
                    <div className={styles.table_wrapper}>
                        <DataList
                            gto={gto}
                            keyGenerator={(e) => e.id}
                            labelGenerator={{
                                surname: {
                                    label: (e) => e.user.surname,
                                    column: "user_surname",
                                    filter: { key: "user_surname", type: "string" },
                                },
                                name: {
                                    label: (e) => e.user.name,
                                    column: "user_name",
                                    filter: { key: "user_name", type: "string" },
                                },
                                coName: {
                                    label: (e) => e.user.company.company_name,
                                    column: "company_name",
                                    filter: { key: "company_name", type: "string" },
                                },
                                email: {
                                    label: (e) => e.user.email,
                                    column: "user_email",
                                },
                                phone: {
                                    label: (e) => e.user.phone_prefix + " " + e.user.phone,
                                    column: "user_phone",
                                    filter: { key: "user_phone", type: "string" },
                                },
                                login: {
                                    label: (e) => e.user.login,
                                    column: "user_login",
                                    filter: { key: "user_login", type: "string" },
                                },
                            }}
                            defaultOrder="user_surname"
                            tableWrapperElement={<table className={styles.table} />}
                            headerElement={
                                <DataList.Header
                                    gto={gto}
                                    translation={props.ht}
                                    headerElement={<thead className={styles.thead} />}
                                    labelElement={<th scope="col" className={styles.th} />}
                                    filterElement={<input className={styles.input} />}
                                    filterRef={filterRef}
                                    companyFilterValue={props.companyName}
                                />
                            }
                            dataRowElement={<tr className={styles.tr} />}
                            dataLabelElement={<td className={styles.td} />}
                        >
                            {(data, expand, key, reloadOneRow) => (
                                <tr className={styles.tr_exp} key={key}>
                                    <CSSTransition
                                        in={expand}
                                        nodeRef={nodeRef}
                                        timeout={300}
                                        classNames={{
                                            enter: styles.csstrEnter,
                                            enterActive: styles.csstrEnterActive,
                                            exit: styles.csstrExit,
                                            exitActive: styles.csstrExitActive,
                                        }}
                                        unmountOnExit
                                        appear
                                    >
                                        <td ref={nodeRef} className={styles.td_exp} colSpan={6}>
                                            <div className={styles.td_exp_content}>
                                                <div className={styles.td_exp_heading}>
                                                    <SecondaryRow
                                                        data={data}
                                                        mode={Mode.AuditorMngr}
                                                        reload={reloadOneRow}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </CSSTransition>
                                </tr>
                            )}
                        </DataList>
                    </div>
                </>
            )}
        </DataProvider>
    );
};

const DispatcherProvider = (props: { ht: any; ft: any; companyName: string }) => {
    const filterRef = useRef({
        surname: "",
        company_name: props.companyName,
    } as DispatcherWithCompanyFilter);

    // eslint-disable-next-line
    const nodeRef = useRef(null);

    return (
        <DataProvider
            fetchFun={api.fetchDispatchersWithDispatcherCompanyAndUser}
            fetchArgs={[]}
            query={{
                order_by: "user_surname" as keyof DispatcherWithDispatcherCompanyUser,
                filter: {
                    company_name: props.companyName,
                },
            }}
        >
            {(gto) => (
                <>
                    <div className={styles.row_items}>
                        <DataProvider.Pagination gto={gto} />
                    </div>
                    <div className={styles.table_wrapper}>
                        <DataList
                            gto={gto}
                            keyGenerator={(e) => e.id}
                            labelGenerator={{
                                surname: {
                                    label: (e) => e.user.surname,
                                    column: "user_surname",
                                    filter: { key: "user_surname", type: "string" },
                                },
                                name: {
                                    label: (e) => e.user.name,
                                    column: "user_name",
                                    filter: { key: "user_name", type: "string" },
                                },
                                coName: {
                                    label: (e) => e.user.company.company_name,
                                    column: "company_name",
                                    filter: { key: "company_name", type: "string" },
                                },
                                email: {
                                    label: (e) => e.user.email,
                                    column: "user_email",
                                },
                                phone: {
                                    label: (e) => e.user.phone_prefix + " " + e.user.phone,
                                    column: "user_phone",
                                    filter: { key: "user_phone", type: "string" },
                                },
                                login: {
                                    label: (e) => e.user.login,
                                    column: "user_login",
                                    filter: { key: "user_login", type: "string" },
                                },
                            }}
                            defaultOrder="user_surname"
                            tableWrapperElement={<table className={styles.table} />}
                            headerElement={
                                <DataList.Header
                                    gto={gto}
                                    translation={props.ht}
                                    headerElement={<thead className={styles.thead} />}
                                    labelElement={<th scope="col" className={styles.th} />}
                                    filterElement={<input className={styles.input} />}
                                    filterRef={filterRef}
                                    companyFilterValue={props.companyName}
                                />
                            }
                            dataRowElement={<tr className={styles.tr} />}
                            dataLabelElement={<td className={styles.td} />}
                        >
                            {(data, expand, key, reloadOneRow) => (
                                <tr className={styles.tr_exp} key={key}>
                                    <CSSTransition
                                        in={expand}
                                        nodeRef={nodeRef}
                                        timeout={300}
                                        classNames={{
                                            enter: styles.csstrEnter,
                                            enterActive: styles.csstrEnterActive,
                                            exit: styles.csstrExit,
                                            exitActive: styles.csstrExitActive,
                                        }}
                                        unmountOnExit
                                        appear
                                    >
                                        <td ref={nodeRef} className={styles.td_exp} colSpan={6}>
                                            <div className={styles.td_exp_content}>
                                                <div className={styles.td_exp_heading}>
                                                    <SecondaryRow
                                                        data={data}
                                                        mode={Mode.Dispatcher}
                                                        reload={reloadOneRow}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </CSSTransition>
                                </tr>
                            )}
                        </DataList>
                    </div>
                </>
            )}
        </DataProvider>
    );
};

export const Users = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { t } = useTranslation("adminView", { keyPrefix: "users" });
    const headerTranslation = useTranslation("adminView", {
        keyPrefix: "users.data",
    });
    const filterTranslation = useTranslation("adminView", {
        keyPrefix: "users.filters",
    });

    // Wyświetlanie userów z konkretną rolą
    const [tableMode, setTableMode] = useState<Mode>(() => {
        return location.state?.mode ?? Mode.Auditor;
    });

    // Po kliknięciu na nowa firma
    const handleNew = (mode: Mode) => {
        navigate("/admin/register", {
            state: {
                id: mode === Mode.Dispatcher ? "3" : mode === Mode.AuditorMngr ? "2" : "1",
            },
        });
    };

    const resolveDataProvider = (mode: Mode) => {
        if (mode === Mode.Auditor)
            return (
                <AuditorProvider
                    ft={filterTranslation.t}
                    ht={headerTranslation.t}
                    companyName={location?.state?.companyName ?? ""}
                />
            );
        else if (mode === Mode.AuditorMngr)
            return (
                <AuditorManagerProvider
                    ft={filterTranslation.t}
                    ht={headerTranslation.t}
                    companyName={location?.state?.companyName ?? ""}
                />
            );
        else
            return (
                <DispatcherProvider
                    ft={filterTranslation.t}
                    ht={headerTranslation.t}
                    companyName={location?.state?.companyName ?? ""}
                />
            );
    };

    return (
        <>
            <TitleProvider title={`${t("title")} | Raily Marketplace`} />
            <div className={styles.content}>
                <div className={styles.row}>
                    <h1> {t("title")} </h1>
                </div>

                <div className={styles.row}>
                    <div className={styles.row_items}>
                        <button
                            type="button"
                            className={styles.button}
                            id={tableMode === Mode.Auditor ? styles.button_active : ""}
                            onClick={(e) => {
                                if (tableMode !== Mode.Auditor) {
                                    setTableMode(Mode.Auditor);
                                }
                            }}
                        >
                            {t("tabs.auditors")}
                        </button>
                        <button
                            type="button"
                            className={styles.button}
                            id={tableMode === Mode.AuditorMngr ? styles.button_active : ""}
                            onClick={(e) => {
                                if (tableMode !== Mode.AuditorMngr) {
                                    setTableMode(Mode.AuditorMngr);
                                }
                            }}
                        >
                            {t("tabs.auditorManagers")}
                        </button>
                        <button
                            type="button"
                            className={styles.button}
                            id={tableMode === Mode.Dispatcher ? styles.button_active : ""}
                            onClick={(e) => {
                                if (tableMode !== Mode.Dispatcher) {
                                    setTableMode(Mode.Dispatcher);
                                }
                            }}
                        >
                            {t("tabs.dispatchers")}
                        </button>
                    </div>
                    <div className={styles.row_items}>
                        <button onClick={() => handleNew(Mode.Auditor)} type="button" className={styles.button}>
                            {t("handles.newAuditor")}
                        </button>
                        <button onClick={() => handleNew(Mode.AuditorMngr)} type="button" className={styles.button}>
                            {t("handles.newManager")}
                        </button>
                        <button onClick={() => handleNew(Mode.Dispatcher)} type="button" className={styles.button}>
                            {t("handles.newDispatcher")}
                        </button>
                    </div>
                </div>
                {resolveDataProvider(tableMode)}
            </div>
        </>
    );
};

export default Users;
