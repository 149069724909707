import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { routesHelper } from "routes/routesHelper";
import useAppContext from "context/useContext";
import useAuthSessionFlow from "commons/hooks/useAuthSessionFlow";
import { useEffect } from "react";
import ProtectedRoute from "routes/ProtectedRoute";

export default function App() {
  const { isUserLoggedIn, isTokenRenewPending } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  useAuthSessionFlow();

  useEffect(() => {
    const isCurrentRouteOneOfAuthRoutes = Object.values(routesHelper.getAuthRoutes())
      .map((route) => route.path)
      .includes(location.pathname);

    if (!isUserLoggedIn && !isTokenRenewPending && !isCurrentRouteOneOfAuthRoutes) {
      navigate(routesHelper.sighInPath);
      return;
    }

    if (isUserLoggedIn && !isTokenRenewPending && isCurrentRouteOneOfAuthRoutes) {
      navigate("/");
      return;
    }
    // eslint-disable-next-line
  }, [isUserLoggedIn, isTokenRenewPending]);

  return (
    <Routes>
      {routesHelper.getRoutes().map((route) => {
        return <Route key={route.path} path={route.path} element={route.element} />;
      })}
      <Route key={"*"} path="*" element={<ProtectedRoute aspect="none" />} />
    </Routes>
  );
}
