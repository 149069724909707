import FormValidationResult from "./form-validation-result";
import Joi, { AnySchema, Schema } from "joi";
import translationsHelper from "languages/translations.helper";

const BASE_NAME_PATTERN = /^[\w\-,.' ]*$/;
const LOGIN_PATTERN = /^[A-Za-z0-9]+([_.]?[A-Za-z0-9]+)*$/;

const getValidationMessageTemplates = (): { [k: string]: string } => {
  return translationsHelper.getValidationErrorTranslations().joiErrorTemplates;
};

const getValidationCustomMessages = (): { [k: string]: string } => {
  const validationMessages = translationsHelper.getValidationErrorTranslations();
  const { joiErrorTemplates, ...rest } = validationMessages;
  return rest;
};

const defaultValidationResilt: FormValidationResult = {
  isValid: true,
  errorMessage: "",
};

const ok = (): FormValidationResult => {
  return {
    isValid: true,
    errorMessage: "",
  };
};

const bad = (errorMessage: string): FormValidationResult => {
  return {
    isValid: false,
    errorMessage: errorMessage,
  };
};

const createSchema = (joiSchema: AnySchema): Schema => {
  const messageTemplates = getValidationMessageTemplates();

  return joiSchema.messages(messageTemplates);
};

const validate = (value: any, validationSchema: AnySchema): FormValidationResult => {
  const validationError = createSchema(validationSchema).validate(value).error?.message;

  if (validationError) {
    return bad(validationError);
  }
  return ok();
};

const validatePassword = (value: string, required?: boolean): FormValidationResult => {
  const schema = Joi.string().min(8).max(50);

  return validate(value, required ? schema.required() : schema.allow(""));
};

const validatePasswordConfirm = (value: string, confirmValue: string): FormValidationResult => {
  const validateAsPasswordResult = validatePassword(confirmValue);

  if (!validateAsPasswordResult.isValid) {
    return validateAsPasswordResult;
  }

  const passwordConfirmSchema = Joi.string().valid(value);

  const validationResult = validate(confirmValue, !!value ? passwordConfirmSchema.required() : passwordConfirmSchema);

  if (validationResult.isValid) {
    return validationResult;
  }

  const customMessages = getValidationCustomMessages();

  validationResult.errorMessage = customMessages.passwordRepeatValidationError;
  return validationResult;
};

const formValidationService = {
  validate,
  getValidationCustomMessages,
  defaultValidationResilt,
  bad,
  ok,
  validatePassword,
  validatePasswordConfirm,
  BASE_NAME_PATTERN,
  LOGIN_PATTERN,
};

export default formValidationService;
