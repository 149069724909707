import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import api from "api";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import Modal from "commons/Modal";
import useForm from "commons/form/useForm";
import serviceFormFactory from "./form/service-form.factory";
import serviceAddRequestFactory from "./service-add-request.factory";
import styles from "./Service.module.css";
import Button from "commons/Button/Button";

export default function Service() {
  const { t } = useTranslation("adminView", { keyPrefix: "register" });
  const { handleErrors } = useErrorHandling();
  const [alert, setAlert] = useState(false);

  const serviceNameInputRef = useRef<HTMLInputElement>(null);

  const form = useForm({
    emptyValues: serviceFormFactory.getDefaultData(),
    validationDefinition: serviceFormFactory.getFormValidationDefiniton(),
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!form.validateAll()) {
      serviceNameInputRef.current?.setCustomValidity(form.validationResults["serviceName"].errorMessage);

      event.currentTarget.reportValidity();
      return;
    }

    const request = serviceAddRequestFactory.create(form.values);

    api.registerService(request).then((res) => {
      if (handleErrors(res.error).ok) {
        form.restore();
        setAlert(true);
      } else {
        setAlert(false);
      }
    });
  };

  const serviceNameLabel = t("service.name");

  return (
    <>
      {alert && (
        <Modal
          action1={() => {
            setAlert(false);
          }}
          title={t("modal.registerTitle")}
        />
      )}
      <form onSubmit={handleSubmit} autoComplete={"off"}>
        <div className={styles.wrapper}>
          <div className={styles.single_input}>
            <input
              title={serviceNameLabel}
              placeholder={serviceNameLabel}
              ref={serviceNameInputRef}
              className={styles.input}
              onChange={(event) => {
                const result = form.setAndValidate("serviceName", event.currentTarget.value);
                event.currentTarget.setCustomValidity(result.errorMessage);
              }}
              onFocus={() => {
                form.flag("serviceName");
              }}
              onBlur={(event) => {
                if (form.isFlagged("serviceName")) {
                  const result = form.validate("serviceName");
                  event.currentTarget.setCustomValidity(result.errorMessage);
                  event.currentTarget.reportValidity();
                  form.unflag("serviceName");
                }
              }}
            />
          </div>

          <Button type="submit" label={t("service.register")} />
        </div>
      </form>
    </>
  );
}
