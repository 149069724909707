import api from "api";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import useAppContext from "context/useContext";
import { useErrorHandling } from "./useErrorHandling";
import { isEqual } from "lodash";
import { useEffect } from "react";

export const checkTokenExpirationIntervalTime = 10000; // 10s
export const silentRenewBeforeExpirationTime = 60000; // 60s

const useAuthSessionFlow = () => {
  const { isUserLoggedIn, setIsUserLoggedIn, setIsTokenRenewPending, user, setUser } = useAppContext();
  const { handleErrors } = useErrorHandling();

  const checkShouldRenewToken = (): boolean => {
    const accessTokenExpirationTime = sessionStorageHelper.getAccessTokenExpirationTime();

    if (!accessTokenExpirationTime) {
      return true;
    }

    const currentTime = new Date().getTime();

    const shouldRenewToken = accessTokenExpirationTime < currentTime + silentRenewBeforeExpirationTime;
    return shouldRenewToken;
  };

  const renewAccessToken = () => {
    setIsTokenRenewPending(true);

    try {
      api.refreshToken().then((response) => {
        if (handleErrors(response.error).ok) {
          sessionStorageHelper.setStorageOnLogin(response.data.access_token);
          setIsUserLoggedIn(true);

          const userProfile = sessionStorageHelper.getUserProfile();
          if (isEqual(user, userProfile)) {
            return;
          }

          setUser(userProfile);
        } else {
          setIsUserLoggedIn(false);
        }
      });
    } catch {
      setIsUserLoggedIn(false);
    } finally {
      setIsTokenRenewPending(false);
    }
  };

  const accessTokenChecker = () => {
    const shouldRenewToken = checkShouldRenewToken();

    if (shouldRenewToken) {
      renewAccessToken();
      return;
    }

    setIsTokenRenewPending(false);
  };

  useEffect(() => {
    accessTokenChecker();
    if (!isUserLoggedIn) {
      return;
    }

    const checkAccessTokenExpirationInterval = setInterval(accessTokenChecker, checkTokenExpirationIntervalTime);

    return () => {
      clearInterval(checkAccessTokenExpirationInterval);
    };
    // eslint-disable-next-line
  }, [isUserLoggedIn]);
};

export default useAuthSessionFlow;
