import { ReactNode } from "react";
import { IconDefinition, faFolderOpen, faTrain } from "@fortawesome/free-solid-svg-icons";
import {
  faHouse,
  faAddressBook,
  faUsers,
  faScroll,
  faBriefcase,
  faUserPlus,
  faPlus,
  faCircleUser,
  faBoxArchive,
} from "@fortawesome/free-solid-svg-icons";

import Login from "routes/Login";
import ProtectedRoute from "routes/ProtectedRoute";

// Admin
import AdminView from "modules/AdminView";
import Register from "modules/AdminView/Register";
import Home from "modules/AdminView/Home";
import Users from "modules/AdminView/Listings/Users";
import Companies from "modules/AdminView/Listings/Companies";
import Contracts from "modules/AdminView/Listings/Contracts";

// Commons
import UserProfile from "modules/UserProfile";

// Dispatcher
import DispatcherView from "modules/DispatcherView";
import { default as DispatcherCommissions } from "modules/DispatcherView/Commissions";
import { default as DispatcherNewCommission } from "modules/DispatcherView/NewCommission";
import { default as DispatcherContracts } from "modules/DispatcherView/Contracts";
import { default as DispatcherCommissionsInactive } from "modules/DispatcherView/Commissions/CommissionsInactive";

// Operator
import AuditorManagerView from "modules/AuditorManagerView";
import { default as AuditorManagerCommissions } from "modules/AuditorManagerView/Commissions";
import { default as AuditorManagerAuditors } from "modules/AuditorManagerView//Auditors";
import { default as AuditorManagerEmployeeForm } from "modules/AuditorManagerView/EmployeeForm";
import { default as AuditorManagerContracts } from "modules/AuditorManagerView/Contracts";
import { default as AuditorManagerCommissionsInactive } from "modules/AuditorManagerView/Commissions/CommissionsInactive";

// Employee
import AuditorView from "modules/AuditorView";
import { default as AuditorCommissions } from "modules/AuditorView/Commissions";
import { default as AuditorInactiveCommissions } from "modules/AuditorView/Commissions/CommissionsInactive";
import AuditorEditComponent from "modules/AdminView/UserEditForms/auditor/AuditorEditComponent";
import DispatcherEditComponent from "modules/AdminView/UserEditForms/dispatcher/DispatcherEditComponent";
import OperatorEditComponent from "modules/AdminView/UserEditForms/operator/OperatorEditComponent";

type Route = {
  name: string;
  path: string;
  element: ReactNode;
  icon: IconDefinition;
};

const sighInPath = `/sign-in`;

const authRoutes: Route[] = [
  {
    name: "",
    path: sighInPath,
    element: <Login />,
    icon: faPlus,
  },
];

const getAuthRoutes = (): Route[] => {
  return authRoutes;
};

const getRoutes = (): Route[] => {
  return authRoutes.concat(GetAdminRoutes(), GetDispatcherRoutes(), GetAuditorRoutes(), GetAuditorManagerRoutes());
};

const getAdminSidenavRoutes = (): Route[] => {
  const adminRoutes: Route[] = [
    {
      name: "routes.profile",
      path: "/admin/my-profile",
      element: (
        <ProtectedRoute aspect="admin">
          <AdminView>
            <UserProfile />
          </AdminView>
        </ProtectedRoute>
      ),
      icon: faCircleUser,
    },
    {
      name: "routes.admin.home",
      path: `/admin/`,
      element: (
        <ProtectedRoute aspect="admin">
          <AdminView>
            <Home />
          </AdminView>
        </ProtectedRoute>
      ),
      icon: faHouse,
    },
    {
      name: "routes.admin.companies",
      path: `/admin/companies`,
      element: (
        <ProtectedRoute aspect="admin">
          <AdminView>
            <Companies />
          </AdminView>
        </ProtectedRoute>
      ),
      icon: faAddressBook,
    },
    {
      name: "routes.admin.contracts",
      path: `/admin/contracts`,
      element: (
        <ProtectedRoute aspect="admin">
          <AdminView>
            <Contracts />
          </AdminView>
        </ProtectedRoute>
      ),
      icon: faScroll,
    },
    {
      name: "routes.admin.users",
      path: `/admin/users`,
      element: (
        <ProtectedRoute aspect="admin">
          <AdminView>
            <Users />
          </AdminView>
        </ProtectedRoute>
      ),
      icon: faUsers,
    },
    {
      name: "routes.admin.register",
      path: `/admin/register`,
      element: (
        <ProtectedRoute aspect="admin">
          <AdminView>
            <Register />
          </AdminView>
        </ProtectedRoute>
      ),
      icon: faUserPlus,
    },
  ];

  return adminRoutes;
};

const GetAdminRoutes = (): Route[] => {
  return [
    ...getAdminSidenavRoutes(),
    {
      name: "",
      path: "/admin/auditor/edit",
      element: (
        <ProtectedRoute aspect="admin">
          <AdminView>
            <AuditorEditComponent />
          </AdminView>
        </ProtectedRoute>
      ),
      icon: faUserPlus,
    },
    {
      name: "",
      path: "/admin/operator/edit",
      element: (
        <ProtectedRoute aspect="admin">
          <AdminView>
            <OperatorEditComponent />
          </AdminView>
        </ProtectedRoute>
      ),
      icon: faUserPlus,
    },
    {
      name: "",
      path: "/admin/dispatcher/edit",
      element: (
        <ProtectedRoute aspect="admin">
          <AdminView>
            <DispatcherEditComponent />
          </AdminView>
        </ProtectedRoute>
      ),
      icon: faUserPlus,
    },
  ];
};

const GetDispatcherRoutes = (): Route[] => {
  const dispatcherRoutes: Route[] = [
    {
      name: "routes.profile",
      path: `/dispatcher/my-profile`,
      element: (
        <ProtectedRoute aspect="dispatcher">
          <DispatcherView>
            <UserProfile />
          </DispatcherView>
        </ProtectedRoute>
      ),
      icon: faCircleUser,
    },
    {
      name: "routes.dispatcher.commissions",
      path: `/dispatcher/`,
      element: (
        <ProtectedRoute aspect="dispatcher">
          <DispatcherView>
            <DispatcherCommissions />
          </DispatcherView>
        </ProtectedRoute>
      ),
      icon: faTrain,
    },
    {
      name: "routes.dispatcher.commissions_inactive",
      path: "/dispatcher/commissions/inactive",
      element: (
        <ProtectedRoute aspect="dispatcher">
          <DispatcherView>
            <DispatcherCommissionsInactive />
          </DispatcherView>
        </ProtectedRoute>
      ),
      icon: faBoxArchive,
    },
    {
      name: "routes.auditorManager.commissions",
      path: `/dispatcher/offer`,
      element: (
        <ProtectedRoute aspect="operator">
          <DispatcherView>
            <AuditorManagerCommissions />
          </DispatcherView>
        </ProtectedRoute>
      ),
      icon: faBriefcase,
    },
    {
      name: "routes.auditorManager.commissions_inactive",
      path: "/dispatcher/offers/inactive",
      element: (
        <ProtectedRoute aspect="operator">
          <DispatcherView>
            <AuditorManagerCommissionsInactive />
          </DispatcherView>
        </ProtectedRoute>
      ),
      icon: faFolderOpen,
    },
    {
      name: "routes.dispatcher.contracts",
      path: `/dispatcher/contracts`,
      element: (
        <ProtectedRoute aspect="dispatcher">
          <DispatcherView>
            <DispatcherContracts />
          </DispatcherView>
        </ProtectedRoute>
      ),
      icon: faScroll,
    },
    {
      name: "routes.dispatcher.new-commission",
      path: `/dispatcher/new-commission`,
      element: (
        <ProtectedRoute aspect="dispatcher">
          <DispatcherView>
            <DispatcherNewCommission />
          </DispatcherView>
        </ProtectedRoute>
      ),
      icon: faPlus,
    },
    {
      name: "routes.auditorManager.auditors",
      path: `/dispatcher/auditors`,
      element: (
        <ProtectedRoute aspect="operator">
          <DispatcherView>
            <AuditorManagerAuditors />
          </DispatcherView>
        </ProtectedRoute>
      ),
      icon: faUsers,
    },
    {
      name: "routes.auditorManager.employee-form",
      path: `/dispatcher/employee-form`,
      element: (
        <ProtectedRoute aspect="operator">
          <DispatcherView>
            <AuditorManagerEmployeeForm />
          </DispatcherView>
        </ProtectedRoute>
      ),
      icon: faUserPlus,
    },
  ];

  return dispatcherRoutes;
};

const GetDispatcherBasicRoutes = (): Route[] => {
  const dispatcherBasicRoutes: Route[] = [
    {
      name: "routes.profile",
      path: `/dispatcher/my-profile`,
      element: (
        <ProtectedRoute aspect="dispatcher">
          <DispatcherView>
            <UserProfile />
          </DispatcherView>
        </ProtectedRoute>
      ),
      icon: faCircleUser,
    },
    {
      name: "routes.dispatcher.commissions",
      path: `/dispatcher/`,
      element: (
        <ProtectedRoute aspect="dispatcher">
          <DispatcherView>
            <DispatcherCommissions />
          </DispatcherView>
        </ProtectedRoute>
      ),
      icon: faTrain,
    },
    {
      name: "routes.dispatcher.commissions_inactive",
      path: "/dispatcher/commissions/inactive",
      element: (
        <ProtectedRoute aspect="dispatcher">
          <DispatcherView>
            <DispatcherCommissionsInactive />
          </DispatcherView>
        </ProtectedRoute>
      ),
      icon: faBoxArchive,
    },
    {
      name: "routes.dispatcher.contracts",
      path: `/dispatcher/contracts`,
      element: (
        <ProtectedRoute aspect="dispatcher">
          <DispatcherView>
            <DispatcherContracts />
          </DispatcherView>
        </ProtectedRoute>
      ),
      icon: faScroll,
    },
    {
      name: "routes.dispatcher.new-commission",
      path: `/dispatcher/new-commission`,
      element: (
        <ProtectedRoute aspect="dispatcher">
          <DispatcherView>
            <DispatcherNewCommission />
          </DispatcherView>
        </ProtectedRoute>
      ),
      icon: faPlus,
    },
  ];
  return dispatcherBasicRoutes;
};

const GetAuditorManagerRoutes = (): Route[] => {
  const auditorManagerRoutes: Route[] = [
    {
      name: "routes.profile",
      path: `/operator/my-profile`,
      element: (
        <ProtectedRoute aspect="operator">
          <AuditorManagerView>
            <UserProfile />
          </AuditorManagerView>
        </ProtectedRoute>
      ),
      icon: faCircleUser,
    },
    {
      name: "routes.auditorManager.commissions",
      path: `/operator/`,
      element: (
        <ProtectedRoute aspect="operator">
          <AuditorManagerView>
            <AuditorManagerCommissions />
          </AuditorManagerView>
        </ProtectedRoute>
      ),
      icon: faBriefcase,
    },
    {
      name: "routes.auditorManager.commissions_inactive",
      path: "/operator/commissions/inactive",
      element: (
        <ProtectedRoute aspect="operator">
          <AuditorManagerView>
            <AuditorManagerCommissionsInactive />
          </AuditorManagerView>
        </ProtectedRoute>
      ),
      icon: faBoxArchive,
    },
    {
      name: "routes.auditorManager.contracts",
      path: `/operator/contracts`,
      element: (
        <ProtectedRoute aspect="operator">
          <AuditorManagerView>
            <AuditorManagerContracts />
          </AuditorManagerView>
        </ProtectedRoute>
      ),
      icon: faScroll,
    },
    {
      name: "routes.auditorManager.auditors",
      path: `/operator/auditors`,
      element: (
        <ProtectedRoute aspect="operator">
          <AuditorManagerView>
            <AuditorManagerAuditors />
          </AuditorManagerView>
        </ProtectedRoute>
      ),
      icon: faUsers,
    },
    {
      name: "routes.auditorManager.employee-form",
      path: `/operator/employee-form`,
      element: (
        <ProtectedRoute aspect="operator">
          <AuditorManagerView>
            <AuditorManagerEmployeeForm />
          </AuditorManagerView>
        </ProtectedRoute>
      ),
      icon: faUserPlus,
    },
  ];

  return auditorManagerRoutes;
};

const GetAuditorRoutes = (): Route[] => {
  const auditorRoutes: Route[] = [
    {
      name: "routes.profile",
      path: `/worker/my-profile`,
      element: (
        <ProtectedRoute aspect="worker">
          <AuditorView>
            <UserProfile />
          </AuditorView>
        </ProtectedRoute>
      ),
      icon: faCircleUser,
    },
    {
      name: "routes.auditor.commissions",
      path: `/worker/`,
      element: (
        <ProtectedRoute aspect="worker">
          <AuditorView>
            <AuditorCommissions />
          </AuditorView>
        </ProtectedRoute>
      ),
      icon: faBriefcase,
    },
    {
      name: "routes.auditor.commissions_inactive",
      path: `/worker/inactive`,
      element: (
        <ProtectedRoute aspect="worker">
          <AuditorView>
            <AuditorInactiveCommissions />
          </AuditorView>
        </ProtectedRoute>
      ),
      icon: faBoxArchive,
    },
  ];

  return auditorRoutes;
};

const routesHelper = {
  sighInPath,
  getAuthRoutes,
  getRoutes,
  getAdminRoutes: getAdminSidenavRoutes,
  getDispatcherRoutes: GetDispatcherRoutes,
  getDispatcherBasicRoutes: GetDispatcherBasicRoutes,
  getAuditorManagerRoutes: GetAuditorManagerRoutes,
  getAuditorRoutes: GetAuditorRoutes,
};

export { routesHelper };
