import { FormValidationDefinition } from "commons/form/useForm";
import ServiceFormData from "./service-form-data";
import registerValidationService from "../../common/register-validation-service";

const getDefaultData = (): ServiceFormData => {
  return {
    serviceName: "",
  };
};

const getFormValidationDefiniton = (): FormValidationDefinition<ServiceFormData> => {
  return {
    serviceName: (values) => registerValidationService.validateServiceName(values.serviceName),
  };
};

const serviceFormFactory = {
  getDefaultData,
  getFormValidationDefiniton,
};

export default serviceFormFactory;
