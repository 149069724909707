import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Login.module.css";
import { BeatLoader } from "react-spinners";
import api from "api";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import LanguageMenu from "commons/LanguageMenu";
import { LoginCredentials } from "api/entities/auth";
const railyLogo = require("assets/marketplace_logo.png");

export default function Login() {
  const navigate = useNavigate();
  const { t } = useTranslation("login");
  const { handleErrors } = useErrorHandling();
  const [showLoginScreen, setShowLoginScreen] = useState(false);

  const [state, setState] = useState({
    login: "",
    password: "",
    errorMsg: "",
  });
  const [loading, setLoading] = useState(false);

  // Refs for main input fields and error label
  const userRef = useRef<HTMLInputElement>(null);
  const pwdRef = useRef<HTMLInputElement>(null);
  const errRef = useRef<HTMLDivElement>(null);

  // Sets document title and focuses user on username input
  useEffect(() => {
    document.title = t("title");

    if (userRef.current) {
      userRef.current.focus();
    }
  }, [t]);

  useEffect(() => {
    if (!!sessionStorageHelper.getToken()) {
      navigate(`/${sessionStorageHelper.getAspects()[0]}/`, { replace: false });
    }
    setShowLoginScreen(true);
  }, [navigate]);

  // On input change -> clear error refs and msg
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value: string = event.currentTarget.value;
    setState({ ...state, [event.currentTarget.id]: value, errorMsg: "" });
    if (userRef.current) userRef.current.style.border = "";
    if (pwdRef.current) pwdRef.current.style.border = "";
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Check for empty fields
    if (state.login === "" && userRef.current !== null) {
      userRef.current.style.border = "1px solid #df4f5d";
      setState({ ...state, errorMsg: t("missingCredentials") });
    } else if (state.password === "" && pwdRef.current !== null) {
      pwdRef.current.style.border = "1px solid #df4f5d";
      setState({ ...state, errorMsg: t("missingCredentials") });
    } else {
      setLoading(true);
      try {
        const loginRequest: LoginCredentials = {
          login: state.login,
          password: state.password,
        };
        api
          .login(loginRequest)
          .then((res) => {
            const x = handleErrors(res.error, false);
            if (x.ok) {
              sessionStorageHelper.setStorageOnLogin(res.data.access_token);
              navigate(`/${sessionStorageHelper.getAspects()[0]}/`, { replace: false });
            } else {
              setState({ ...state, errorMsg: t("invalidCredentials") });
            }
          })
          .then(() => setLoading(false));
      } catch (err) {
        console.log(err);
        setState({ ...state, errorMsg: "Error" });
        errRef.current?.focus();
      }
    }
  };

  if (showLoginScreen)
    return (
      <>
        <LanguageMenu type="left" />

        <div className={styles.signin}>
          <form onSubmit={handleSubmit}>
            <div className={styles.content}>
              <div className={styles.logo_wrapper}>
                <img className={styles.logo} src={railyLogo} alt="Logo" />
              </div>

              <div className={styles.header}>{t("login")}</div>

              <div className={styles.form_field}>
                <label htmlFor="login" className={styles.form_label}>
                  {t("username")} *
                </label>
                <div className={styles.form_input_wrapper}>
                  <input
                    id="login"
                    className={styles.form_input}
                    type="text"
                    ref={userRef}
                    value={state.login}
                    onChange={handleChange}
                    placeholder={t("username") ?? ""}
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className={styles.form_field}>
                <label htmlFor="password" className={styles.form_label}>
                  {t("password")} *
                </label>
                <div className={styles.form_input_wrapper}>
                  <input
                    id="password"
                    className={styles.form_input}
                    type="password"
                    ref={pwdRef}
                    value={state.password}
                    onChange={handleChange}
                    placeholder={t("password") ?? ""}
                    autoComplete="off"
                  />
                </div>
              </div>

              <div ref={errRef} className={styles.errmsg} aria-live="assertive">
                {state.errorMsg !== "" && <span>{state.errorMsg}</span>}
              </div>

              {!loading && (
                <button className={styles.submit_button} type="submit">
                  {t("submit")}
                </button>
              )}

              {loading && (
                <div className={styles.spinner_button}>
                  <BeatLoader color="#ffffff" />
                </div>
              )}
            </div>
          </form>
        </div>
      </>
    );
  else {
    return <></>;
  }
}
