import { UserProfile } from "api/entities/auth";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import i18n from "i18n";
import { createContext, useEffect, useState } from "react";

interface props {
  children?: React.ReactNode;
}
export type ContextType = {
  refetchCounters: () => void;
  shouldRefetchCounters: boolean;
  setShouldRefetchCounters: (value: boolean) => void;
  selectedAppLanguage: string;
  setAppLanguage: (language: string) => void;
  isUserLoggedIn: boolean;
  setIsUserLoggedIn: (isUserLoggedIn: boolean) => void;
  user: UserProfile | undefined;
  setUser: (user: UserProfile | undefined) => void;
  isTokenRenewPending: boolean;
  setIsTokenRenewPending: (isTokenRenewPending: boolean) => void;
};
const AuthContext = createContext<ContextType | null>(null);

export function ContextProvider(props: props) {
  const [selectedAppLanguage, setSelectedAppLanguage] = useState(
    sessionStorageHelper.getLanguage() ?? i18n.languages[0],
  );
  const [shouldRefetchCounters, setShouldRefetchCounters] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(() => {
    return sessionStorageHelper.checkIsAccessTokenValid();
  });
  const [user, setUser] = useState(() => {
    return sessionStorageHelper.getUserProfile();
  });
  const [isTokenRenewPending, setIsTokenRenewPending] = useState(false);

  const refetchCounters = () => {
    setShouldRefetchCounters(true);
  };

  const setAppLanguage = (language: string) => {
    i18n.changeLanguage(language);
    sessionStorageHelper.setLanguage(language);
    setSelectedAppLanguage(language);
  };

  useEffect(() => {
    document.documentElement.lang = selectedAppLanguage;
  }, [selectedAppLanguage]);

  return (
    <AuthContext.Provider
      value={{
        selectedAppLanguage,
        setAppLanguage,
        refetchCounters,
        setShouldRefetchCounters,
        shouldRefetchCounters,
        isUserLoggedIn,
        setIsUserLoggedIn,
        user,
        setUser,
        isTokenRenewPending,
        setIsTokenRenewPending,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
