import ListInput, { ListType } from "commons/Registration/ListInput/ListInput";
import { newOfferService } from "modules/AuditorManagerView/Commissions/ExpandedRow/newOfferService";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import { CommuteMode } from "api/entities/enums";
import Spinner from "commons/Spinner/Spinner";
import MapComponent, { AddressForMap } from "commons/Map/MapComponent";
import { CommissionForAuditorCompany } from "api/entities/commission";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { Dispatch, SetStateAction, useEffect, useMemo, useState, useRef } from "react";
import { OfferCreate, OfferEdit } from "api/entities/bases/offer";
import api from "api";
import styles from "./NewOffer.module.css";
import Button from "commons/Button/Button";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SingleInput from "commons/Registration/SingleInput";
import Attachments from "commons/NewAttachments";
import { OfferForAuditorManager } from "api/entities/offer";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import SearchAddressResponseItem from "commons/Registration/ListInput/search-address-response";
import { nominatimURL } from "api/backend";
import useAppContext from "context/useContext";

enum TransportState {
  NONE,
  ONE_WAY,
  TWO_WAY,
}

interface OfferCreateExtended extends OfferCreate {
  commute_to?: string;
  commute_from?: string;
  currency?: string;
  internal_notes: string | null;
}

export default function NewOffer(props: {
  data: CommissionForAuditorCompany;
  expand: boolean;
  reloadOneRow: () => void;
  editMode?: boolean;
  offer?: OfferForAuditorManager;
  setEditMode?: () => void;
}) {
  const { t } = useTranslation("auditorManagerView", { keyPrefix: "commissions.extension" });
  const { handleErrors } = useErrorHandling();
  const [state, setState] = useState<OfferCreateExtended>(
    newOfferService.getDefaultFormData(sessionStorageHelper.getAuditorManagerId(), props.data.id),
  );
  const [files, setFiles] = useState<File[]>([]);
  const [transport, setTransport] = useState<{ toWork: TransportState; fromWork: TransportState }>({
    toWork: TransportState.NONE,
    fromWork: TransportState.NONE,
  });

  const [addrMode, setAddrMode] = useState(false); // false -> use the default employee address, true -> create new
  const [errMsg, setErrMsg] = useState("");
  const [empAddr, setEmpAddr] = useState<{ name: string; lat: number; lng: number } | undefined>(undefined); // Przechowuje domyslny adres pracownika
  const [price, setPrice] = useState([0, 0, 0]);
  const [isAuditorFetched, setIsAuditorFetched] = useState(false);
  const [isFetched, setIsFetched] = useState(true);
  const [isReverseFetched, setIsReverseFetched] = useState(false);
  const [addrSearchRes, setAddrSearchRes] = useState<SearchAddressResponseItem[]>([]); // for nominatim results
  const [loading, setLoading] = useState(false);
  const [newSelected, setNewSelected] = useState(false); // kiedy zostal wybrany adres z nominatim
  const [expiDate, setExpiDate] = useState("0");
  // For Csstransition
  const listRef = useRef(null);
  const { refetchCounters } = useAppContext();

  // Przy zmianie trybu adresu
  const changeAddrMode = () => {
    setPrice([0.0, 0.0, 0.0]);
    setErrMsg("");

    /* new -> default */
    if (addrMode) {
      setAddrMode(false);
      setState({
        ...state,
        addr_name: empAddr?.name,
        addr_latitude: empAddr?.lat,
        addr_longitude: empAddr?.lng,
        commute_to: undefined,
        commute_from: undefined,
      });
    } else {
      /* default -> new */
      setAddrMode(true);
      setState({
        ...state,
        addr_name: "",
        addr_latitude: undefined,
        addr_longitude: undefined,
        commute_to: undefined,
        commute_from: undefined,
      });
    }

    if (transport.toWork !== TransportState.NONE || transport.fromWork !== TransportState.NONE)
      setTransport({ toWork: TransportState.NONE, fromWork: TransportState.NONE });
  };

  useEffect(() => {
    if (!isFetched && addrMode === true && state.addr_name && state.addr_name.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        const query_params = new URLSearchParams();

        query_params.append("accept-language", "pl");
        query_params.append("q", String(state.addr_name));

        fetch(nominatimURL + "/search?" + query_params, {
          method: "GET",
        })
          .then((x) => x.json())
          .then((x) => {
            setAddrSearchRes(x);
            handleErrors(x.error);
            setIsFetched(true);
          });
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line
  }, [state.addr_name]);

  useEffect(() => {
    if (!isReverseFetched && addrMode === true && state.addr_latitude && state.addr_longitude) {
      const delayDebounceFn = setTimeout(() => {
        const query_params = new URLSearchParams();

        query_params.append("accept-language", "pl");
        query_params.append("lat", String(state.addr_latitude));
        query_params.append("lon", String(state.addr_longitude));
        query_params.append("format", "json");
        query_params.append("layer", "address");

        fetch(nominatimURL + "/reverse?" + query_params, {
          method: "GET",
        })
          .then((x) => x.json())
          .then((x) => {
            setState({ ...state, addr_name: x.display_name });
            handleErrors(x.error);
            setIsReverseFetched(true);
          });
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line
  }, [state.addr_latitude, state.addr_longitude]);

  const clearState = () => {
    setState(
      newOfferService.getDefaultFormData(sessionStorageHelper.getAuditorManagerId(), props.data.id, state.contract_id),
    );
    setTransport({ toWork: TransportState.NONE, fromWork: TransportState.NONE });
    setIsAuditorFetched(false);
    setAddrMode(false);
    setState({
      ...state,
      addr_name: undefined,
      addr_latitude: undefined,
      addr_longitude: undefined,
    });
    setEmpAddr(undefined);
    setErrMsg("");
  };

  // fetches contract for prices
  useEffect(() => {
    try {
      api
        .fetchContractByCompanies(sessionStorageHelper.getAuditorCoId(), props.data.dispatcher.user.company_id)
        .then((x) => {
          handleErrors(x.error);
          setState((s) => {
            return { ...s, contract_id: x.data.id, currency: x.data.currency };
          });
        });
    } catch (e) {
      console.log(e);
    }
  }, [props.data, handleErrors]);

  useEffect(() => {
    if (state.addr_latitude === 0.0 || state.addr_longitude === 0.0) return;
    calculatePrice();
    // eslint-disable-next-line
  }, [transport, state.addr_latitude, state.addr_longitude, state.contract_id]);

  const calculatePrice = () => {
    if (state.addr_name === "") return;
    try {
      if (state.auditor_id !== "" && state.contract_id !== "") {
        api
          .calculateOfferPrice({
            commission_id: state.commission_id,
            auditor_id: state.auditor_id,
            auditor_mngr_id: state.auditor_mngr_id,
            contract_id: state.contract_id,
            internal_notes: state.internal_notes,

            commute_mode: getCommuteMode(state.commute_to, state.commute_from),
            addr_latitude: state.addr_latitude,
            addr_longitude: state.addr_longitude,
            addr_name: state.addr_name,
          })
          .then((res) => {
            handleErrors(res.error);
            setPrice([res.data.price_service, res.data.price_to, res.data.price_from]);
          });
      } else setPrice([0, 0, 0]);
    } catch (e) {
      console.log(e);
    }
  };

  const getAuditor = (auditorId: string) => {
    try {
      api.fetchAuditorWithUser(auditorId).then((x) => {
        handleErrors(x.error);
        setEmpAddr({ name: x.data.address.name, lat: x.data.address.latitude, lng: x.data.address.longitude });
        setState({
          ...state,
          auditor_id: auditorId,
          addr_name: x.data.address.name,
          addr_latitude: x.data.address.latitude,
          addr_longitude: x.data.address.longitude,
        });
        setIsAuditorFetched(true);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    if (state.addr_name === "") {
      setErrMsg(t("errAddrName") ?? "error");
      return;
    }
    if (state.addr_latitude === 0.0 || state.addr_longitude === 0.0) {
      setErrMsg(t("errMarker") ?? "error");
      return;
    }

    if (props.editMode === true) {
      const o: OfferEdit = {
        auditor_id: state.auditor_id,
        commute_mode: getCommuteMode(state.commute_to, state.commute_from),
        addr_name: state.addr_name ?? "error",
        addr_latitude: state.addr_latitude ?? undefined,
        addr_longitude: state.addr_longitude ?? undefined,
      };
      newOfferService.handleEditSubmit(props.offer?.id ?? "", o).then((e) => {
        handleErrors(e.error);
        props.reloadOneRow();
        if (props.setEditMode) props.setEditMode();
      });
    } else {
      state.commute_mode = getCommuteMode(state.commute_to, state.commute_from);

      newOfferService.handleSubmit(state, files).then((e) => {
        handleErrors(e.error);
        props.reloadOneRow();
        refetchCounters();
      });
    }
  };

  const markers = useMemo((): AddressForMap[] => {
    const markersList: AddressForMap[] = [];

    if (props.data.address_primary.latitude !== 0.0 && props.data.address_primary.longitude !== 0.0) {
      markersList.push({
        x: props.data.address_primary.latitude,
        y: props.data.address_primary.longitude,
        name: props.data.address_primary.name,
      });
    }

    if (
      props.data.address_secondary &&
      props.data.address_secondary.latitude !== 0.0 &&
      props.data.address_secondary.longitude !== 0.0
    ) {
      markersList.push({
        x: props.data.address_secondary.latitude,
        y: props.data.address_secondary.longitude,
        name: props.data.address_secondary.name,
      });
    }

    if (
      addrMode &&
      !state.auditor_id &&
      state.addr_latitude !== 0.0 &&
      state.addr_longitude !== 0.0 &&
      state.addr_name
    ) {
      markersList.push({
        x: state.addr_latitude!,
        y: state.addr_longitude!,
        name: state.addr_name,
      });
    }

    return markersList;
  }, [
    props.data.address_primary,
    props.data.address_secondary,
    addrMode,
    state.auditor_id,
    state.addr_latitude,
    state.addr_longitude,
    state.addr_name,
  ]);

  const getCommuteMode = (ts?: string, fs?: string): CommuteMode => {
    if (fs !== undefined) {
      if (ts !== undefined) {
        return `${fs}${ts.replace("t", "T")}` as CommuteMode;
      } else {
        return fs as CommuteMode;
      }
    } else {
      if (ts !== undefined) {
        return ts as CommuteMode;
      } else {
        return CommuteMode.none;
      }
    }
  };

  const changeExpiDate = (e: React.FormEvent<HTMLSelectElement>) => {
    setExpiDate(e.currentTarget.value);

    if (e.currentTarget.value === "0") setState({ ...state, expiration_date: null });
    else {
      var date = new Date();
      date.setTime(date.getTime() + Number(e.currentTarget.value) * 3600 * 1000);
      setState({ ...state, expiration_date: date });
    }
  };

  const getFormatedDate = (date: Date) => {
    return (
      date.getDate() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getFullYear() +
      " " +
      String(date.getHours()).padStart(2, "0") +
      ":" +
      String(date.getMinutes()).padStart(2, "0")
    );
  };

  const handleChangeNotes = (event: React.FormEvent<HTMLInputElement>) => {
    setState({
      ...state,
      internal_notes: event.currentTarget.value,
    });
  };

  useEffect(() => {
    if (props.expand) {
      setState({
        ...state,
        addr_name: props.data.address_primary.name,
        addr_latitude: props.data.address_primary.latitude,
        addr_longitude: props.data.address_primary.longitude,
        commute_to: undefined,
        commute_from: undefined,
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.content_wrapper}>
      <Spinner size="80px" isFetched={!loading}>
        <div className={styles.content_new_offer}>
          <form onSubmit={handleSubmit}>
            <div className={styles.new_offer_content}>
              <div className={styles.new_offer_content_column}>
                <ListInput
                  setInputs={(id: string) => {
                    setState({ ...state, auditor_id: id });
                  }}
                  labelText={t("formData.auditor")}
                  id="auditor_name"
                  mode={ListType.AuditorByManagerId}
                  auditor_co_id={sessionStorageHelper.getAuditorCoId()}
                  onElementClear={clearState}
                  onAuditorClick={getAuditor}
                  commissionAddress={props.data.address_primary}
                />

                {isAuditorFetched && (
                  <>
                    <div>
                      <SingleInput
                        change={(event: React.FormEvent<HTMLInputElement>) => {
                          if (isAuditorFetched && addrMode) {
                            setState({ ...state, addr_name: event.currentTarget.value });
                            setIsFetched(false);
                            setNewSelected(false);
                          }
                        }}
                        text={t("auditorAddress")}
                        id={"name"}
                        value={state.addr_name}
                        placeholder={t("enterAddressName")}
                      />

                      <CSSTransition
                        in={
                          addrMode === true &&
                          state.addr_name !== undefined &&
                          state.addr_name.length !== 0 &&
                          !newSelected
                        }
                        nodeRef={listRef}
                        timeout={{
                          enter: 300,
                          exit: 300,
                        }}
                        classNames={{
                          enter: styles.enterAddr,
                          enterActive: styles.enterActiveAddr,
                          exit: styles.exitAddr,
                          exitActive: styles.exitActiveAddr,
                        }}
                        mountOnEnter
                        unmountOnExit
                        appear
                      >
                        <div ref={listRef}>
                          <div className={styles.list_wrapper}>
                            <div className={styles.list}>
                              <Spinner isFetched={isFetched} size={"50px"} padding={"20px"}>
                                {addrSearchRes.length === 0 ? (
                                  <div className={styles.list_item} key={"error: no entries"}>
                                    {t("noEntries")}
                                  </div>
                                ) : (
                                  addrSearchRes.map((data) => {
                                    return (
                                      <div
                                        onClick={() => {
                                          setState({
                                            ...state,
                                            addr_name: data.display_name,
                                            addr_latitude: parseFloat(data.lat),
                                            addr_longitude: parseFloat(data.lon),
                                          });
                                          setNewSelected(true);
                                          setIsFetched(false);
                                          setIsReverseFetched(false);
                                        }}
                                        className={styles.list_item}
                                        key={`${data.display_name} + ${Math.random()}`}
                                      >
                                        {data.display_name}
                                      </div>
                                    );
                                  })
                                )}
                              </Spinner>
                            </div>
                          </div>
                        </div>
                      </CSSTransition>

                      <div className={`${styles.new_addr_wrapper} ${styles.new_addr_or}`}>{t("or")}</div>
                      <div
                        onClick={() => {
                          changeAddrMode();
                        }}
                        className={styles.new_addr_wrapper}
                      >
                        <div className={styles.new_addr}>
                          {addrMode ? t("selectExistingAddress") : t("addNewAddress")}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <>
                  <TransportTable
                    transport={transport}
                    setTransport={setTransport}
                    setState={setState}
                    state={state}
                    price={price}
                  />

                  <div style={{ width: "490px", padding: "0 10px" }}>
                    <div className={styles.date_label}> {t("expiration")}</div>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                      <div className={styles.expi_input}>
                        {state.expiration_date ? getFormatedDate(state.expiration_date) : "---"}
                      </div>
                      <div>
                        <select
                          value={expiDate}
                          onChange={changeExpiDate}
                          className={styles.expi_select}
                          style={{ height: "36px", padding: "7px 8px" }}
                        >
                          <option className={styles.expi_option} value="0">
                            ---
                          </option>
                          <option className={styles.expi_option} value="1">
                            1 godz.
                          </option>
                          <option className={styles.expi_option} value="2">
                            2 godz.
                          </option>
                          <option className={styles.expi_option} value="3">
                            3 godz.
                          </option>
                          <option className={styles.expi_option} value="4">
                            4 godz.
                          </option>
                          <option className={styles.expi_option} value="5">
                            5 godz.
                          </option>
                          <option className={styles.expi_option} value="6">
                            6 godz.
                          </option>
                          <option className={styles.expi_option} value="7">
                            7 godz.
                          </option>
                          <option className={styles.expi_option} value="8">
                            8 godz.
                          </option>
                          <option className={styles.expi_option} value="9">
                            9 godz.
                          </option>
                          <option className={styles.expi_option} value="10">
                            10 godz.
                          </option>
                          <option className={styles.expi_option} value="11">
                            11 godz.
                          </option>
                          <option className={styles.expi_option} value="12">
                            12 godz.
                          </option>
                          <option className={styles.expi_option} value="24">
                            24 godz.
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </>

                {/* Podsumowanie */}
                <div className={styles.new_offer_summary}>
                  <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    <div style={{ display: "flex", gap: "15px" }}>
                      <div style={{ width: "230px" }}>
                        <div className={styles.new_offer_price_header}>{t("formData.price.services")}</div>
                        <div className={styles.new_offer_price}>{price[0]?.toFixed(2) + " " + state.currency}</div>
                      </div>
                      <div style={{ width: "230px" }}>
                        <div className={styles.new_offer_price_header}>{t("formData.price.commute")}</div>
                        <div className={styles.new_offer_price}>
                          {(price[1] + price[2])?.toFixed(2) + " " + state.currency}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className={styles.new_offer_price_header}>{t("formData.price.")}</div>
                      <div className={styles.new_offer_price}>
                        {(price[0] + price[1] + price[2])?.toFixed(2) + " " + state.currency}
                      </div>
                    </div>
                  </div>

                  {props.editMode === true ? (
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        id={"delete"}
                        label={t("cancelMakingOffer")}
                        click={() => {
                          if (props.setEditMode) props.setEditMode();
                        }}
                      />
                    </div>
                  ) : (
                    <Attachments
                      files={files}
                      addFile={(f: File) => {
                        setFiles((files) => [...files, f]);
                      }}
                      removeFile={(f: File) => {
                        setFiles(files.filter((file) => file.name !== f.name));
                      }}
                    />
                  )}

                  {errMsg !== "" && <div style={{ textAlign: "center", color: "var(--red-base)" }}>{errMsg}</div>}

                  {isAuditorFetched && (
                    <div className={styles.new_offer_summary_buttons}>
                      <Button type="submit" label={t("makeOffer")} />
                    </div>
                  )}
                </div>
              </div>

              {/* Prawa strona oferty */}
              <div className={styles.new_offer_content_column} style={{ alignItems: "stretch", width: "50%" }}>
                {props.data.comment && props.data.comment?.length > 0 && (
                  <div>
                    <p className={styles.comment_title}> {t("comment")}</p>
                    <div className={styles.comment_wrapper}>
                      <p className={styles.comment}>{props.data.comment}</p>
                    </div>
                  </div>
                )}

                <SingleInput
                  change={handleChangeNotes}
                  id="notes"
                  text={t("notes")}
                  value={state.internal_notes}
                  placeholder={t("notesPlaceHolder")}
                  optional
                />

                <MapComponent
                  markers={markers}
                  title={true}
                  padding={"0 10px 10px 10px"}
                  height={"100%"}
                  empMarker={
                    isAuditorFetched
                      ? {
                          x: state.addr_latitude ?? props.data.address_primary.latitude,
                          y: state.addr_longitude ?? props.data.address_primary.longitude,
                          name: state.addr_name ?? "error",
                        }
                      : undefined
                  }
                  empAddrTo={transport.toWork !== TransportState.NONE}
                  empAddrFrom={transport.fromWork !== TransportState.NONE}
                  newEmpAddr={addrMode}
                  newAddr={false}
                  setNewAddr={(lat: number, lng: number) => {
                    setState({ ...state, addr_latitude: lat, addr_longitude: lng });
                    setNewSelected(true);
                    setIsReverseFetched(false);
                    setIsFetched(false);
                  }}
                  autoFit
                  autoFitOnUpdate
                />
              </div>
            </div>
          </form>
        </div>
      </Spinner>
    </div>
  );
}

function TransportTable(props: {
  setState: Dispatch<SetStateAction<OfferCreateExtended>>;
  state: OfferCreateExtended;
  price: number[];
  transport: { toWork: TransportState; fromWork: TransportState };
  setTransport: Dispatch<SetStateAction<{ toWork: TransportState; fromWork: TransportState }>>;
}) {
  const { t } = useTranslation("auditorManagerView", { keyPrefix: "commissions.extension" });

  return (
    <div className={styles.table_transport_wrapper}>
      <div className={styles.transport_summary}>{t("transport")}</div>
      <table className={styles.table_transport}>
        <tbody>
          {/* Do pracy */}
          <tr>
            <td rowSpan={3} className={styles.td_transport}>
              {t("toJob")}
            </td>
            <td className={styles.td_transport}> {t("none")} </td>
            <td
              className={styles.td_transport_button}
              onClick={() => {
                props.setTransport({ ...props.transport, toWork: TransportState.NONE });
                props.setState({ ...props.state, commute_to: undefined });
              }}
            >
              <div className={styles.icon_wrapper}>
                <TransportButton selected={props.transport.toWork === TransportState.NONE} />
              </div>
            </td>
            <td style={{ borderRight: "0", minWidth: "100px" }} rowSpan={3} className={styles.td_transport}>
              {props.price[1]?.toFixed(2) + " " + props.state.currency}
            </td>
          </tr>

          <tr className={styles.tr_transport}>
            <td className={styles.td_transport}>{t("drive")}</td>
            <td
              className={styles.td_transport_button}
              onClick={() => {
                props.setTransport({ ...props.transport, toWork: TransportState.ONE_WAY });
                props.setState({ ...props.state, commute_to: "toSingle" });
              }}
            >
              <div className={styles.icon_wrapper}>
                <TransportButton selected={props.transport.toWork === TransportState.ONE_WAY} />
              </div>
            </td>
          </tr>

          <tr className={styles.tr_transport}>
            <td className={styles.td_transport}>{t("driveReturn")}</td>
            <td
              className={styles.td_transport_button}
              onClick={() => {
                props.setTransport({ ...props.transport, toWork: TransportState.TWO_WAY });
                props.setState({ ...props.state, commute_to: "toDouble" });
              }}
            >
              <div className={styles.icon_wrapper}>
                <TransportButton selected={props.transport.toWork === TransportState.TWO_WAY} />
              </div>
            </td>
          </tr>

          {/* Z pracy */}
          <tr>
            <td rowSpan={3} className={styles.td_transport} style={{ borderBottom: "0" }}>
              {t("fromJob")}
            </td>
            <td className={styles.td_transport}>{t("none")}</td>
            <td
              className={styles.td_transport_button}
              onClick={() => {
                props.setTransport({ ...props.transport, fromWork: TransportState.NONE });
                props.setState({ ...props.state, commute_from: undefined });
              }}
            >
              <div className={styles.icon_wrapper}>
                <TransportButton selected={props.transport.fromWork === TransportState.NONE} />
              </div>
            </td>
            <td style={{ borderRight: "0", borderBottom: "0" }} rowSpan={3} className={styles.td_transport}>
              {props.price[2]?.toFixed(2) + " " + props.state.currency}
            </td>
          </tr>
          <tr className={styles.tr_transport}>
            <td className={styles.td_transport}>{t("drive")}</td>
            <td
              className={styles.td_transport_button}
              onClick={() => {
                props.setTransport({ ...props.transport, fromWork: TransportState.ONE_WAY });
                props.setState({ ...props.state, commute_from: "fromSingle" });
              }}
            >
              <div className={styles.icon_wrapper}>
                <TransportButton selected={props.transport.fromWork === TransportState.ONE_WAY} />
              </div>
            </td>
          </tr>
          <tr className={styles.tr_transport}>
            <td className={styles.td_transport} style={{ borderBottom: "0" }}>
              {t("driveReturn")}
            </td>
            <td
              className={styles.td_transport_button}
              onClick={() => {
                props.setTransport({ ...props.transport, fromWork: TransportState.TWO_WAY });
                props.setState({ ...props.state, commute_from: "fromDouble" });
              }}
              style={{ borderBottom: "0" }}
            >
              <div className={styles.icon_wrapper}>
                <TransportButton selected={props.transport.fromWork === TransportState.TWO_WAY} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function TransportButton(props: { selected: boolean }) {
  return <>{props.selected && <FontAwesomeIcon className={styles.icon} icon={faCheck} />}</>;
}
