import ValidationErrorMessageTranslations from "./types/validation-error-message.translations";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";

const getTranslations = <T>(internalFilePath: string): T => {
  const language = sessionStorageHelper.getLanguage();
  const filePath = `./${language}/${internalFilePath}`;
  const fileContent = require(`${filePath}`);
  return fileContent as T;
};

const getFormValidationErrorTranslations = () => {
  const translations = getTranslations<ValidationErrorMessageTranslations>(
    "validation-error-message.translations.json",
  );

  return translations;
};

const translationsHelper = {
  getValidationErrorTranslations: getFormValidationErrorTranslations,
};

export default translationsHelper;
