import api from "api";
import { OfferForAuditor } from "api/entities/offer";
import Button from "commons/Button/Button";
import Map from "commons/Map";
import { AddressForMap } from "commons/Map/MapComponent";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SecondaryRow.module.css";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import Attachments from "commons/Attachments/Attachments";

export default function SecondaryRow(props: { data: OfferForAuditor; expand: boolean; reloadOneRow: () => void }) {
  const { t } = useTranslation("auditorView", { keyPrefix: "commissions.extension" });
  const [success, setSuccess] = useState(false);
  const eh = useErrorHandling();

  const handleAcceptedCommission = () => {
    try {
      api.editOffer(props.data.id, { accepted_by_auditor: true }).then((x) => {
        if (eh.handleErrors(x.error).ok) {
          setSuccess(true);
          props.reloadOneRow();
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getMarkers = (): AddressForMap[] => {
    var array: AddressForMap[] = [];
    if (
      props.data.commission.address_primary.latitude !== 0.0 &&
      props.data.commission.address_primary.longitude !== 0.0
    )
      array.push({
        x: props.data.commission.address_primary.latitude,
        y: props.data.commission.address_primary.longitude,
        name: props.data.commission.address_primary.name,
      });
    if (props.data.commission.address_secondary)
      if (
        props.data.commission.address_secondary?.latitude !== 0.0 &&
        props.data.commission.address_secondary?.longitude !== 0.0
      )
        array.push({
          x: props.data.commission.address_secondary?.latitude!,
          y: props.data.commission.address_secondary?.longitude!,
          name: props.data.commission.address_secondary?.name,
        });
    return array;
  };

  return (
    <div className={styles.content_wrapper}>
      <div className={styles.content}>
        <div className={styles.content_column}>
          {props.data.commission.comment && props.data.commission.comment?.length > 0 && (
            <div>
              <p className={styles.comment_title}> {t("comment")}</p>
              <div className={styles.comment_wrapper}>
                <p className={styles.comment}>{props.data.commission.comment}</p>
              </div>
            </div>
          )}
          <Map
            markers={getMarkers()}
            title={true}
            padding={"0 10px 10px 10px"}
            empMarker={{
              x: props.data.addr_latitude ?? 0.0,
              y: props.data.addr_longitude ?? 0.0,
              name: props.data.addr_name ?? "error",
            }}
            empAddrTo={props.data.commute_mode.toLocaleLowerCase().includes("to")}
            empAddrFrom={props.data.commute_mode.toLocaleLowerCase().includes("from")}
          />

          {/* Załaczniki do zlecenia, nie mozna dodawac tutaj */}
          <Attachments addFile={(f: File) => {}} mode="c" id={props.data.commission_id} ableToAdd={false} />

          {/* Załączniki do oferty, mozna dodawac */}
          {props.data.id && <Attachments addFile={(f: File) => {}} mode="o" id={props.data.id} ableToAdd={true} />}

          {props.data.accepted_by_auditor === false && (
            <div style={{ paddingInline: "15px", paddingBottom: "10px" }}>
              <Button type="button" label={t("confirm")} click={handleAcceptedCommission} id={success ? "2" : ""} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
