import OperatorEditFormData from "./operator-edit-form-data";
import { FormValidationDefinition } from "commons/form/useForm";
import formValidationService from "commons/form/form-validation.service";
import operatorEditFormValidationService from "./operator-edit-form-validation.service";
import { AuditorManagerWithAuditorCompanyUser } from "api/entities/auditorManager";

const createFormData = (responseData: AuditorManagerWithAuditorCompanyUser): OperatorEditFormData => {
  return {
    companyId: responseData.user.company_id,
    companyName: responseData.user.company.company_name,
    email: responseData.user.email,
    login: responseData.user.login,
    originalLogin: responseData.user.login,
    loginCheck: {
      available: true,
      login: responseData.user.login,
    },
    name: responseData.user.name,
    phone: `${responseData.user.phone_prefix}${responseData.user.phone}`,
    surname: responseData.user.surname,
    password: "",
    passwordConfirm: "",
  };
};

const getEmptyFormData = (): OperatorEditFormData => {
  return {
    companyId: "",
    companyName: "",
    email: "",
    login: "",
    originalLogin: "",
    loginCheck: {
      available: true,
      login: "",
    },
    name: "",
    password: "",
    passwordConfirm: "",
    phone: "",
    surname: "",
  };
};

const getFormValidationDefinition = (): FormValidationDefinition<OperatorEditFormData> => {
  return {
    companyId: formValidationService.ok,
    companyName: formValidationService.ok,
    email: (values) => operatorEditFormValidationService.validateEmail(values.email),
    login: (values) =>
      operatorEditFormValidationService.validateLogin(values.login, values.originalLogin, values.loginCheck),
    originalLogin: formValidationService.ok,
    loginCheck: formValidationService.ok,
    name: (values) => operatorEditFormValidationService.validateName(values.name),
    password: (values) => formValidationService.validatePassword(values.password),
    passwordConfirm: (values) => formValidationService.validatePasswordConfirm(values.password, values.passwordConfirm),
    phone: (values) => operatorEditFormValidationService.validatePhone(values.phone),
    surname: (values) => operatorEditFormValidationService.validateSurname(values.surname),
  };
};

const operatorEditFormFactory = {
  createFormData,
  getEmptyFormData,
  getFormValidationDefinition,
};

export default operatorEditFormFactory;
